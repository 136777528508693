<div class="ag-container">
  <div class="ag-col-secondary">
    <filter-set #filterSet="filterSet" [marketId]="company?.market.id">
      <filter-list key="product_id" sortKey="name"></filter-list>
      <filter-list key="delivery_type"></filter-list>
      <filter-list key="zone_id" sortKey="name" *ngIf="company?.market.configuration.location.zone.enabled"></filter-list>
      <filter-select key="past_range"></filter-select>
    </filter-set>
  </div>
  <div class="ag-col-primary">
    <div class="container-fluid">
      <div class="content-box">
        <div class="ag-header sticky" *ngIf="!loading">
          <div class="ag-header-title">
            <div class="summary">
              <span [innerHtml]="'TRADED_VIEW.SUMMARIZE'|translate: {quantity: (negotiationsByProduct|pluck:'values'|flatten).length}"></span>
              <span *ngIf="company?.market.quantity_units.length === 1"
              [innerHtml]="'TRADED_VIEW.SUMARIZE_TONS'|translate: {
                quantity: totalTons|number:undefined:currentLang,
                unit: company?.market.quantity_units[0].abbrev
              }"></span>
            </div>
          </div>
          <div class="ag-header-actions">
            <span *ngIf="!loadingPrivate && company?.market.quantity_units.length === 1" class="summary text-muted"
            [innerHtml]="'TRADED_VIEW.SUMARIZE_PRIVATE_TONS'|translate: {
              quantity: totalTonsPrivate|number:undefined:currentLang,
              unit: company?.market.quantity_units[0].abbrev
            }"></span>
            <paginator [data]="paginationData" [loading]="loading"></paginator>
          </div>
        </div>
        <div class="loading" *ngIf="loading">
          <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
        </div>
        <div class="row" *ngIf="!loading">
          <div class="col-xs-12">
            <!-- No data -->
            <div class="no-data" *ngIf="negotiationsByProduct?.length === 0">
              <i class="material-symbols-rounded">not_interested</i>
              {{ 'TRADED_VIEW.PLACEHOLDER'|translate }}
            </div>
            <!-- Data -->
            <!-- https://www.w3.org/WAI/tutorials/tables/examples/threeheaders/ -->
            <div class="horizontal-scroller">
              <table class="table table-condensed table-hover" sortable #tableSortable="tableSortable"
              [variableInUrl]="true"
              *ngIf="negotiationsByProduct?.length > 0">
                <thead>
                  <tr class="tr-align-center">
                    <th class="sap hidden-xs" sortBy="original_booking_date">{{ 'GLOBAL.DATE'|translate }}</th>
                    <th scope="col" id="loc" sortBy="proposal.locationString" *ngIf="company?.market.configuration.location.enabled">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
                    <th scope="col" id="loc" sortBy="proposal.business_detail.delivery.geolocations[0].name" *ngIf="company?.market.configuration.geolocation.enabled">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
                    <th scope="col" id="port" sortBy="proposal.portString" *ngIf="company?.market.configuration.order.business_detail.port.enabled">{{ 'GLOBAL.COUNTRY_PORT'|translate }}</th>
                    <th class="sap hidden-xs" sortBy="proposal.deliveryType" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
                      <abbr title tooltip="{{ 'GLOBAL.DELIVERY_TYPE'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.DELIVERY_TYPE_ABBR'|translate }}</abbr>
                    </th>
                    <th scope="col" id="qua" class="hidden-xs hidden-sm" sortBy="proposal.quality_string">{{ 'GLOBAL.QUALITY'|translate }}</th>
                    <th scope="col" id="ton" class="dlb drb" sortBy="proposal.business_detail.quantity.value">
                      <abbr title tooltip="{{ 'GLOBAL.QUANTITY'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
                    </th>
                    <th class="sap text-center" sortBy="proposal.business_detail.delivery.intDateFrom">{{ 'GLOBAL.PERIOD'|translate }}</th>
                    <th class="sap" sortBy="proposal.business_detail.price.value">
                      {{ 'GLOBAL.PRICE'|translate }}
                      <ng-container *ngIf="company?.market.quantity_units.length === 1">/{{ company?.market.quantity_units[0].abbrev }}</ng-container>
                    </th>
                  </tr>
                </thead>
                <tbody *ngFor="let group of negotiationsByProduct">
                  <!-- Product sub-header -->
                  <tr class="product-header">
                    <!-- XS -->
                    <th class="text-uppercase hidden-sm hidden-md hidden-lg">{{ group.key.name }}</th>

                    <!-- SM, MD & LG -->
                    <th colspan="2" class="text-uppercase hidden-xs">{{ group.key.name }}</th>
                    <th class="hidden-xs hidden-sm"></th>
                    <th class="hidden-xs" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists"></th>
                    <th class="text-center">
                      <span tooltip="{{ 'GLOBAL.NEGOTIATED_VOLUME'|translate }}" *ngIf="company?.market.quantity_units.length === 1">
                        {{ group.values|pluck:'proposal.business_detail.quantity.value'|sum|number:undefined:currentLang}}
                        <small>{{ company?.market.quantity_units[0].abbrev }}</small>
                      </span>
                    </th>
                    <th colspan="2"></th>
                  </tr>
                  <!-- Order row -->
                  <tr class="tr-align-center" *ngFor="let negotiation of group.values">
                    <td class="sap hidden-xs">
                      <ag-timestamp [date]="negotiation.original_booking_date"></ag-timestamp>
                    </td>
                    <th>
                      <div class="visible-xs location-small">
                        <b>{{ negotiation.original_booking_date|date: 'HH:mm' }}</b>
                        - <date-md [date]="negotiation.original_booking_date"></date-md>
                      </div>
                      <div class="truncate traded-location">
                        <span *ngIf="company?.market.configuration.location.enabled" class="clamp-2">{{ negotiation.proposal.business_detail.delivery.locations |locationString }}</span>
                        <span *ngIf="company?.market.configuration.geolocation.enabled" class="clamp-2">
                          <ng-container *ngFor="let location of negotiation.proposal.business_detail.delivery.geolocations; let last = last">
                            {{ location.name }}<span *ngIf="!last">, </span>
                          </ng-container>
                        </span>
                        <span *ngIf="company?.market.configuration.order.business_detail.port.enabled" class="clamp-2">{{ countryAndPort(negotiation) }}</span>
                        <span class="small lighten hidden-sm hidden-md hidden-lg" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
                          ({{ negotiation.proposal.business_detail.delivery.delivery_type?.short_name || negotiation.proposal.business_detail.delivery.delivery_type?.name }})
                        </span>
                      </div>
                      <div class="visible-xs lighten">
                        {{ negotiation.proposal.product_detail.quality|quality:group.key }}
                      </div>
                    </th>
                    <td class="sap hidden-xs small" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
                      {{ negotiation.proposal.business_detail.delivery.delivery_type?.short_name || negotiation.proposal.business_detail.delivery.delivery_type?.name }}
                    </td>
                    <td headers="qua" class="hidden-xs hidden-sm small">
                      {{ negotiation.proposal.product_detail.quality|quality:group.key }}
                    </td>
                    <td headers="ton" class="text-right dlb drb">
                      <quantity-control [quantity]="negotiation.proposal.business_detail.quantity" [compact]="true"></quantity-control>
                    </td>
                    <td class="sap">
                      <date-range
                      [styles]="false"
                      [from]="negotiation.proposal.business_detail.delivery.date_from"
                      [to]="negotiation.proposal.business_detail.delivery.date_to"></date-range>
                    </td>
                    <td class="sap">
                      <div class="price-box booked-bg">
                        <div class="value">
                          <price-display [price]="negotiation.proposal.business_detail.price" [market]="company?.market" [withoutYear]="true"></price-display>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>