<ag-slots-linker entity="contract" #slotsLinker
[company]="company"
(linked)="linkedListener($event)"></ag-slots-linker>

<ng-template #assignToContractModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'SLOTS.ASSIGN_MODAL.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="arrow-box m-b1">
      <ng-container *ngTemplateOutlet="contractsTable; context:{
        contracts: [contract]
      }"></ng-container>
    </div>

    <ng-container *ngIf="availableBatches; else loading">
      <ng-container *ngIf="assignModel; else showBatches">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label class="control-label">{{ 'SLOTS.BATCH_DETAILS'|translate }}</label>
              <batch-details
              [company]="company"
              [batch]="selectedBatch"
              [actors]="assignModel.actors"
              [contract]="contract"
              [edit]="true">
              </batch-details>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <p [innerHtml]="'SLOTS.ASSIGN_MODAL.AVAILABLE_SLOTS'|translate: {quantity: getSlotsQuantity() }"></p>
            <table class="table table-condensed">
              <thead>
                <tr>
                  <th class="sap">{{ 'SLOTS.ASSIGN_MODAL.QUANTITY'|translate }}</th>
                  <th>{{ 'GLOBAL.COMPANY'|translate }}</th>
                  <th class="sap">{{ 'GLOBAL.CONTRACT'|translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="tr-align-center">
                  <td class="sap">
                    <select class="form-control" [disabled]="processing" (change)="changeAssignedQuantity(0, $event)">
                        <option [disabled]="isDisabledQuantity(i)"
                          *ngFor="let slot of contractAssignableSlots(selectedBatch.unassigned, assignModel?.assigned[0]?.contract); let i = index"
                          [selected]="isSelectedQuantity(i)" value="{{ i + 1 }}">{{ i + 1 }}</option>
                    </select>
                  </td>
                  <td class="selectize">
                    <!-- Select company -->
                    <selectize *ngIf="!assignModel.assigned[0].company; else showCompany"
                    [searchURL]="companyService.getCompanies(company.id, { all:true })"
                    [cache]="480"
                    [disabled]="processing"
                    name="tradeCompany" [(ngModel)]="assignModel.assigned[0].company" [maxItems]="1"
                    [options]="availableCounterparts" placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"></selectize>

                    <!-- Select my company -->
                    <div *ngIf="assignModel.noMoreCompanies && !assignModel.assigned[0].company">
                      <div class="small text-danger">
                        {{ 'SLOTS.ASSIGN_MODAL.NO_COMPANIES'|translate }}</div>
                      <a *ngIf="selectedBatch.recipient.id !== company.id" (click)="assignToMyCompany()">{{
                        'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
                    </div>

                    <!-- Company selected -->
                    <ng-template #showCompany>
                      <button type="button" class="close" [disabled]="processing"
                        (click)="assignModel.assigned.length === 1 ? assignModel.assigned[0].company = undefined : removeBatch(batch_index)"><span
                          aria-hidden="true">&times;</span></button>
                      <company-info [company]="assignModel.assigned[0].company" [activity]="false"></company-info>
                    </ng-template>
                  </td>
                  <td class="sap">
                    <samp>#{{ assignModel.assigned[0].contract.reference }}</samp>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label class="control-label">{{ 'GLOBAL.OBSERVATIONS'|translate }}
                <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
              </label>
              <quill-editor placeholder="{{ 'SLOTS.ASSIGN_MODAL.OBSERVATIONS_PLACEHOLDER'|translate }}"
                [readOnly]="processing" name="obs" [(ngModel)]="assignModel.observations"></quill-editor>
            </div>
          </div>
        </div>
        <!-- Alerta de sobrecupeo, no disponible para contratos actualmente -->
        <!-- <alert type="warning" class="text-left"
          *ngIf="slotsToAssignSelectedQty > (contract.assignableSlots - contract.assignedQuantity)">
          <div
            [innerHtml]="'SLOTS.OVERALLOCATION_WARNING'|translate: { tons: contract.quantity.value }">
          </div>
          <div *ngIf="contract.assignedQuantity > 0"
            [innerHtml]="'SLOTS.OVERALLOCATION_WARNING_ASSIGNED'|translate: { assigned: contract.assignedQuantity }">
          </div>
        </alert> -->
      </ng-container>
      <ng-template #showBatches>
        <div class="row">
          <div class="col-md-6">

          </div>
          <div class="col-md-6 filters">
            <!-- Select delivery zone filter -->
            <div *ngIf="deliveryZones">
              <selectize *ngIf="!deliveryZoneSelected" class="selectize-md selectize-medium-items" [maxItems]="1"
                placeholder="Zonas de entrega" (onItemAdd)="refreshAvailableBatches()"
                [(ngModel)]="deliveryZoneSelected" [options]="deliveryZones" [disabled]="processing">
              </selectize>
              <div *ngIf="deliveryZoneSelected" class="editable-pill">
                <button type="button" *ngIf="deliveryZoneSelected.id" [disabled]="processing" type="button" class="close"
                  (click)="deliveryZoneSelected = undefined; refreshAvailableBatches()"><span aria-hidden="true">&times;</span></button>
                {{deliveryZoneSelected.name}}
              </div>
            </div>
            <!-- Select destination filter -->
            <div *ngIf="destinations">
              <selectize *ngIf="!destinationSelected" class="selectize-md selectize-medium-items" [maxItems]="1"
                placeholder="Destino" (onItemAdd)="refreshAvailableBatches()"
                [(ngModel)]="destinationSelected" [options]="destinations" [disabled]="processing">
              </selectize>
              <div *ngIf="destinationSelected" class="editable-pill">
                <button type="button" *ngIf="destinationSelected.id" [disabled]="processing" type="button" class="close"
                  (click)="destinationSelected = undefined; refreshAvailableBatches()"><span aria-hidden="true">&times;</span></button>
                {{destinationSelected.name}}
              </div>
            </div>
          </div>
        </div>
        <p [innerHtml]="'SLOTS.AVAILABLE_BATCHES'|translate: { product: contract.product.name }"></p>
        <!-- Content -->
        <ng-container *ngIf="availableBatches.length > 0">
          <div class="horizontal-scroller">
            <table class="table table-condensed no-margin" sortable defaultSortBy="-date">
              <thead>
                <tr class="tr-align-center">
                  <th class="sap" sortBy="date">{{ 'GLOBAL.DATE'|translate }}</th>
                  <th class="sap">{{ 'SLOTS.TABLE_HEADER.BATCH_SLOTS'|translate }}</th>
                  <th class="sap">{{ 'SLOTS.TABLE_HEADER.PENDING_SLOTS'|translate }}</th>
                  <th sortBy="allocator.name">{{ 'SLOTS.TABLE_HEADER.ASSIGNED_BY'|translate }}</th>
                  <th class="sap hidden-xs" sortBy="negotiation.id">{{ 'GLOBAL.CONTRACT'|translate }}</th>
                  <th sortBy="recipient.name">{{ 'SLOTS.TABLE_HEADER.RECEIVER'|translate }}</th>
                  <th class="sap"></th>
                </tr>
              </thead>
              <tbody>
                <!-- Slots batch row -->
                <tr *ngFor="let batch of availableBatches" class="tr-align-center">
                  <td class="sap small">
                    <date-md [date]="batch.date" [style]="true"></date-md>
                  </td>
                  <td class="sap dlb text-right">
                    <samp>
                      <b>{{ batch.slots_total }}</b>
                    </samp>
                  </td>
                  <td class="sap dlb text-right text-danger">
                    <samp>{{ batch.slots_unassigned }}</samp>
                  </td>
                  <td class="dlb">
                    <company-info [compact]="true" [company]="batch.allocator"></company-info>
                  </td>
                  <td class="small sap hidden-xs">
                    <a *ngIf="batch.contract && batch.company.id === company.id; else none"
                      (click)="closeModal()"
                      [routerLink]="['/company', company.id, 'contracts', batch.contract.id]"><samp>#{{
                        batch.contract.reference }}</samp></a>
                  </td>
                  <td>
                    <batch-destination [company]="company" [batch]="batch" [compact]="true"></batch-destination>
                  </td>
                  <td class="sap">
                    <button class="btn btn-default" (click)="setBatchToContract(batch)">{{ 'GLOBAL.SELECT'|translate
                      }}</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
        <!-- No content -->
        <div *ngIf="availableBatches?.length <= 0">
          <ng-container *ngTemplateOutlet="noBatches">
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #noBatches>
      <!-- No data -->
      <div class="no-data">
        <i class="material-symbols-rounded">not_interested</i>
        <span [innerHtml]="'SLOTS.UNAVAILABLE_BATCHES'|translate: { product: contract.product.name }"></span>
      </div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button (click)="closeModal()" type="button" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{
      'GLOBAL.CANCEL'|translate }}</button>
    <button (click)="createAndAssign()" type="button" class="btn btn-agree"
      [disabled]="processing || !assignModel || !assignModel.assigned[assignModel.assigned.length-1].company"
      data-dismiss="modal">{{ 'SLOTS.ASSIGN'|translate }}</button>
  </div>
</ng-template>

<ng-template #assignSlotsModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'SLOTS.ASSIGN_MODAL.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <label class="control-label">{{ 'SLOTS.BATCH_DETAILS'|translate }}</label>
          <batch-details
          [company]="company"
          [batch]="selectedBatch"
          [actors]="assignModel.actors"
          [contract]="contract"
          [edit]="true">
          </batch-details>
        </div>
      </div>
    </div>
    <form id="assignForm" #assignForm="ngForm">
      <div class="row">
        <div class="col-xs-12">
          <p [innerHtml]="'SLOTS.ASSIGN_MODAL.AVAILABLE_SLOTS'|translate: {quantity: getSlotsQuantity()}"></p>
          <table class="table table-condensed">
            <thead>
              <tr>
                <th class="sap">
                  <span class="hidden-xs">{{ 'SLOTS.ASSIGN_MODAL.QUANTITY'|translate }}</span>
                  <span class="visible-xs">{{ 'COMPANY.SLOTS'|translate }}</span>                  
                </th>
                <th>{{ 'GLOBAL.COMPANY'|translate }}</th>
                <th class="sap">{{ 'GLOBAL.CONTRACT'|translate }}</th>
                <th class="sap"></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let batch of assignModel.assigned; let batch_index = index">
                <tr class="tr-align-center">
                  <td class="sap">
                    <select class="form-control" [disabled]="processing"
                      (change)="changeAssignedQuantity(batch_index, $event)">
                      <option *ngFor="let slot of batch.unassigned; let i = index"
                        [selected]="batch.slots_unassigned === (i + 1)" value="{{ i + 1 }}">
                        {{ i + 1 }}</option>
                      <option *ngFor="let slot of assignModel.available; let i = index"
                        value="{{ batch.slots_unassigned + i + 1 }}">
                        {{ batch.slots_unassigned + i + 1 }}
                      </option>
                    </select>
                  </td>
                  <td class="selectize">
                    <selectize *ngIf="!batch.company; else showCompany" [class.hidden]="assignModel.noMoreCompanies"
                    [disabled]="processing"
                    [searchURL]="companyService.getCompanies(company.id, { excluded_ids: [company.id, selectedBatch.recipient.id], all: true })"
                    [cache]="480"
                    [(ngModel)]="batch.company"
                    (load)="assignableCompaniesLoaded($event)" [maxItems]="1"
                    name="company_{{ batch_index }}" placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}"></selectize>
                    <div *ngIf="assignModel.noMoreCompanies && !batch.company">
                      <div class="small text-danger">
                        {{ 'SLOTS.ASSIGN_MODAL.NO_COMPANIES'|translate }}</div>
                      <a *ngIf="selectedBatch.recipient.id !== company.id" (click)="assignToMyCompany()">{{
                        'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
                    </div>
                    <ng-template #showCompany>
                      <button type="button" class="close" [disabled]="processing"
                        (click)="assignModel.assigned.length === 1 ? batch.company = undefined : removeBatch(batch_index)"><span
                          aria-hidden="true">&times;</span></button>
                      <company-info [company]="batch.company" [activity]="false"></company-info>
                    </ng-template>
                  </td>
                  <td class="sap">
                    <a *ngIf="batch.contract" (click)="slotsLinker.linkBatch(batch)">
                      <samp>#{{ batch.contract.reference }}</samp>
                    </a>
                  </td>
                  <td class="sap">
                    <!-- <ng-container *ngIf="batch.company">
                    </ng-container> -->
                    <button *ngIf="batch.contract" [disabled]="processing" class="btn btn-link btn-xs"
                      (click)="batch.contract = undefined" title="{{ 'LINKER.UNLINK'|translate }}"><i
                        class="material-symbols-rounded">link_off</i></button>
                    <button *ngIf="!batch.contract" [disabled]="processing" class="btn btn-link btn-xs"
                      (click)="slotsLinker.linkBatch(batch)" title="{{ 'LINKER.TITLE'|translate }}"><i
                        class="material-symbols-rounded">link</i></button>
                  </td>
                </tr>
                <tr *ngIf="batch.company && batch.company.id === company.id" class="tr-align-center no-border">
                  <td></td>
                  <td>
                    <small [innerHtml]="'SLOTS.ASSIGN_MODAL.TAKE_HELP'|translate: {company: company.name}"></small>
                  </td>
                </tr>
              </ng-container>
              <tr *ngIf="!assignModel.assignedToMyCompany &&
                assignModel.noMoreCompanies == false &&
                !assignModel.assigned[assignModel.assigned.length-1].company &&
                selectedBatch.recipient.id !== company.id" class="no-border">
                <td></td>
                <td>
                  <a class="small" (click)="assignToMyCompany()">{{ 'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="flex-center-distributed" *ngIf="assignModel.available.length > 0 &&
        assignModel.assigned[assignModel.assigned.length-1].company">
        <div [innerHtml]="'SLOTS.ASSIGN_MODAL.PENDING_SLOTS'|translate: {quantity: assignModel.available.length}">
        </div>
        <div>
          <button class="btn btn-default" [disabled]="processing" (click)="addBatch()">{{ 'GLOBAL.ADD_COMPANY'|translate
            }}</button>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label">{{ 'GLOBAL.OBSERVATIONS'|translate }}
              <small class="text-muted"> ({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <quill-editor placeholder="{{ 'SLOTS.ASSIGN_MODAL.OBSERVATIONS_PLACEHOLDER'|translate }}"
              [readOnly]="processing" name="obs" [(ngModel)]="assignModel.observations"></quill-editor>
            <p class="help-block small">{{ 'SLOTS.ASSIGN_MODAL.OBSERVATIONS_HELP'|translate }}</p>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="closeModal()" type="button" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{
      'GLOBAL.CANCEL'|translate }}</button>
    <button (click)="createAndAssign()" type="button" class="btn btn-agree"
      [disabled]="processing || !assignModel.assigned[assignModel.assigned.length-1].company" data-dismiss="modal">{{
      'SLOTS.ASSIGN'|translate }}</button>
  </div>
</ng-template>

<ng-template #requestSlotsModal>
  <ng-container>
    <!-- modal -->
    <div class="modal-header">
      <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 class="modal-title">{{ 'SLOTS.REQUEST_SLOT_MODAL.TITLE'|translate }}</h4>
    </div>
    <div class="modal-body">
      <slots-batch-contract-request-form [company]="company" [contract]="contract"
        (close)="closeModal()"></slots-batch-contract-request-form>
    </div>
  </ng-container>
</ng-template>

<ng-template #acceptSlotsModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'SLOTS.ACCEPT_MODAL.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group" *ngIf="details">
          <label class="control-label">{{ 'SLOTS.BATCH_DETAILS'|translate }}</label>
          <batch-details [company]="company" [batch]="selectedBatch"></batch-details>
        </div>
        <!-- <ng-container *ngTemplateOutlet="slotsIds; context: {batch: selectedBatch}"></ng-container> -->
      </div>
    </div>

    <ng-container *ngIf="!simplerAccept; else simplyAccept">
      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div class="form-group">
            <label class="control-label">{{ 'SLOTS.ACCEPT_MODAL.SLOTS_TO_ACCEPT'|translate }}</label>
            <select class="form-control" [(ngModel)]="acceptedSlots" name="accepted">
              <option *ngFor="let slot of selectedBatch.unassigned; let i = index" value="{{ i+1 }}">
                {{ i+1 }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="acceptedSlots !== selectedBatch.unassigned.length">
        <div class="col-xs-12">
          <div class="form-group">
            <label for="reason" class="control-label">
              {{ 'SLOTS.ACCEPT_MODAL.REASONS'|translate }}
              <small class="text-muted">({{ 'GLOBAL.OPTIONAL'|translate }})</small>
            </label>
            <textarea rows="3" [disabled]="processing" class="form-control" id="reason" [(ngModel)]="reasons"
              maxlength="512" placeholder="{{ 'SLOTS.ACCEPT_MODAL.REASONS_PLACEHOLDER'|translate }}"></textarea>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #simplyAccept>
      <div class="row">
        <div class="col-xs-12 text-center">
          <div class="panel panel-default no-margin">
            <div class="panel-body">
              <h4>{{ 'SLOTS.ACCEPT_MODAL.SIMPLER_QUESTION'|translate }}</h4><br>
              <p>
                <button (click)="takeBatch(selectedBatch)" type="button" class="btn btn-agree" [disabled]="processing"
                  [innerHtml]="'SLOTS.ACCEPT_MODAL.SIMPLER_QUESTION_YES'|translate: {company: company.name}"></button>
                <button (click)="simplerAccept = false" type="button" class="btn btn-link" [disabled]="processing"
                  [innerHtml]="'SLOTS.ACCEPT_MODAL.SIMPLER_QUESTION_NO'|translate"></button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="modal-footer" *ngIf="!simplerAccept">
    <button (click)="closeModal()" type="button" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{
      'GLOBAL.CANCEL'|translate }}</button>
    <button (click)="acceptSlots()" type="button" class="btn btn-agree" [disabled]="processing" data-dismiss="modal">{{
      'SLOTS.ACCEPT_MODAL.ACCEPT'|translate:{quantity:acceptedSlots} }}</button>
  </div>
</ng-template>

<ng-template #rejectModal>
  <!-- modal -->
  <div class="modal-header">
    <button type="button" class="close" [disabled]="processing" (click)="closeModal()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ 'SLOTS.REJECT_MODAL.TITLE'|translate }}</h4>
  </div>
  <div class="modal-body">
    <form id="rejectForm">
      <div class="row" *ngIf="details">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="control-label">{{ 'SLOTS.BATCH_DETAILS'|translate }}</label>
            <batch-details [company]="company" [batch]="selectedBatch"></batch-details>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group" hasError>
            <!-- <label for="slots" class="control-label">Número de Cupos</label> -->
            <p>{{ 'SLOTS.REJECT_MODAL.DESCRIPTION'|translate }}</p>
            <textarea rows="3" [disabled]="processing" [(ngModel)]="reasons" class="form-control" id="slots"
              name="slots" maxlength="512" placeholder="{{ 'SLOTS.REJECT_MODAL.PLACEHOLDER'|translate }}"></textarea>
            <span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>
            <error-list></error-list>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="closeModal()" type="button" class="btn btn-link" [disabled]="processing" data-dismiss="modal">{{
      'GLOBAL.CANCEL'|translate }}</button>
    <button (click)="rejectSlots()" type="button" class="btn btn-agree" [disabled]="processing" data-dismiss="modal">{{
      'SLOTS.REJECT_MODAL.REJECT'|translate }}</button>
  </div>
</ng-template>

<ng-template #contractsTable let-contracts="contracts">
  <div class="horizontal-scroller">
    <table *ngIf="contracts?.length > 0" class="table table-condensed no-margin" sortable defaultSortBy="-date" #tableSortable="tableSortable">
      <thead>
        <tr class="tr-align-center">
          <th class="sap" sortBy="date">{{ 'GLOBAL.DATE'|translate }}</th>
          <th class="sap" sortBy="reference">{{ 'GLOBAL.CONTRACT'|translate }}</th>
          <th sortBy="delivery.locations[0].name">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
          <th class="dlb drb" sortBy="quantity.value" colspan="2">
            <abbr title tooltip="{{ 'GLOBAL.QUANTITY'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
          </th>
          <th class="sap text-center" sortBy="contract.delivery.date_from">{{ 'GLOBAL.PERIOD'|translate }}</th>
          <th class="sap" sortBy="price.value">
            {{ 'GLOBAL.PRICE'|translate }}
            <ng-container *ngIf="company.market.quantity_units.length === 1">/{{ company.market.quantity_units[0].abbrev }}</ng-container>
          </th>
          <!-- <ng-container *ngIf="!environment.production">
            <th class="dlb" *ngIf="environment.modules.unloads">{{ 'COMPANY_TRADED_VIEW.UNLOADED'|translate }}</th>
            <th class="text-right drb" *ngIf="environment.modules.invoices">{{ 'COMPANY_TRADED_VIEW.LIQUIDATED'|translate }}</th>
          </ng-container> -->
        </tr>
      </thead>
      <tbody>
        <tr class="tr-align-center"
        [class.clickable]="select === 'contract' && !contract"
        [class.selected]="contract?.id === c.id"
        (click)="select === 'contract' && !contract ? contract = c : null"
        *ngFor="let c of contracts|orderBy: tableSortable.sortBy;">
          <td class="sap micro text-muted">
            <date-md [date]="c.date"></date-md>
          </td>
          <td class="small sap"><samp>{{ c.reference }}</samp></td>
          <td class="small">
            {{ c.delivery.locations |locationString }}
          </td>
          <td class="text-right dlb">
            <quantity-control [quantity]="c.quantity" [compact]="true"></quantity-control>
          </td>
          <td class="sap drb">
            <progress-circle
            label="{{ 'COMPANY_TRADED_VIEW.UNLOADED' | translate }}"
            [value]="contract.applied.value"
            [unit]="contract.quantity.unit.abbrev"
            [progress]="contract.applied.value ? contract.applied.value / contract.quantity.value : 0"></progress-circle>
          </td>
          <td class="sap">
            <date-range
            [from]="c.delivery.date_from"
            [to]="c.delivery.date_to"></date-range>
          </td>
          <td class="sap">
            <div class="price-box booked-bg">
              <div class="type"
                [class.buy]="c.buyer.id === company.id"
                [class.sell]="c.seller.id === company.id">{{
                  (c.buyer.id === company.id ? 'COMPANY_TRADED_VIEW.BOUGHT' : (c.seller.id === company.id ? 'COMPANY_TRADED_VIEW.SOLD' : ''))|translate
              }}</div>
              <div class="value">
                <price-display [price]="c.price" [market]="company.market" [withoutYear]="true"></price-display>
              </div>
            </div>
          </td>
          <!-- <ng-container *ngIf="!environment.production">
            <td class="text-right dlb" *ngIf="environment.modules.unloads">
              {{ (c.quantity.value * 0.7)|number:undefined:currentLang }}
              <span class="currency">{{ c.quantity.unit.abbrev }}.</span>
              &nbsp;<progress-circle [progress]="0.7" size="13"></progress-circle>
            </td>
            <td class="text-right drb" *ngIf="environment.modules.invoices">
              <price-display [price]="{
                type: 'flat',
                unit: {
                  name: 'AR$'
                },
                value: 12345678
              }"></price-display>
            </td>  
          </ng-container> -->
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>