<div class="ag-container">
  <div class="ag-col-secondary">
    <filter-set #filterSet="filterSet" [marketId]="company?.market.id">
      <filter-list key="validity" sortKey="name"></filter-list>
      <filter-list key="operation_type" sortKey="name" 
      *ngIf="!company?.activity.broker"></filter-list>
      <filter-list key="product_id" sortKey="name"></filter-list>
      <filter-date-range key="delivery_range"></filter-date-range>
      <filter-list key="sustainable"></filter-list>
      <filter-list key="label_id"></filter-list>
      <filter-list key="price"></filter-list>
      <filter-list key="crop"></filter-list>
      <filter-list key="delivery_type"></filter-list>
      <filter-list key="has_contract"
      *ngIf="company?.hasModule('contracts') &&
      environment.modules.imported_data &&
      company?.market.configuration.imported_data.collections.length > 0"></filter-list>
      <filter-list key="zone_id" sortKey="name" *ngIf="company?.market.configuration.location.zone.enabled"></filter-list>
      <!-- <filter-select key="past_range"></filter-select> -->
    </filter-set>
  </div>
  <div class="ag-col-primary">
    <div class="container-fluid">
      <div class="content-box">
        <div class="ag-header sticky" *ngIf="!loading">
          <div class="ag-header-title">
            <div id="viewmode-toggle"
            *ngIf="negotiations?.length > 0"
            class="btn-group" data-toggle="buttons">
              <label class="btn btn-default btn-sm" [class.active]="groupByProduct == true" title="{{ 'COMPANY_TRADED_VIEW.VIEWMODE.GROUPED'|translate }}">
                <input name="viewmode" type="radio" [(ngModel)]="groupByProduct" [value]="true">
                <i class="material-symbols-rounded">view_list</i>
              </label>
              <label class="btn btn-default btn-sm" [class.active]="groupByProduct == false" title="{{ 'COMPANY_TRADED_VIEW.VIEWMODE.UNGROUPED'|translate }}">
                <input name="viewmode" type="radio" [(ngModel)]="groupByProduct" [value]="false">
                <i class="material-symbols-rounded">format_list_bulleted</i>
              </label>&nbsp;&nbsp;
            </div>
            <div class="summary" *ngIf="!selection || selection?.length === 0">
              <span [innerHtml]="'COMPANY_TRADED_VIEW.SUMMARIZE'|translate:{quantity: negotiations.length }"></span>
              <span *ngIf="company?.market.quantity_units.length === 1" [innerHtml]="'COMPANY_TRADED_VIEW.SUMARIZE_VOLUME'|translate:{quantity: totalTons|number:undefined:currentLang, unit: company?.market.quantity_units[0].abbrev }"></span>
            </div>
            <div class="summary" *ngIf="selection?.length > 0">
              <span [innerHtml]="'LABELS.SELECTED'|translate: {quantity: selection.length}"></span>
              &mdash;
              <a class="small" (click)="selection = []">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</a>
              <ag-label-manager
              [(ngModel)]="selection"
              [company]="company"></ag-label-manager>
            </div>
          </div>
          <div class="ag-header-actions">
            <div class="btn-toolbar">
              <div class="btn-group">
                <button
                type="button" class="btn btn-link"
                (click)="exportToXLS()"
                [disabled]="loading || processing">
                  <i class="material-symbols-rounded">save_alt</i>
                  <span class="hidden-xs hidden-sm">{{ 'GLOBAL.EXPORT_XLS'|translate }}</span>
                </button>
              </div>

              <!-- <div class="btn-group">
                <button
                type="button" class="btn btn-default"
                (click)="dashOpened = !dashOpened"
                [class.active]="dashOpened"
                [attr.aria-expanded]="!dashOpened" aria-controls="collapseBasic">
                  <span class="material-symbols-rounded">pie_chart</span>
                  {{ 'SLOTS.TOGGLE_DASHBOARD'|translate }}
                </button>
              </div> -->

              <div class="btn-group"
              *ngIf="paginationData?.total_pages > 1">
                <paginator [data]="paginationData" [loading]="loading"></paginator>
              </div>
            </div>
          </div>
        </div>

        <div class="row" id="collapseBasic" [collapse]="!dashOpened" [isAnimated]="true" *ngIf="company">
          <div class="col-xs-12">
            <negotiations-dashboard
            [enabled]="dashOpened"
            [company]="company"></negotiations-dashboard>
          </div>
        </div>
        
        <div class="loading" *ngIf="loading">
          <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
        </div>

        <div class="row" *ngIf="!loading">
          <div class="col-xs-12">
            <closed-negotiations-table
            [negotiations]="negotiations"
            [(ngModel)]="selection"
            [enableSelection]="true"
            [company]="company" 
            [filters]="filters"
            [group-by-product]="groupByProduct"
            (onForceReload)="reload()"></closed-negotiations-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>