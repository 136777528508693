<ng-container *ngIf="company && batch">
  <ng-container *ngIf="batch.allocator">
    <p
    [innerHtml]="(batch.company.id === company.id ? 'SLOTS.ASSIGNED_BY' : 'SLOTS.ASSIGNED_BY_TO')|translate:{allocator: batch.allocator.name, company: batch.company.name}"></p>
  </ng-container>
  
  <div class="batch-detail flex-center-distributed">
    <div class="date">
      <!-- <div class="icon">
        <span class="material-symbols-rounded">calendar_today</span>
      </div> -->
      <div class="legend">
        <div class="micro text-muted">{{ batch.date | date:'EEEE':undefined }}</div>
        <div>
          <date-md [date]="batch.date"></date-md>
        </div>
      </div>
    </div>
    <div class="product">
      <h4>{{ batch.product?.name }}</h4>
      <div *ngIf="batch.quality.sustainable"
      class="text-success small">{{ 'ORDER_FORM.PRODUCT.SUSTAINABLE'|translate }}</div>
    </div>
    <div class="arrow text-center grow">
      <div class="small">
        <b>{{ batch.slots_total }}</b>&nbsp;<span class="material-symbols-rounded text-muted">local_shipping</span>
      </div>
      <hr>
      <div class="micro">
        <span class="text-muted"
          [innerHtml]="batch.slots_assigned ? ('SLOTS.ASSIGNED_QUANTITY'|translate: {quantity: batch.slots_assigned}) : '&nbsp;'"></span>
      </div>
    </div>
    <div class="destination flex-center-distributed">
      <div class="icon">
        <span class="material-symbols-rounded">location_on</span>
      </div>
      <div class="legend grow">
        <batch-destination [company]="company" [batch]="batch"></batch-destination>
      </div>
    </div>
  </div>

  <div class="negotiation arrow-box" *ngIf="batch.negotiation?.booking_date">
    <table>
      <tbody>
        <tr class="tr-align-center">
          <td class="smtd hidden-xs">
            <ag-timestamp [date]="batch.negotiation.booking_date"></ag-timestamp>
          </td>
          <td class="smtd">
            <company-logo [company]="batch.negotiation.counterpartiesOf(company.id)[0]"></company-logo>
          </td>
          <td>
            <a class="order-link truncate"
            [routerLink]="['/company', company.id, 'counter-order', batch.negotiation.id]"
            title="#{{ batch.negotiation.id }}">
              <span class="truncate">
                <samp class="small hidden-xs hidden-sm">#{{ batch.negotiation.id }}:</samp>
                <b>{{ batch.negotiation.counterpartiesOf(company.id)[0].name }}
                  <small
                    *ngIf="batch.negotiation.counterpartiesOf(company.id).length > 1">(+{{ batch.negotiation.counterpartiesOf(company.id).length-1 }})</small>
                </b>
              </span>
            </a>
            <div *ngIf="batch.negotiation.times_booked > 0 && batch.negotiation.status.id !== 7">
              <span class="ag-label label-warning"><span class="glyphicon glyphicon-eye-open" aria-hidden="true"></span>
                {{ 'COMPANY_TRADED_VIEW.REVIEWING'|translate }}</span>
            </div>
          </td>
          <!-- <td class="small" *ngIf="company.market.configuration.location.enabled">
            <span class="clamp-2">{{ batch.negotiation.proposal.business_detail.delivery.locations | locationString }}</span>
          </td>
          <td class="small" *ngIf="company.market.configuration.order.business_detail.port.enabled">
            {{ countryAndPort(batch.negotiation) }}
          </td>
          <td class="small" *ngIf="company.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
            {{ batch.negotiation.proposal.business_detail.delivery.delivery_type?.short_name || batch.negotiation.proposal.business_detail.delivery.delivery_type?.name }}
          </td> -->
          <td class="text-right">
            <quantity-control [quantity]="batch.negotiation.proposal.business_detail.quantity"></quantity-control>
          </td>
          <td class="smtd">
            <date-range [from]="batch.negotiation.proposal.business_detail.delivery.date_from"
              [to]="batch.negotiation.proposal.business_detail.delivery.date_to"></date-range>
          </td>
          <td class="smtd">
            <div class="price-box booked-bg">
              <div class="type"
              [class.buy]="batch.negotiation.buyers_id.includes(company.id)"
              [class.sell]="batch.negotiation.sellers_id.includes(company.id)">{{
                (batch.negotiation.buyers_id.includes(company.id) ? 'COMPANY_TRADED_VIEW.BOUGHT': 'COMPANY_TRADED_VIEW.SOLD')|translate
              }}</div>
              <div class="value">
                <price-display [price]="batch.negotiation.proposal.business_detail.price" [market]="company.market"
                  [withoutYear]="true"></price-display>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="negotiation arrow-box" *ngIf="batch.contract?.date">
    <table>
      <tbody>
        <tr class="tr-align-center">
          <td class="smtd hidden-xs">
            <ag-timestamp [date]="batch.contract.date"></ag-timestamp>
          </td>
          <td class="smtd">
            <company-logo [company]="batch.contract.buyer.id == company.id ? batch.contract.seller : batch.contract.buyer"></company-logo>
          </td>
          <td>
            <a class="order-link truncate"
            [routerLink]="['/company', company.id, 'contracts', batch.contract.id]"
            title="#{{ batch.contract.reference }}">
              <span class="truncate">
                <samp class="small hidden-xs hidden-sm">#{{ batch.contract.reference }}:</samp>
                <b>{{ batch.contract.buyer.id == company.id ? batch.contract.seller.name : batch.contract.buyer.name }}
                  <!-- <small
                    *ngIf="batch.negotiation.counterpartiesOf(company.id).length > 1">(+{{ batch.negotiation.counterpartiesOf(company.id).length-1 }})</small> -->
                </b>
              </span>
            </a>
            <!-- <div *ngIf="batch.negotiation.times_booked > 0 && batch.negotiation.status.id !== 7">
              <span class="ag-label label-warning"><span class="glyphicon glyphicon-eye-open" aria-hidden="true"></span>
                {{ 'COMPANY_TRADED_VIEW.REVIEWING'|translate }}</span>
            </div> -->
          </td>
          <!-- <td class="small" *ngIf="company.market.configuration.location.enabled">
            <span class="clamp-2">{{ batch.negotiation.proposal.business_detail.delivery.locations | locationString }}</span>
          </td>
          <td class="small" *ngIf="company.market.configuration.order.business_detail.port.enabled">
            {{ countryAndPort(batch.negotiation) }}
          </td>
          <td class="small" *ngIf="company.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
            {{ batch.negotiation.proposal.business_detail.delivery.delivery_type?.short_name || batch.negotiation.proposal.business_detail.delivery.delivery_type?.name }}
          </td> -->
          <td class="text-right">
            <quantity-control [quantity]="batch.contract.quantity"></quantity-control>
          </td>
          <td class="smtd">
            <date-range [from]="batch.contract.delivery.date_from"
              [to]="batch.contract.delivery.date_to"></date-range>
          </td>
          <td class="smtd">
            <div class="price-box booked-bg">
              <div class="type"
              [class.buy]="batch.contract.buyer.id == company.id"
              [class.sell]="batch.contract.seller.id == company.id">{{
                (batch.contract.buyer.id == company.id ? 'COMPANY_TRADED_VIEW.BOUGHT': 'COMPANY_TRADED_VIEW.SOLD')|translate
              }}</div>
              <div class="value">
                <price-display [price]="batch.contract.price" [market]="company.market"
                  [withoutYear]="true"></price-display>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="batch.cpe?.actors" class="batch-actors">
    <div class="flex-center-distributed">
      <h5 class="grow">{{ 'SLOTS.WAYBILL_INFO'|translate }}</h5>
      <button *ngIf="edit"
      type="button" class="btn btn-link"
      (click)="editActors = !editActors"
      [disabled]="processing">{{ 'SLOTS.EDIT_ACTORS'|translate }}</button>
      <ag-form-reuse *ngIf="editActors"
      [company]="company"
      form="cpe-actors"
      [map]="{
        corredor_venta_primaria: { label: 'WAYBILLS.ACTORS.CORREDOR_VENTA_PRIMARIA' },
        corredor_venta_secundaria: { label: 'WAYBILLS.ACTORS.CORREDOR_VENTA_SECUNDARIA' },
        destinatario: { label: 'WAYBILLS.ACTORS.DESTINATARIO', skip: true },
        destino: { label: 'WAYBILLS.ACTORS.DESTINO', skip: true },
        mercado_a_termino: { label: 'WAYBILLS.ACTORS.MERCADO_A_TERMINO' },
        remitente_comercial_productor: { label: 'WAYBILLS.ACTORS.REMITENTE_COMERCIAL_PRODUCTOR' },
        remitente_comercial_venta_primaria: { label: 'WAYBILLS.ACTORS.REMITENTE_COMERCIAL_VENTA_PRIMARIA' },
        remitente_comercial_venta_secundaria_2: { label: 'WAYBILLS.ACTORS.REMITENTE_COMERCIAL_VENTA_SECUNDARIA_2' },
        remitente_comercial_venta_secundaria: { label: 'WAYBILLS.ACTORS.REMITENTE_COMERCIAL_VENTA_SECUNDARIA' },
        representante_entregador: { label: 'WAYBILLS.ACTORS.REPRESENTANTE_ENTREGADOR' },
        representante_recibidor: { label: 'WAYBILLS.ACTORS.REPRESENTANTE_RECIBIDOR' },
        titular_carta_de_porte: { label: 'WAYBILLS.ACTORS.TITULAR_CARTA_DE_PORTE' },
        observaciones: { label: 'WAYBILLS.ACTORS.OBSERVACIONES' }
      }"
      [(ngModel)]="batch.cpe.actors"></ag-form-reuse>
    </div>
    <table class="table table-condensed">
      <thead>
        <tr>
          <th class="sap"></th>
          <th>{{ 'GLOBAL.COMPANY'|translate }}</th>
          <th class="sap visible-sm visible-md visible-lg visible-print">
            {{ company.market.configuration?.company.fiscal_id?.label ? company.market.configuration.company.fiscal_id.label : 'REGISTER_COMPANY.FISCAL_IDENTIFICATION'|translate }}
          </th>
          <th class="sap" *ngIf="editActors"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let actor of batch.cpe.actors| keyvalue: originalOrder">
          <tr class="tr-align-center"
          *ngIf="editActors || actor.value">
          <ng-container *ngIf="!editActors">
            <ng-container *ngIf="!isArray(actor.value);else actorArray">
              <td class="small sap text-right">{{
                'WAYBILLS.ACTORS.'+(actor.key).toUpperCase()|translate }}</td>
              <td>
                <company-info [company]="actor.value" [compact]="true"></company-info>
              </td>
              <td class="sap visible-sm visible-md visible-lg visible-print">
                <fiscal-id [value]="actor.value.fiscal_id"></fiscal-id>
              </td>
            </ng-container>

            <ng-template #actorArray>
             <ng-container *ngIf="actor.value.length > 0">
              <td style="vertical-align: top;" class="small sap text-right">{{
                'WAYBILLS.ACTORS.'+(actor.key).toUpperCase()|translate }}</td>
              <td>
                <ng-container *ngFor="let act of actor.value">
                  <company-info [company]="act" [compact]="true"></company-info>
                </ng-container>
              </td>
              <td class="sap visible-sm visible-md visible-lg visible-print">
                <ng-container *ngFor="let act of actor.value">
                  <div>
                    <fiscal-id [value]="act.fiscal_id"></fiscal-id>
                  </div>
                </ng-container>
              </td>
             </ng-container>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="editActors">
              <td class="small sap text-right">{{ 'WAYBILLS.ACTORS.'+(actor.key).toUpperCase()|translate }}</td>
              <td>
                <selectize
                *ngIf="isArray(actor.value) || !actor.value"
                class="selectize-sm selectize-small-items" [preload]="false" 
                [maxItems]="isArray(actor.value) ? 10 : 1"
                [searchURL]="companyService.getCompanies(company.id, {
                  all: true
                })"
                [cache]="480"
                [(ngModel)]="batch.cpe.actors[actor.key]" name="cpe_actor_{{ actor.key }}"
                placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}" [disabled]="processing"></selectize>
                <company-info *ngIf="actor.value && !isArray(actor.value)"
                [company]="actor.value" [compact]="true"></company-info>
              </td>
              <td class="sap visible-sm visible-md visible-lg visible-print">
                <fiscal-id *ngIf="actor.value" [value]="actor.value.fiscal_id"></fiscal-id>
                <ng-container
                *ngIf="!actor.value">
                  <a class="small"
                  *ngIf="actor.key.includes('corredor') &&
                  company.activity.broker"
                  (click)="batch.cpe.actors[actor.key] = company">{{ 'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
                </ng-container>
              </td>
              <td class="sap">
                <a (click)="removeActor(actor.key)"
                [tooltip]="'GLOBAL.CLEAR'|translate"
                [class.invisible]="unsetteableKeys.includes(actor.key) || !actor.value || (isArray(actor.value) && actor.value.length < 1)">
                  <i class="material-symbols-rounded">delete</i>
                </a>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <!-- FAS-3448 @deprecated -->
  <div *ngIf="bActors" class="batch-actors">
    <a *ngIf="edit" class="pull-right small" (click)="editActors = !editActors">{{ 'SLOTS.EDIT_ACTORS'|translate }}</a>
    <h5>{{ 'SLOTS.WAYBILL_INFO'|translate }}</h5>
    <table class="table table-condensed">
      <thead>
        <tr>
          <th class="sap"></th>
          <th>{{ 'GLOBAL.COMPANY'|translate }}</th>
          <th class="sap visible-sm visible-md visible-lg visible-print">
            {{ company.market.configuration?.company.fiscal_id?.label ? company.market.configuration.company.fiscal_id.label : 'REGISTER_COMPANY.FISCAL_IDENTIFICATION'|translate }}
          </th>
          <th class="sap" *ngIf="editActors"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let actor of bActors| keyvalue: originalOrder">
          <!-- <ng-container *ngTemplateOutlet="editActors ? notDefined : defined; context: { actor: actor }">
          </ng-container> -->

          <tr class="tr-align-center"
          *ngIf="editActors || (batch.waybillActors[actor.key] || actor.value)">
            <td class="small sap text-right">{{ 'TRUCKS.ACTORS.'+(actor.key).toUpperCase()|translate }}</td>
            <ng-container *ngIf="!editActors">
              <td>
                <b *ngIf="batch.waybillActors[actor.key]">{{ batch.waybillActors[actor.key].name }}</b>
                <company-info *ngIf="!batch.waybillActors[actor.key]"
                [company]="actor.value" [compact]="true"></company-info>
              </td>
              <td class="sap visible-sm visible-md visible-lg visible-print">
                <samp *ngIf="batch.waybillActors[actor.key]">{{ batch.waybillActors[actor.key].fiscal_id }}</samp>
                <fiscal-id *ngIf="!batch.waybillActors[actor.key]"
                [value]="actor.value.fiscal_id"></fiscal-id>
              </td>
            </ng-container>
            <ng-container *ngIf="editActors">
              <td>
                <b
                *ngIf="batch.waybillActors[actor.key]?.name && !batch.waybillActors[actor.key].editable">{{ batch.waybillActors[actor.key].name }}</b>
                <input class="form-control input-sm" type="text"
                *ngIf="batch.waybillActors[actor.key] && batch.waybillActors[actor.key].editable"
                name="actorname_{{ actor.key }}"
                [(ngModel)]="batch.waybillActors[actor.key].name"
                placeholder="{{ 'REGISTER_COMPANY.NAME'|translate }}"
                [disabled]="processing"
                maxLength="128">
                <selectize
                *ngIf="!batch.waybillActors[actor.key] && !bActors[actor.key]"
                class="selectize-sm" [preload]="false" [maxItems]="1"
                [searchURL]="companyService.getCompanies(company.id, {
                  all: true
                })"
                [cache]="480"
                [(ngModel)]="bActors[actor.key]" name="actor_{{ actor.key }}"
                placeholder="{{ 'GLOBAL.ENTER_COMPANY'|translate }}" [disabled]="processing"></selectize>
                <company-info *ngIf="actor.value"
                [company]="actor.value" [compact]="true"></company-info>
              </td>
              <td class="sap visible-sm visible-md visible-lg visible-print">
                <samp
                *ngIf="batch.waybillActors[actor.key]?.fiscal_id && !batch.waybillActors[actor.key].editable">{{ batch.waybillActors[actor.key].fiscal_id }}</samp>
                <input class="form-control input-sm" type="text"
                *ngIf="batch.waybillActors[actor.key] && batch.waybillActors[actor.key].editable"
                name="actorfiscalid_{{ actor.key }}"
                [(ngModel)]="batch.waybillActors[actor.key].fiscal_id"
                placeholder="{{ company.market.configuration?.company.fiscal_id?.label ? company.market.configuration.company.fiscal_id.label : 'REGISTER_COMPANY.FISCAL_IDENTIFICATION'|translate }}"
                [disabled]="processing"
                maxLength="32">
                <ng-container
                *ngIf="!batch.waybillActors[actor.key]">
                  <a class="small"
                  *ngIf="['buying_broker', 'selling_broker'].includes(actor.key) &&
                  company.activity.broker &&
                  !bActors[actor.key]"
                  (click)="bActors[actor.key] = company">{{ 'SLOTS.ASSIGN_MODAL.TAKE'|translate }}</a>
                  <a class="small"
                  *ngIf="['carrier_broker', 'carrier', 'driver'].includes(actor.key)"
                  (click)="batch.waybillActors[actor.key] = {editable:true}">{{ 'SLOTS.ASSIGN_MODAL.ENTER_ANOTHER'|translate }}</a>
                </ng-container>
              </td>
              <td class="sap">
                <a (click)="unsetActor(actor.key)"
                [tooltip]="'GLOBAL.CLEAR'|translate"
                *ngIf="!['receiver', 'destination'].includes(actor.key) &&
                (batch.waybillActors[actor.key] || actor.value)">
                  <i class="material-symbols-rounded">delete</i>
                </a>
                <a (click)="setActor(actor.key, {name: 'DIRECTO', editable: false})"
                [tooltip]="'SLOTS.ASSIGN_MODAL.DIRECT'|translate"
                *ngIf="['buying_broker', 'selling_broker'].includes(actor.key) &&
                !batch.waybillActors[actor.key] && !actor.value">
                  <i class="material-symbols-rounded">sync_alt</i>
                </a>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  
  <ng-container *ngIf="batch.destination && showAddress">
    <h5>{{ 'SLOTS.ADD_MODAL.ADDRESS'|translate }}</h5>
    <div class="batch-address">
      <div>
        <div *ngIf="batch.destination.name"><b>{{ batch.destination.name }}</b></div>
        <div *ngIf="batch.destination.address">{{ batch.destination.address.street }}</div>
        <div *ngIf="batch.destination.terminal" class="text-muted">{{ batch.destination.terminal }}</div>
        <p *ngIf="batch.destination.location" class="small text-muted">
          {{ (batch.destination.location.levels|pluck: 'name'|flatten).join(', ') }}</p>
      </div>
      <map class="hidden-xs" [maxZoom]="12" [addresses]="[
        batch.destination.address.street + ', ' + (batch.destination.location.levels|pluck: 'name'|flatten).join(', ')
      ]" [rounded]="true"></map>
    </div>
  </ng-container>
  
  <div *ngIf="batch.observations" class="batch-observations">
    <h5>{{ 'GLOBAL.OBSERVATIONS'|translate }}</h5>
    <quill-editor
    [modules]="{toolbar: false}"
    classes="renderer"
    [readOnly]="true"
    [ngModel]="batch.observations"></quill-editor>
  </div>
</ng-container>