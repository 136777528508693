<ag-republish-massively 
(reloadMyOperations)="reload()"
#republishMassively="agRepublishMassively"
[company]="company"></ag-republish-massively>

<div class="ag-container">
  <div class="ag-col-secondary">
    <filter-set #filterSet="filterSet" [marketId]="company?.market.id">
      <filter-list key="status_group_orders"></filter-list>
      <filter-list key="order_type"
      *ngIf="environment.modules.auctions && company?.market.configuration.allowed_order_types.auction"></filter-list>
      <filter-list key="product_id" sortKey="name"></filter-list>
      <filter-list key="delivery_type"></filter-list>
      <filter-list key="zone_id" sortKey="name"
      *ngIf="company?.market.configuration.location.zone.enabled"></filter-list>
      <filter-select key="range"></filter-select>
    </filter-set>
  </div>
  <div class="ag-col-primary">
    <div class="container-fluid">
      <div class="content-box">
        <div class="ag-header sticky">
          <div class="ag-header-title">
            <div class="summary" *ngIf="!loading && ordersByProduct?.length > 0">
              <span [innerHtml]="'MY_OPERATIONS_VIEW.SUMMARIZE'|translate:{quantityOrder:paginationData.total}"></span>
              <!-- <span *ngIf="paginationData.total_pages > 1" [innerHtml]="'GLOBAL.PAGE_COUNT'|translate:{page: paginationData.current_page, totalPages: paginationData.total_pages}"></span> -->
            </div>
          </div>
          <div class="ag-header-actions" *ngIf="!loading">
            <div class="btn-toolbar">
              <div class="btn-group" dropdown container="body" placement="bottom right">
                <button id="button-basic" title="{{ 'GLOBAL.ADVANCED_OPTIONS'|translate }}" dropdownToggle type="button"
                class="btn btn-link dropdown-toggle btn-sm" aria-controls="dropdown-basic">
                  <i class="material-symbols-rounded">build</i>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                  <li role="menuitem">
                    <a class="dropdown-item" (click)="republishMassively.open()">{{ 'MY_OPERATIONS_VIEW.BULK_REPUBLISH.TITLE'|translate }}</a>
                  </li>
                  <!-- <li role="menuitem" class="disabled">
                    <a>Retirar todas las órdenes abiertas</a>
                  </li> -->
                </ul>
              </div>
              <div class="btn-group"
              *ngIf="paginationData?.total_pages > 1">
                <paginator [data]="paginationData" [loading]="loading"></paginator>
              </div>
            </div>
          </div>
        </div>
        <div class="loading" *ngIf="loading">
          <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
        </div>
        <div class="row" [class.hidden]="loading">
          <div class="col-xs-12">
            <div class="loading" *ngIf="!company">
              <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
            </div>
            <!-- No data -->
            <div class="no-data" *ngIf="ordersByProduct?.length === 0">
              <i class="material-symbols-rounded">not_interested</i>
              {{ 'MY_OPERATIONS_VIEW.NO_RECORDS'|translate }}
            </div>

            <!-- Data -->
            <!-- https://www.w3.org/WAI/tutorials/tables/examples/threeheaders/ -->
            <div [class.hidden]="!company" class="horizontal-scroller">
              <div class="table-sticky-headers">
                <table class="table table-condensed" [class.hidden]="ordersByProduct?.length === 0"
                sortable 
                [variableInUrl]="true">
                  <!-- <caption></caption> -->
                  <thead>
                    <tr class="tr-align-center">
                      <th class="sap hidden-xs" scope="col" id="chk"></th>
                      <th class="sap" sortBy="updated_at">
                        <span class="hidden-xs">{{ 'GLOBAL.DATE'|translate }}</span>
                        <i class="material-symbols-rounded visible-xs">date_range</i>
                      </th>
                      <th scope="col" id="ico"></th>
                      <th class="sap hidden-xs hidden-sm"></th>
                      <th scope="col" id="com" sortBy="companyName">{{ 'GLOBAL.COMPANY'|translate }}</th>
                      <th scope="col" id="qua" class="hidden-xs hidden-sm" sortBy="quality_string">{{ 'GLOBAL.QUALITY'|translate }}</th>
                      <th scope="col" id="loc" class="hidden-xs hidden-sm" sortBy="locationString" *ngIf="company?.market.configuration.location.enabled">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
                      <th scope="col" id="loc" class="hidden-xs hidden-sm" sortBy="business_detail.delivery.geolocations[0].name" *ngIf="company?.market.configuration.geolocation.enabled">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
                      <th scope="col" id="port" class="hidden-xs hidden-sm" sortBy="portString" *ngIf="company?.market.configuration.order.business_detail.port.enabled">{{ 'GLOBAL.COUNTRY_PORT'|translate }}</th>
                      <th class="hidden-xs" sortBy="deliveryType" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
                        <abbr title tooltip="{{ 'GLOBAL.DELIVERY_TYPE'|translate }}"
                        container="body" [adaptivePosition]="false">{{ 'GLOBAL.DELIVERY_TYPE_ABBR'|translate }}</abbr>
                      </th>
                      <th class="sap hidden-xs hidden-sm" sortBy="hasObservations">
                        <abbr title tooltip="{{ 'GLOBAL.OBSERVATIONS'|translate }}"
                        container="body" [adaptivePosition]="false">{{ 'GLOBAL.OBSERVATIONS_ABBR'|translate }}</abbr>
                      </th>
                      <th scope="col" id="ton" class="dlb drb" sortBy="business_detail.quantity.valueString" *ngIf="!company?.market.configuration.order.has_minimum_order">
                        <abbr title tooltip="{{ 'GLOBAL.QUANTITY'|translate }}"
                        container="body" [adaptivePosition]="false">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
                      </th>
                      <th class="sap text-center" sortBy="business_detail.delivery.intDateFrom">{{ 'GLOBAL.PERIOD'|translate }}</th>
                      <th class="sap" sortBy="business_detail.price.value">
                        {{ 'GLOBAL.PRICE'|translate }}
                        <ng-container *ngIf="company?.market.quantity_units.length === 1">/{{ company?.market.quantity_units[0].abbrev }}</ng-container>
                      </th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let group of ordersByProduct">
                    <!-- Product sub-header -->
                    <tr class="product-header">
                      <th scope="colgroup" id="product_{{ group.key.id }}" class="text-uppercase" [colSpan]="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists ? '12' : '11'">{{
                        group.key.name || 'GLOBAL.NOT_AVAILABLE_ABBR'|translate }}</th>
                    </tr>
                    <!-- Order row -->
                    <ng-template ngFor let-order [ngForOf]="group.values">
                      <tr class="order-row tr-align-center"
                      [class.tr-hold]="order.on_hold"
                      [class.tr-danger]="!order.on_hold && order.order_status.group.id === 2">
                        <td class="sap td-checkbox hidden-xs" headers="product_{{ group.key.id }} order_id_{{ order.id }} chk">
                          <i class="material-symbols-rounded text-danger" tooltip="{{ order.order_status.name }}" placement="right" *ngIf="!order.on_hold && order.order_status.group.id === 2">highlight_off</i>
                          <i class="material-symbols-rounded" tooltip="Pausada" placement="right" *ngIf="order.on_hold">pause</i>
                        </td>
                        <td class="sap">
                          <ag-timestamp [date]="order.updated_at"></ag-timestamp>
                        </td>
                        <td headers="product_{{ group.key.id }} order_id_{{ order.id }} ico">
                          <scope-icon [entity]="order"></scope-icon>
                          <i tooltip="{{ order.general_observations }}" placement="right" class="hidden-md hidden-lg material-symbols-rounded"
                            *ngIf="order.general_observations">comment</i>
                        </td>
                        <td class="sap hidden-xs hidden-sm">
                          <company-logo [company]="order.company"></company-logo>
                        </td>
                        <td id="order_id_{{ order.id }}">
                          <i class="material-symbols-rounded small pull-right" *ngIf="order.auction">gavel</i>
                          <span class="order-link">
                            <a class="truncate" routerLink="../order/{{ order.id }}" title="{{ order.companyName }}">
                              <samp class="small hidden-xs hidden-sm">#{{ order.id }}:</samp>
                              <b [class.highlighted-text]="company.id === order.company.id">{{ order.companyName }}</b>
                            </a>
                            <a *ngIf="company.id === order.company?.id && !order.auction" routerLink="../republish-order/{{ order.id }}" tooltip="{{ 'MY_OPERATIONS_VIEW.TOOLTIPS.REPUBLISH'|translate }}" class="order-action">
                              <i class="material-symbols-rounded">file_copy</i>
                            </a>
                            <a *ngIf="company.id === order.company?.id && order.auction" routerLink="../republish-auction/{{ order.id }}" tooltip="{{ 'MY_OPERATIONS_VIEW.TOOLTIPS.REPUBLISH_AUCTION'|translate }}" class="order-action">
                              <i class="material-symbols-rounded">file_copy</i>
                            </a>
                          </span>
                          <div class="visible-xs visible-sm small">
                            <b>{{ order.quality_string }}</b>
                          </div>
                          <div class="visible-xs visible-sm location-small">
                            <span *ngIf="company?.market.configuration.location.enabled" class="clamp-2">{{ order.business_detail.delivery.locations | locationString }}</span>
                            <span *ngIf="company?.market.configuration.geolocation.enabled" class="clamp-2">{{ order.business_detail.delivery.geolocations | locationString:true:'name' }}</span>
                            <span *ngIf="company?.market.configuration.order.business_detail.port.enabled" class="clamp-2">{{ countryAndPort(order) }}</span>
                            <span *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists" class="clamp-2">
                              ({{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }})
                            </span>
                          </div>
                        </td>
                        <td headers="product_{{ group.key.id }} order_id_{{ order.id }} qua" class="hidden-xs hidden-sm small">
                          {{ order.quality_string }}
                        </td>
                        <td headers="product_{{ group.key.id }} order_id_{{ order.id }} loc" class="hidden-xs hidden-sm small" *ngIf="company?.market.configuration.location.enabled">
                          <span class="clamp-2">{{ order.business_detail.delivery.locations | locationString }}</span>
                        </td>
                        <td headers="product_{{ group.key.id }} order_id_{{ order.id }} loc" class="hidden-xs hidden-sm small" *ngIf="company?.market.configuration.geolocation.enabled">
                          <span class="clamp-2">{{ order.business_detail.delivery.geolocations | locationString:true:'name' }}</span>
                        </td>
                        <td headers="product_{{ group.key.id }} order_id_{{ order.id }} port" class="hidden-xs hidden-sm small" *ngIf="company?.market.configuration.order.business_detail.port.enabled">
                          {{ countryAndPort(order) }}
                        </td>
                        <td class="sap hidden-xs small" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
                          {{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }}
                        </td>
                        <td class="sap hidden-xs hidden-sm">
                          <i tooltip="{{ order.general_observations }}" class="material-symbols-rounded" *ngIf="order.hasObservations">comment</i>
                        </td>
                        <td headers="product_{{ group.key.id }} order_id_{{ order.id }} ton" class="text-right" *ngIf="!company?.market.configuration.order.has_minimum_order">
                          <quantity-control [quantity]="order.business_detail.quantity"></quantity-control>
                        </td>
                        <td class="sap">
                          <date-range
                          [showSpot]="order.order_status.group.id === 1"
                          [from]="order.business_detail.delivery.date_from"
                          [to]="order.business_detail.delivery.date_to"></date-range>
                        </td>
                        <td class="sap">
                          <div class="price-box" [class.sell-bg]="order.operation_type === 'venta'" [class.buy-bg]="order.operation_type === 'compra'">
                            <div class="type">{{ (order.operation_type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }}</div>
                            <div class="value">
                              <price-display [price]="order.business_detail.price" [market]="company?.market" [withoutYear]="true"></price-display>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <!-- Counter orders cell // hidden if no counter-orders -->
                      <tr *ngIf="order.negotiations.length"
                      [class.tr-hold]="order.on_hold"
                      [class.tr-danger]="!order.on_hold && order.order_status.group.id === 2">
                        <td class="td-empty" colspan="2"></td>
                        <td class="td-empty hidden-xs hidden-sm"></td>
                        <td class="co-td" [colSpan]="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists ? '9' : '8'">
                          <negotiation-list 
                          [order]="order" 
                          [company]="company"
                          (saveReference)="reload()">
                          </negotiation-list>
                        </td>
                      </tr>
                    </ng-template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
