import { EnvironmentSettings } from "./environment-settings.model";

export const environment: EnvironmentSettings = {
  version: '4.2.250114',
  production: false,
  env: 'dev',
  api: {
    applicationProxy: 'https://public-api.dev.agree.ag',
    applicationsExport: 'https://application-export.dev.agree.ag/',
    base: 'https://api.dev.agree.ag/',
    batches: 'https://batches.dev.agree.ag/',
    chat: 'https://chat.dev.agree.ag/',
    // dataDogLogger: 'https://datadog-logger.dev.agree.ag',
    drive: 'https://drive.dev.agree.ag/',
    files: 'https://files.dev.agree.ag/',
    fintechStats: 'https://fintech-stats.dev.agree.ag/',
    identityValidation: 'https://identity.dev.agree.ag/',
    importer: 'https://4oyv2q26f7zkc3inxehecgwkpu0dgbmd.lambda-url.us-west-2.on.aws/v2/', // 'https://csv-files-importer.dev.agree.ag/v2/',
    integrationsMonitor: 'https://integration-monitor.dev.agree.ag/',
    invoices: 'https://invoices.dev.agree.ag/',
    notes: 'https://company-notes.dev.agree.ag/',
    oauth: 'https://oauth.dev.agree.ag/',
    satCiec: 'https://sat-login.dev.agree.ag/',
    services: 'https://services.agree.ag/',
    signatures: 'https://signatures.dev.agree.ag/',
    tenant: 'https://tenants.dev.agree.ag/',
    users: 'https://users.dev.agree.ag/'
  },
  authClientGrant: {
    client_id: 1,
    client_secret: 'PEAbyLNwbWpDe9Hn7jGz2TsuFJX4H9XFSiiDZtUX',
    grant_type: 'password',
  },
  intercomID: 'umnk00gd',
  google: {
    apiKey: 'AIzaSyCqU8-QHvWVR5uRg4Heut9bIS5SbnBGOMs', // google places
    apiClientID: '304066128789-73oq77cpeaa368q7999itr0ejppq2dd6.apps.googleusercontent.com', // google sign in
    id: '',
    maps: {
      Autocomplete: {
        componentRestrictions: null
        // {
        //   country: ['ar'] // An array of up to five country code strings
        // }
      }
    }
  },
  // scripts: [],
  pusher: {
    key: 'd430f0b5b4c786bbe76f',
    cluster: 'us2',
  },
  languages: [
    { name: 'Español (Argentina)', slug: 'es' },
    { name: 'Español', slug: 'es-mx' },
    { name: 'English', slug: 'en' },
    { name: 'Português', slug: 'pt' }
  ],
  modules: {
    auctions: false,
    barters: true, // admin module
    certificate_deposit: true,
    chat: true,
    contract_applications: true,
    contracts: true,
    cpe: true,
    commercial: true,
    execution: true, // admin module
    fintech_screening: true, // admin module
    fintech: true, // admin module
    fixations: true,
    futures: false, // not operational
    imported_data: true, // admin module to define 'Importer companies'
    invoices: true,
    liquidaciones: true,
    market_prices: true, // admin module
    market: true, // admin module
    my_network: true, // admin module
    operations_alerts: false,
    signatures: true,
    slots: true, // admin module
    unloads: true,
    waybills: true
  },
  maps: {
    mapbox: false,
    google: true
  },
  fintech: {
    skipSupplier: true
  },
  // firebase_auth: {
  //   email: "info@agreemarket.com",
  //   password: "agr33market"
  // },
  // firebase: {
  //   apiKey: "AIzaSyBZtbXQBNGWSvD0e_QYixIoiP50leTicxc",
  //   authDomain: "agree-dev.firebaseapp.com",
  //   databaseURL: "https://agree-dev.firebaseio.com",
  //   projectId: "agree-dev",
  //   storageBucket: "agree-dev.appspot.com",
  //   messagingSenderId: "120690008628",
  //   appId: "1:120690008628:web:c23cc4f1bd7f0a0462a061",
  //   measurementId: "G-P5YZSNNREN"
  // },
  datadog: {
    // CLIENT_TOKEN: 'pub827341109f8308cddb09421ba5b5ff1e',
    service: 'angular-webapp'
    // source: 'angular'
  },
  data_studio: {
    boards: {
      // invoices: "https://datastudio.google.com/embed/reporting/2e5bcc5b-c769-481e-91a1-0299a0e3ea75/page/p_3lies3qjwc",
      // negotiations: "https://datastudio.google.com/embed/reporting/9ad7a59d-08f8-419f-84b0-42c6a56c86f4/page/p_3lies3qjwc",
      contracts: 'https://datastudio.google.com/embed/reporting/5f71c1c2-4304-4db6-bcf8-dc67ba3c363d/page/eek8C',
      financial: 'https://lookerstudio.google.com/embed/reporting/f05aed6a-7da0-4522-9f8d-37717fee14db/page/oCtID',
    },
    visibilityByRole: {
      financial: ['financial']
    },
    exceptions: {
      // Company IDs to handle exceptions
      88: { // Insumos Agro
        financial: '',
      }
    }
  },
  visibilityByRepresentativeRoles: {
    88: {
      business_officer: ['contracts', 'fixations'],
      commercial: ['contracts', 'fixations'],
      logistics: ['contracts', 'fixations'],
      operations: ['contracts', 'fixations'],
    }
  }
};
