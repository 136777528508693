<form #proposalForm="ngForm" [class.condensed]="condensed">
  <div class="proposal-container">
    <div class="media-container no-select" *ngIf="proposal_media.length">
      <!-- Media gallery -->
      <div class="fullscreen" *ngIf="media_fullscreen">
        <div class="media-browser">
          <div class="arrow back" (click)="previous($event)" *ngIf="proposal_media.length > 1">
            <i class="material-symbols-rounded">keyboard_arrow_left</i>
          </div>
          <div class="arrow fwrd" (click)="next($event)" *ngIf="proposal_media.length > 1">
            <i class="material-symbols-rounded">keyboard_arrow_right</i>
          </div>
          <div class="arrow close" (click)="toggleFullscreen($event)">
            <i class="material-symbols-rounded">close</i>
          </div>
        </div>
        <div class="margin">
          <div class="img" [style.backgroundImage]="'url(' + proposal_media[active_media].uri + ')'"></div>
        </div>
      </div>
      <div class="thumb-list" *ngIf="proposal_media.length > 1">
        <div class="media-thumb" *ngFor="let a of proposal_media; let i = index" [class.active]="i === active_media"
          (mouseenter)="switchMedia(i)">
          <div class="img" [style.backgroundImage]="'url(' + a.uri + ')'"></div>
        </div>
      </div>
      <div class="selected-media" (click)="toggleFullscreen($event)">
        <div class="media-browser" *ngIf="proposal_media.length > 1">
          <div class="arrow back" (click)="previous($event)">
            <i class="material-symbols-rounded">keyboard_arrow_left</i>
          </div>
          <div class="arrow fwrd" (click)="next($event)">
            <i class="material-symbols-rounded">keyboard_arrow_right</i>
          </div>
        </div>
        <div class="img" [style.backgroundImage]="'url(' + proposal_media[active_media].uri + ')'"></div>
      </div>
    </div>
    <div class="details-box">
      <!-- <div class="container-fluid"> -->
      <div class="row row-main">
        <!-- Order data -->
        <div class="col-xs-6" [class.col-md-3]="!proposal_media.length"
          *ngIf="!(hideUndefined && !proposal.business_detail.quantity.value) || formMode">
          <!-- Quantity -->
          <negotiable-group [formMode]="formMode" [direction]="direction"
            [negotiableValue]="proposal.negotiability.business_detail.quantity" [negotiableMode]="negotiableMode">
            <div class="negotiable-label text-right">
              <!--  negotiable && !proposal.id ??? -->
              <on-off-switch class="pull-right" [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="proposal.negotiability.business_detail.quantity" *ngIf="
                  !shouldDisableSwitches() &&
                  (
                    !proposal.business_detail.quantity.type ||
                    proposal.business_detail.quantity.type.id !== 2
                  )"></on-off-switch>
              <span class="not-negotiable-message hidden-xs material-symbols-rounded">lock</span>
              <span>
                {{ !formMode && order.product?.attributes?.order_quantity_label ?
                (order.product?.attributes.order_quantity_label[order.operation_type] ||
                order.product?.attributes.order_quantity_label) : 'GLOBAL.QUANTITY'|translate }}
              </span>
            </div>
            <div class="negotiable-static text-right">
              <quantity-control [quantity]="proposal.business_detail.quantity" class="quantity-bold"></quantity-control>
              <ng-container *ngIf="proposal.business_detail.quantity_calculator">
                &nbsp;<a (click)="volumeCalculator.show()" class="small" tooltip="{{ 'VOLUME.CALCULATOR'|translate }}">
                  <span class="material-symbols-rounded">help_outline</span>
                </a>
                <ag-volume-calculator #volumeCalculator [readonly]="true"
                  [(business-detail)]="proposal.business_detail"></ag-volume-calculator>
              </ng-container>
            </div>
            <div class="negotiable-form" ngModelGroup="quantityGroup">
              <div class="input-group" [class.has-warning]="!proposal.business_detail.quantity.value">
                <input name="quantity" type="number" step="1" class="form-control"
                  placeholder="{{ 'GLOBAL.QUANTITY'|translate }}"
                  [agAutoFocus]="!proposal.business_detail.quantity.value"
                  [(ngModel)]="proposal.business_detail.quantity.value" trackedControl
                  [previousValue]="previousProposal?.business_detail.quantity.value"
                  [disabled]="!editable || (!isAmendment && negotiableMode !== 'editable' && proposal.negotiability.business_detail.quantity)"
                  [min]="1" [max]="9999999" required>
                <span class="input-group-addon">
                  {{ proposal.business_detail.quantity.unit.name_plural || proposal.business_detail.quantity.unit.abbrev
                  }}
                </span>
              </div>
            </div>
          </negotiable-group>
        </div>
        <div class="col-xs-6" [class.col-md-3]="!proposal_media.length"
          *ngIf="!(hideUndefined && !proposal.business_detail.price.value) || formMode">
          <!-- Price -->
          <negotiable-group [formMode]="formMode" [direction]="direction"
            [negotiableValue]="proposal.negotiability?.business_detail.price" [negotiableMode]="negotiableMode">
            <div class="negotiable-label text-right">
              <on-off-switch *ngIf="!shouldDisableSwitches()" class="pull-right" [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="proposal.negotiability.business_detail.price"></on-off-switch>
              <span class="not-negotiable-message hidden-xs material-symbols-rounded">lock</span>
              <ng-container [ngSwitch]="order.type?.id">
                <!-- Pre-order -->
                <span *ngSwitchCase="2">{{ (order.operation_type === 'compra' ? 'PROPOSAL.PRICE.LABEL_MAX' :
                  'PROPOSAL.PRICE.LABEL_MIN')|translate }}</span>
                <!-- Auction -->
                <span *ngSwitchCase="4">{{ (order.operation_type === 'compra' ? 'PROPOSAL.PRICE.OFFER_MAX' :
                  'PROPOSAL.PRICE.OFFER_MIN')|translate }}</span>
                <span *ngSwitchDefault>{{ 'GLOBAL.PRICE'|translate }}</span>
              </ng-container>
              <ng-container *ngIf="company?.market.quantity_units.length === 1">
                /{{ company.market.quantity_units[0].abbrev }}
              </ng-container>
            </div>
            <div class="negotiable-static text-right">
              <b>
                <price-display [showConditions]="true" [price]="proposal.business_detail.price"
                  [market]="company?.market" [small]="true"></price-display>
              </b>
            </div>
            <div class="negotiable-form" ngModelGroup="priceGroup">
              <price-form [showQuantityUnit]="false" [price]="proposal.business_detail.price" [currencies]="currencies"
                placeholder="{{ 'PROPOSAL.PRICE.PLACEHOLDER'|translate }}" [min]="0.01"
                [previousPrice]="previousProposal?.business_detail.price" trackedGroup
                [trackedGroupInitialValue]="proposal.business_detail.price"
                [trackedGroupPreviousValue]="previousProposal?.business_detail.price"
                [trackedGroupDisplayAs]="priceToString"
                [disabled]="!editable || (!isAmendment && negotiableMode !== 'editable' && proposal.negotiability.business_detail.price)">
              </price-form>
            </div>
          </negotiable-group>
        </div>
        <div class="col-xs-6" [class.col-md-3]="!proposal_media.length"
          *ngIf="!(hideUndefined && !proposal.business_detail.delivery.date_to) || formMode">
          <!-- Delivery period -->
          <negotiable-group [formMode]="formMode" [direction]="direction"
            [negotiableValue]="proposal.negotiability?.business_detail.delivery.range"
            [negotiableMode]="negotiableMode">
            <div class="negotiable-label text-center">
              <on-off-switch *ngIf="!shouldDisableSwitches()" class="pull-right" [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="proposal.negotiability.business_detail.delivery.range"></on-off-switch>
              <span class="not-negotiable-message hidden-xs material-symbols-rounded">lock</span>
              <span>{{ company?.market.configuration.order.business_detail.delivery.range.label }}</span>
            </div>
            <div class="negotiable-static text-center">
              <ng-container
                *ngIf="proposal.business_detail.delivery.date_from && proposal.business_detail.delivery.date_to; else none">
                <date-range
                [simple]="true"
                [showSpot]="true"
                [from]="proposal.business_detail.delivery.date_from"
                [to]="proposal.business_detail.delivery.date_to"></date-range>
              </ng-container>
            </div>
            <div class="negotiable-form" ngModelGroup="deliveryDateGroup">
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="material-symbols-rounded">date_range</i>
                </div>
                <input type="text" name="deliveryDate" class="form-control"
                  placeholder="{{ 'GLOBAL.RANGE_PLACEHOLDER'|translate }}" placement="left" bsDaterangepicker
                  [minDate]="deliveryMinDate" container=".details-box"
                  [bsConfig]="{adaptivePosition: true, showWeekNumbers: false, rangeInputFormat: 'DD/MM/YYYY'}"
                  [(ngModel)]="proposal.business_detail.delivery.range"
                  [disabled]="!editable || (!isAmendment && negotiableMode !== 'editable' && proposal.negotiability?.business_detail.delivery.range)"
                  required readonly #datePickerModel="ngModel" trackedControl
                  [previousValue]="previousProposal?.business_detail.delivery.range" [compareWith]="compareDateRange"
                  [displayAs]="dateRangeToString">
              </div>
              <!-- FAS-1887 -->
              <div *ngIf="adjustedFields.deliveryDate && datePickerModel.pristine" class="help-block small">
                <span class="text-danger" [innerHtml]="'PROPOSAL.ADJUSTED_FIELDS.DELIVERYDATE'|translate"></span>
              </div>
            </div>
          </negotiable-group>
        </div>
        <div class="col-xs-6" [class.col-md-3]="!proposal_media.length" *ngIf="(company?.market.configuration.location.enabled || company?.market.configuration.geolocation.enabled) &&
          order.type?.id !== 8 &&
          (!(hideUndefined && !proposal.business_detail.delivery.locations?.length) || formMode)">
          <!-- Delivery places -->
          <negotiable-group [formMode]="false">
            <div class="negotiable-label">
              <span>
                {{ 'GLOBAL.DELIVERY_PLACES'|translate }}
              </span>
            </div>
            <div class="negotiable-static" *ngIf="company?.market.configuration.location.enabled">
              <span
                *ngIf="!proposal.business_detail.delivery.locations || proposal.business_detail.delivery.locations?.length === 0">-</span>
              {{ proposal.business_detail.delivery.locations |locationString }}
              <span *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists"
                class="text-muted" title="{{ 'GLOBAL.DELIVERY_TYPE'|translate }}">({{
                proposal.business_detail.delivery.delivery_type?.short_name ||
                proposal.business_detail.delivery.delivery_type?.name }})</span>
              <span class="small help-block"
                *ngIf="proposal.business_detail.delivery.locations &&
                  (proposal.business_detail.delivery.locations.length > 1 || proposal.business_detail.delivery.locations[0]?.zone)">
                {{ 'PROPOSAL.DELIVERY_LOCATIONS.BUYER_OPTION'|translate }}
              </span>
            </div>
            <div class="negotiable-static" *ngIf="company?.market.configuration.geolocation.enabled">
              {{ proposal.business_detail.delivery.geolocations |locationString:true }}
              <span *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists"
                class="text-muted" title="{{ 'GLOBAL.DELIVERY_TYPE'|translate }}">({{
                proposal.business_detail.delivery.delivery_type?.short_name ||
                proposal.business_detail.delivery.delivery_type?.name }})</span>
              <span class="small help-block" *ngIf="proposal.business_detail.delivery.geolocations?.length > 1">
                {{ 'PROPOSAL.DELIVERY_LOCATIONS.BUYER_OPTION'|translate }}
              </span>
            </div>
            <div class="negotiable-form" ngModelGroup="locationsGroup">
            </div>
          </negotiable-group>
        </div>
        <div class="col-xs-12" [class.col-md-6]="!proposal_media.length" *ngIf="proposal.destination &&
          (!(hideUndefined && !proposal.destination) || formMode)">
          <!-- Deposit location -->
          <negotiable-group [formMode]="false">
            <div class="negotiable-label">
              <span>{{ 'ORDER.DEPOSIT_LOCATION'|translate }}</span>
            </div>
            <div class="negotiable-static">
              <div class="row">
                <div class="col-sm-9">
                  <!-- <label class="control-label">{{ 'SLOTS.ADD_MODAL.ADDRESS'|translate }}</label> -->
                  <p>{{ proposal.destination.name }}</p>
                  <div class="small">
                    <company-info [company]="proposal.destination.company" [activity]="false"></company-info>
                    <div>
                      {{ proposal.destination.address.street }}
                      <span *ngIf="proposal.destination.address.postal_code" class="text-muted">({{
                        proposal.destination.address.postal_code }})</span>
                    </div>
                    <div *ngIf="proposal.destination.location?.levels; let levels" class="small">
                      {{ levels[0].name }}
                      {{ levels[2] ? '(' + levels[2].name + ')' : '' }}
                    </div>
                    <div *ngIf="proposal.destination.terminal" class="small text-muted">{{ proposal.destination.terminal
                      }}</div>
                  </div>
                </div>
                <div class="col-sm-3 hidden-xs">
                  <map [maxZoom]="12" [addresses]="[
                      proposal.destination.address.street + ', ' + (proposal.destination.location.levels|pluck: 'name'|flatten).join(', ')
                    ]" [rounded]="true"></map>
                </div>
              </div>
            </div>
            <!-- <div class="negotiable-form" ngModelGroup="locationsGroup">
              </div> -->
          </negotiable-group>
        </div>
        <div class="col-xs-6" [class.col-md-3]="!proposal_media.length"
          *ngIf="order.product?.attributes?.loading_rates">
          <!-- Loading rate -->
          <negotiable-group [formMode]="formMode" [direction]="direction"
            [negotiableValue]="proposal.negotiability.business_detail.loading_rate" [negotiableMode]="negotiableMode">
            <div class="negotiable-label text-right">
              <!--  negotiable && !proposal.id ??? -->
              <on-off-switch class="pull-right" [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="proposal.negotiability.business_detail.loading_rate" *ngIf="!shouldDisableSwitches()">
              </on-off-switch>
              <span class="not-negotiable-message hidden-xs material-symbols-rounded">lock</span>
              <span>
                {{ 'PROPOSAL.LOADING_RATE.LABEL'|translate }}
              </span>
            </div>
            <div class="negotiable-static text-right">
              {{proposal.business_detail.loading_rate.value}}
              <span *ngIf="proposal.business_detail.loading_rate.other">{{ proposal.business_detail.loading_rate.other
                }}</span>
              <span
                *ngIf="!proposal.business_detail.loading_rate.other && proposal.business_detail.loading_rate.metric">{{
                proposal.business_detail.loading_rate.metric.name }}</span>
            </div>
            <div class="negotiable-form" ngModelGroup="loadingRateGroup">
              <div class="input-group">
                <input name="loading_rate" type="number" step="1" class="form-control"
                  placeholder="{{ 'PROPOSAL.LOADING_RATE.PLACEHOLDER'|translate }}"
                  [(ngModel)]="proposal.business_detail.loading_rate.value" [min]="0" [max]="99999999" trackedControl
                  [previousValue]="previousProposal?.business_detail.loading_rate.value"
                  [disabled]="!editable || (!isAmendment && negotiableMode !== 'editable' && proposal.negotiability.business_detail.loading_rate)"
                  required>
                <div class="input-group-btn">
                  <div dropdown agSelect name="loading_rate_metric" class="btn-group"
                    [(ngModel)]="proposal.business_detail.loading_rate.metric">
                    <button dropdownToggle type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      {{ proposal.business_detail.loading_rate?.metric?.name }}
                      <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                      <li *ngFor="let metric of order.product?.attributes.loading_rates.metrics" [agOption]="metric">
                        <a>{{ metric.name }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </negotiable-group>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>

  <div class="row">
    <!-- Order data -->
    <div class="col-md-6">
      <!-- Quality -->
      <negotiable-group [formMode]="formMode" [direction]="direction"
        [negotiableValue]="proposal.negotiability.product_detail.quality" [negotiableMode]="negotiableMode">
        <div class="negotiable-label">
          <on-off-switch *ngIf="!shouldDisableSwitches()" class="pull-right" [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="proposal.negotiability.product_detail.quality"></on-off-switch>
          <span class="not-negotiable-message hidden-xs material-symbols-rounded">lock</span>
          <span>
            {{ 'GLOBAL.QUALITY'| translate }}
          </span>
        </div>
        <div class="negotiable-static">
          <span *ngIf="!(proposal.product_detail.quality|pairs).length">
            {{ 'PROPOSAL.QUALITY.PLACEHOLDER'|translate }}
          </span>
          {{ displayQuality(proposal.product_detail.quality) }}
        </div>
        <div class="negotiable-form" ngModelGroup="qualityGroup">
          <quality-input name="quality" [(ngModel)]="proposal.product_detail.quality" [product]="order.product"
            [disabled]="!editable || (!isAmendment && negotiableMode !== 'editable' && proposal.negotiability.product_detail.quality)"
            trackedControl [previousValue]="previousProposal?.product_detail.quality" [compareWith]="compareQuality"
            [displayAs]="displayQuality.bind(this)">
          </quality-input>
        </div>
      </negotiable-group>
      <!-- FAS-1360 -->
      <ag-product-quality-info [order]="order" [proposal]="proposal" key="type"></ag-product-quality-info>
    </div>
    <div class="col-xs-6 col-md-3" *ngIf="order.product?.attributes?.harvest.enabled &&
    (!(hideUndefined && !proposal.business_detail.harvest) || formMode)">
      <!-- Crop year -->
      <negotiable-group [formMode]="formMode" [direction]="direction"
        [negotiableValue]="proposal.negotiability.business_detail.harvest" [negotiableMode]="negotiableMode">
        <div class="negotiable-label">
          <on-off-switch *ngIf="!shouldDisableSwitches()" class="pull-right" [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="proposal.negotiability.business_detail.harvest"></on-off-switch>
          <span class="not-negotiable-message hidden-xs material-symbols-rounded">lock</span>
          <span>{{ 'GLOBAL.CROP_YEAR'|translate }}</span>
        </div>
        <div class="negotiable-static">
          <span *ngIf="proposal.business_detail.harvest; else none">{{ proposal.business_detail.harvest }}</span>
        </div>
        <div class="negotiable-form" ngModelGroup="harvestGroup">
          <select *ngIf="formMode" class="form-control" name="harvest" [(ngModel)]="proposal.business_detail.harvest"
            [disabled]="!editable || (!isAmendment && negotiableMode !== 'editable' && proposal.negotiability.business_detail.harvest)"
            trackedControl [previousValue]="previousProposal?.business_detail.harvest" required>
            <option [ngValue]="null" [selected]="true" [disabled]="true" hidden>{{
              'GLOBAL.CROP_YEAR_PLACEHOLDER'|translate }}</option>
            <option *ngFor="let crop of cropList" [ngValue]="crop">{{ crop }}</option>
          </select>
        </div>
      </negotiable-group>
    </div>
    <div class="col-xs-6 col-md-3" *ngIf="company?.market.configuration.location.enabled &&
    (!(hideUndefined && !proposal.business_detail.origin?.name) || formMode)">
      <!-- Origin -->
      <negotiable-group [formMode]="false">
        <div class="negotiable-label">
          <span>{{ 'GLOBAL.ORIGIN'|translate }}</span>
        </div>
        <div class="negotiable-static">
          <span *ngIf="proposal.business_detail.origin?.name; else none">{{ proposal.business_detail.origin.name
            }}</span>
        </div>
        <div class="negotiable-form" ngModelGroup="originGroup">
        </div>
      </negotiable-group>
    </div>
    <div class="col-xs-6 col-md-3" *ngIf="company?.market.configuration.geolocation.enabled &&
    (!(hideUndefined && !proposal.business_detail.geo_origin?.length) || formMode)">
      <!-- Origin -->
      <negotiable-group [formMode]="false">
        <div class="negotiable-label">
          <span>{{ 'GLOBAL.ORIGIN'|translate }}</span>
        </div>
        <div class="negotiable-static">
          <span>
            {{ (proposal.business_detail.geo_origin && proposal.business_detail.geo_origin[0]?.formatted_address) || '-'
            }}
          </span>
        </div>
        <div class="negotiable-form" ngModelGroup="originGroup">
        </div>
      </negotiable-group>
    </div>
    <div class="col-xs-6 col-md-3" *ngIf="order.product.attributes?.ports &&
    (!(hideUndefined && !proposal.business_detail.port?.name) || formMode)">
      <!-- Port -->
      <negotiable-group [formMode]="formMode" [direction]="direction" [negotiableValue]="proposal.negotiability.port"
        [negotiableMode]="negotiableMode">
        <div class="negotiable-label">
          <on-off-switch *ngIf="!shouldDisableSwitches()" class="pull-right" [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="proposal.negotiability.port"></on-off-switch>
          <span class="not-negotiable-message hidden-xs material-symbols-rounded">lock</span>
          <span>
            {{ 'PROPOSAL.PORT.LABEL'|translate }}
          </span>
        </div>
        <div class="negotiable-static">
          {{ proposal.business_detail.port.name }}
        </div>
        <div class="negotiable-form" ngModelGroup="portGroup">
          <select class="form-control" name="port" [(ngModel)]="proposal.business_detail.port" trackedControl
            [previousValue]="previousProposal?.business_detail.port" [compareWith]="compareId" [displayAs]="displayName"
            [disabled]="!editable || (!isAmendment && negotiableMode !== 'editable' && proposal.negotiability.port)"
            [required]="order.product.attributes.ports">
            <option [ngValue]="undefined" [selected]="true" [disabled]="true" hidden>
              {{ 'PROPOSAL.PORT.PLACEHOLDER'|translate }}
            </option>
            <option *ngFor="let port of order.product.attributes.ports" [ngValue]="port">{{ port.name }}</option>
          </select>
        </div>
      </negotiable-group>
    </div>
    <div class="col-xs-6 col-md-3"
      *ngIf="order.product.attributes?.ports &&
    (!(hideUndefined && (!proposal.business_detail.berth?.name || !proposal.business_detail.other_berth)) || formMode)">
      <!-- Berth -->
      <negotiable-group [formMode]="formMode" [direction]="direction" [negotiableValue]="proposal.negotiability.berth"
        [negotiableMode]="negotiableMode">
        <div class="negotiable-label">
          <on-off-switch *ngIf="!shouldDisableSwitches()" class="pull-right" [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="proposal.negotiability.berth"></on-off-switch>
          <span class="not-negotiable-message hidden-xs material-symbols-rounded">lock</span>
          <span>
            {{ 'PROPOSAL.BERTH.LABEL'|translate }}
          </span>
        </div>
        <div class="negotiable-static">
          <span *ngIf="!proposal.business_detail.other_berth">{{ proposal.business_detail.berth.name }}</span>
          <span *ngIf="proposal.business_detail.other_berth">{{ proposal.business_detail.other_berth }}</span>
        </div>
        <div class="negotiable-form" ngModelGroup="berthGroup">
          <select class="form-control" name="berth" [(ngModel)]="proposal.business_detail.berth" trackedControl
            [previousValue]="previousProposal?.business_detail.berth" [compareWith]="compareId"
            [displayAs]="displayName"
            [disabled]="!editable || (!isAmendment && negotiableMode !== 'editable' && proposal.negotiability.berth)"
            [required]="order.product.attributes.ports">
            <option [ngValue]="undefined" [selected]="true" [disabled]="true" hidden>
              {{ 'PROPOSAL.BERTH.PLACEHOLDER'|translate }}
            </option>
            <option *ngFor="let berth of order.business_detail.port.berths" [ngValue]="berth">{{ berth.name }}</option>
          </select>
        </div>
      </negotiable-group>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6 col-md-3" *ngIf="!excludeFields.includes('paymentCondition') &&
    (!(hideUndefined && !proposal.payment_detail?.payment_condition) || formMode)">
      <!-- Payment conditions -->
      <negotiable-group [formMode]="formMode" [direction]="direction"
        [negotiableValue]="proposal.negotiability.payment_detail.payment_condition" [negotiableMode]="negotiableMode">
        <div class="negotiable-label">
          <on-off-switch *ngIf="!shouldDisableSwitches()" class="pull-right" [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="proposal.negotiability.payment_detail.payment_condition"></on-off-switch>
          <span class="not-negotiable-message hidden-xs material-symbols-rounded">lock</span>
          <span>
            {{ 'GLOBAL.PAYMENT_CONDITION'|translate }}
          </span>
        </div>
        <div class="negotiable-static">
          <ng-container *ngIf="!proposal.payment_detail?.payment_condition; else condition">-</ng-container>
          <ng-template #condition>
            <span *ngIf="proposal.payment_detail.payment_condition?.id !== 3">{{
              proposal.payment_detail.payment_condition?.name }}</span>
            <span
              *ngIf="proposal.payment_detail.payment_condition?.id === 2 && proposal.payment_detail.payment_condition?.id !== 3">({{
              proposal.payment_detail.date|date: ('GLOBAL.DATE_FORMAT.SM'|translate) }})</span>
            <span *ngIf="proposal.payment_detail.payment_condition?.id === 3">{{ proposal.payment_detail.other }}</span>
          </ng-template>
        </div>
        <div class="negotiable-form" ngModelGroup="paymentGroup">
          <payment-condition-input name="paymentDetail" [(ngModel)]="proposal.payment_detail" trackedControl
            [previousValue]="previousProposal?.payment_detail" [compareWith]="comparePayment"
            [displayAs]="displayPayment"
            [disabled]="!editable || (!isAmendment && negotiableMode !== 'editable' && proposal.negotiability.payment_detail.payment_condition)">
          </payment-condition-input>
        </div>
      </negotiable-group>
    </div>
    <div class="col-xs-6 col-md-3" *ngIf="!excludeFields.includes('commission') &&
    (!(hideUndefined && proposal.commission == undefined) || formMode)">
      <!-- Payment conditions -->
      <negotiable-group [formMode]="formMode" [direction]="direction"
        [negotiableValue]="proposal.negotiability.commission" [negotiableMode]="negotiableMode">
        <div class="negotiable-label">
          <on-off-switch *ngIf="!shouldDisableSwitches()" class="pull-right" [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="proposal.negotiability.commission"></on-off-switch>
          <span class="not-negotiable-message hidden-xs material-symbols-rounded">lock</span>
          <span>
            {{ 'GLOBAL.COMMISSION'|translate }}
          </span>
        </div>
        <div class="negotiable-static">
          <ng-container *ngIf="proposal.commission != undefined; else none">
            {{ proposal.commission }}
            <span class="text-muted">%</span>
          </ng-container>
        </div>
        <div class="negotiable-form" ngModelGroup="comissionGroup">
          <div class="input-group">
            <input name="commission" type="number" class="form-control" [min]="0" [max]="100" step="0.01"
              placeholder="0.00" [(ngModel)]="proposal.commission" trackedControl
              [previousValue]="previousProposal?.commission"
              [disabled]="!editable || (!isAmendment && negotiableMode !== 'editable' && proposal.negotiability.commission)">
            <span class="input-group-addon">%</span>
          </div>
        </div>
      </negotiable-group>
    </div>
    <div class="col-xs-6 col-md-3" *ngIf="!excludeFields.includes('own_production') &&
    proposal.own_production &&
    !formMode">
      <!-- Own production -->
      <negotiable-group [formMode]="formMode" [direction]="direction"
        [negotiableValue]="proposal.negotiability.own_production" [negotiableMode]="negotiableMode">
        <div class="negotiable-label">
          <!-- <on-off-switch
          *ngIf="!shouldDisableSwitches()" class="pull-right"
          [ngModelOptions]="{ standalone: true }" [(ngModel)]="proposal.negotiability.own_production"></on-off-switch>
          <span class="not-negotiable-message hidden-xs material-symbols-rounded">lock</span> -->
          <span>
            {{ 'ORDER.PRODUCTION'|translate }}
          </span>
        </div>
        <div class="negotiable-static">
          {{ 'ORDER.OWN_PRODUCED'|translate }}
        </div>
        <div class="negotiable-form" ngModelGroup="ownproducedGroup">
          <!-- <div class="input-group">
            <input name="own_production" type="number" class="form-control"
            [min]="0" [max]="100" step="0.01"
            placeholder="0.00"
            [(ngModel)]="proposal.own_production"
            trackedControl
            [previousValue]="previousProposal?.own_production"
            [disabled]="!editable || (!isAmendment && negotiableMode !== 'editable' && proposal.negotiability.own_production)">
            <span class="input-group-addon">%</span>
          </div> -->
        </div>
      </negotiable-group>
    </div>
    <div class="col-xs-6 col-md-3" [class.hidden]="!company?.market.configuration.order.arbitration_chamber.enabled"
      *ngIf="!excludeFields.includes('chamber') &&
    (!(hideUndefined && !proposal.arbitration_chamber?.name) || formMode)">
      <!-- Chambers -->
      <negotiable-group [formMode]="formMode" [direction]="direction"
        [negotiableValue]="proposal.negotiability.arbitration_chamber" [negotiableMode]="negotiableMode">
        <div class="negotiable-label">
          <on-off-switch *ngIf="!shouldDisableSwitches()" class="pull-right" [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="proposal.negotiability.arbitration_chamber"></on-off-switch>
          <span class="not-negotiable-message hidden-xs material-symbols-rounded">lock</span>
          <span>
            {{ 'PROPOSAL.ARBITRATION_CHAMBER.LABEL'|translate }}
          </span>
        </div>
        <div class="negotiable-static">
          <span *ngIf="proposal.arbitration_chamber?.name; else none">{{ proposal.arbitration_chamber.name }}</span>
        </div>
        <div class="negotiable-form" ngModelGroup="chamberGroup">
          <!-- select.compareWith && trackedControl.compareWith -->
          <select class="form-control" name="chamber" [(ngModel)]="proposal.arbitration_chamber" trackedControl
            [previousValue]="previousProposal?.arbitration_chamber" [compareWith]="compareId" [displayAs]="displayName"
            [disabled]="!editable || (!isAmendment && negotiableMode !== 'editable' && proposal.negotiability.arbitration_chamber)">
            <option [ngValue]="undefined" [selected]="true" [disabled]="true" hidden>
              {{ 'PROPOSAL.ARBITRATION_CHAMBER.PLACEHOLDER'|translate }}
            </option>
            <option *ngFor="let chamber of chambers" [ngValue]="chamber">{{ chamber.name }}</option>
          </select>
        </div>
      </negotiable-group>
    </div>
  </div>
  <!-- FAS-1368 -->
  <div class="row" *ngIf="company?.market.configuration.order.contract.type === 'contract_clauses'">
    <!-- Contract -->
    <div class="col-xs-12">
      <negotiable-group [showChanges]="formMode ? true : false" [contractHasChanged]="contractHasChanged"
        [isNew]="isNew" [direction]="direction">
        <div class="negotiable-label">
          <span>{{ 'GLOBAL.CONTRACT'|translate }}
            <span *ngIf="order?.has_contract_change && !formMode" class="warning-label">&nbsp;{{
              'PROPOSAL.CONTRACT.DEVIATIONS'|translate }}&nbsp;</span>
          </span>
        </div>
        <div class="negotiable-always">
          <contract-clauses-form #contract [contract]="proposal.contract_detail" [order]="order"
            [negotiation]="negotiation" [language]="order.language || company?.market.language.slug" [company]="company"
            [negotiability]="proposal.negotiability" [formMode]="formMode" [direction]="direction"
            [negotiableMode]="negotiableMode" [previousContract]="previousContract"
            [locations]="proposal.business_detail.delivery.locations" (notify)='onNotify($event)'>
            <div ngModelGroup="allClauses"></div>
          </contract-clauses-form>
        </div>
      </negotiable-group>
    </div>
  </div>
  <div class="row" *ngIf="company?.market.configuration.order.observations?.enabled &&
  (!(hideUndefined && !proposal.general_observations) || formMode)">
    <!-- Observations -->
    <div class="col-xs-12">
      <negotiable-group [formMode]="formMode" [direction]="direction"
        [negotiableValue]="proposal.negotiability.general_observations" [negotiableMode]="negotiableMode">
        <div class="negotiable-label">
          <on-off-switch *ngIf="!shouldDisableSwitches()" class="pull-right" [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="proposal.negotiability.general_observations"></on-off-switch>
          <span class="not-negotiable-message hidden-xs material-symbols-rounded">lock</span>
          <span>{{ 'GLOBAL.OBSERVATIONS'|translate }}</span>
        </div>
        <div class="negotiable-static">
          <span *ngIf="proposal.general_observations; else none">{{ proposal.general_observations }}</span>
        </div>
        <div class="negotiable-form" ngModelGroup="observationsGroup">
          <textarea class="form-control" rows="6" name="obs" [(ngModel)]="proposal.general_observations" trackedControl
            maxlength="1024" [previousValue]="previousProposal?.general_observations" [compareWith]="compareString"
            [disabled]="!editable || (!isAmendment && negotiableMode !== 'editable' && proposal.negotiability.general_observations)"></textarea>
        </div>
      </negotiable-group>
    </div>
  </div>
</form>

<ng-template #none>
  <span class="text-muted no-select">&#8212;</span>
</ng-template>
