import { createBatch } from './batch';
import { createHttpRequest } from './httpRequest';
import { createFlushController } from './flushController';
export function startBatchWithReplica(configuration, primary, replica, reportError, pageExitObservable, sessionExpireObservable, batchFactoryImp) {
    if (batchFactoryImp === void 0) { batchFactoryImp = createBatch; }
    var primaryBatch = createBatchFromConfig(configuration, primary);
    var replicaBatch = replica && createBatchFromConfig(configuration, replica);
    function createBatchFromConfig(configuration, _a) {
        var endpoint = _a.endpoint, encoder = _a.encoder;
        return batchFactoryImp({
            encoder: encoder,
            request: createHttpRequest(endpoint, configuration.batchBytesLimit, reportError),
            flushController: createFlushController({
                messagesLimit: configuration.batchMessagesLimit,
                bytesLimit: configuration.batchBytesLimit,
                durationLimit: configuration.flushTimeout,
                pageExitObservable: pageExitObservable,
                sessionExpireObservable: sessionExpireObservable,
            }),
            messageBytesLimit: configuration.messageBytesLimit,
        });
    }
    return {
        flushObservable: primaryBatch.flushController.flushObservable,
        add: function (message, replicated) {
            if (replicated === void 0) { replicated = true; }
            primaryBatch.add(message);
            if (replicaBatch && replicated) {
                replicaBatch.add(replica.transformMessage ? replica.transformMessage(message) : message);
            }
        },
        upsert: function (message, key) {
            primaryBatch.upsert(message, key);
            if (replicaBatch) {
                replicaBatch.upsert(replica.transformMessage ? replica.transformMessage(message) : message, key);
            }
        },
        stop: function () {
            primaryBatch.stop();
            if (replicaBatch) {
                replicaBatch.stop();
            }
        },
    };
}
