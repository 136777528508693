import { Order } from "../company/modules/commercial/models/order.model";
import { Company } from "../models/company.model";
import { CurrentDateService } from "../services/current-date.service";

/**
 * Calculates the default expiration datetime based on company configuration and order details.
 *
 * @param {Company} company - The company object containing market configuration.
 * @param {Order} order - The order object containing business details.
 * @param {boolean} expirationForTBF - Flag indicating if expiration for 'to be fixed' price type should be considered.
 * @param {CurrentDateService} currentDateService - Service to get the current date.
 * @returns {Date | undefined} - Returns the calculated expiration date or undefined if not applicable.
 */
export function defaultOrderExpiration(company: Company, order: Order, expirationForTBF: boolean, currentDateService: CurrentDateService): Date | undefined {
  if (!company) return;

  const orderConfig = company.market.configuration.order;

  const isExpirationEnabled = orderConfig.expiration_date?.enabled;
  const defaultDays = orderConfig.expiration_date?.default_days;
  const isPriceTypeTBF = order.business_detail.price.type === 'to_be_fixed';

  if (
    // Expiration date enabled and default days set
    (isExpirationEnabled && defaultDays) ||
    // Price type to be fixed and default days set
    (isPriceTypeTBF && expirationForTBF && defaultDays)
  ) {
    // Default date set by market configuratiom
    let date = addDays(currentDateService.get(), defaultDays);

    if (order.business_detail.delivery.date_to) {
      // There is a delivery range
      let delivery_to = new Date(order.business_detail.delivery.date_to);

      // Check if delivery_to is less than date
      if (delivery_to.getTime() < date.getTime()) date = delivery_to;
    }

    return startOfDay(date);
  }
}

/**
 * Sets the time of the given date to the beginning of the day (00:00:00).
 *
 * @param {Date} expirationDate - The date to be modified.
 * @returns {Date | undefined} - Returns the modified date or undefined if the input date is not valid.
 */
export function startOfDay(date: Date): Date | undefined {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return undefined; // Return undefined if the input is not a valid date
  }

  const copy = new Date(date.getTime());
  copy.setHours(0, 0, 0, 0);

  return copy;
}

/**
 * Returns a `Date` object set to the end of the given day.
 * 
 * @param {Date} date - The date to adjust.
 * @returns {Date} - The adjusted date set to 23:59:59.999 of the same day.
 */
export function endOfDay(date: Date): Date | undefined {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return undefined; // Return undefined if the input is not a valid date
  }

  const copy = new Date(date.getTime());
  copy.setHours(23, 59, 59, 999);

  return copy;
}

/**
 * Adds a specified number of days to a date.
 * @param date The starting date.
 * @param days The number of days to add.
 * @returns A new Date object.
 */
export function addDays(date: Date, days: number): Date {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return undefined; // Return undefined if the input is not a valid date
  }

  const copy = new Date(date);
  copy.setDate(copy.getDate() + days);
  return copy;
}