var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { clocksNow, computeRawError, computeStackTrace, combine, createContextManager, ErrorSource, monitored, sanitize, createHandlingStack, isError, } from '@datadog/browser-core';
import { isAuthorized, StatusType } from './logger/isAuthorized';
import { createErrorFieldFromRawError } from './createErrorFieldFromRawError';
export var HandlerType = {
    console: 'console',
    http: 'http',
    silent: 'silent',
};
export var STATUSES = Object.keys(StatusType);
// note: it is safe to merge declarations as long as the methods are actually defined on the prototype
// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging, no-restricted-syntax
var Logger = /** @class */ (function () {
    function Logger(handleLogStrategy, customerDataTracker, name, handlerType, level, loggerContext) {
        if (handlerType === void 0) { handlerType = HandlerType.http; }
        if (level === void 0) { level = StatusType.debug; }
        if (loggerContext === void 0) { loggerContext = {}; }
        this.handleLogStrategy = handleLogStrategy;
        this.handlerType = handlerType;
        this.level = level;
        this.contextManager = createContextManager(customerDataTracker);
        this.contextManager.setContext(loggerContext);
        if (name) {
            this.contextManager.setContextProperty('logger', { name: name });
        }
    }
    Logger.prototype.logImplementation = function (message, messageContext, status, error, handlingStack) {
        if (status === void 0) { status = StatusType.info; }
        var sanitizedMessageContext = sanitize(messageContext);
        var context;
        if (error !== undefined && error !== null) {
            var rawError = computeRawError({
                stackTrace: isError(error) ? computeStackTrace(error) : undefined,
                originalError: error,
                nonErrorPrefix: "Provided" /* NonErrorPrefix.PROVIDED */,
                source: ErrorSource.LOGGER,
                handling: "handled" /* ErrorHandling.HANDLED */,
                startClocks: clocksNow(),
            });
            context = combine({
                error: createErrorFieldFromRawError(rawError, { includeMessage: true }),
            }, sanitizedMessageContext);
        }
        else {
            context = sanitizedMessageContext;
        }
        this.handleLogStrategy({
            message: sanitize(message),
            context: context,
            status: status,
        }, this, handlingStack);
    };
    Logger.prototype.log = function (message, messageContext, status, error) {
        if (status === void 0) { status = StatusType.info; }
        var handlingStack;
        if (isAuthorized(status, HandlerType.http, this)) {
            handlingStack = createHandlingStack();
        }
        this.logImplementation(message, messageContext, status, error, handlingStack);
    };
    Logger.prototype.setContext = function (context) {
        this.contextManager.setContext(context);
    };
    Logger.prototype.getContext = function () {
        return this.contextManager.getContext();
    };
    Logger.prototype.setContextProperty = function (key, value) {
        this.contextManager.setContextProperty(key, value);
    };
    Logger.prototype.removeContextProperty = function (key) {
        this.contextManager.removeContextProperty(key);
    };
    Logger.prototype.clearContext = function () {
        this.contextManager.clearContext();
    };
    Logger.prototype.setHandler = function (handler) {
        this.handlerType = handler;
    };
    Logger.prototype.getHandler = function () {
        return this.handlerType;
    };
    Logger.prototype.setLevel = function (level) {
        this.level = level;
    };
    Logger.prototype.getLevel = function () {
        return this.level;
    };
    __decorate([
        monitored
    ], Logger.prototype, "logImplementation", null);
    return Logger;
}());
export { Logger };
/* eslint-disable local-rules/disallow-side-effects */
Logger.prototype.ok = createLoggerMethod(StatusType.ok);
Logger.prototype.debug = createLoggerMethod(StatusType.debug);
Logger.prototype.info = createLoggerMethod(StatusType.info);
Logger.prototype.notice = createLoggerMethod(StatusType.notice);
Logger.prototype.warn = createLoggerMethod(StatusType.warn);
Logger.prototype.error = createLoggerMethod(StatusType.error);
Logger.prototype.critical = createLoggerMethod(StatusType.critical);
Logger.prototype.alert = createLoggerMethod(StatusType.alert);
Logger.prototype.emerg = createLoggerMethod(StatusType.emerg);
function createLoggerMethod(status) {
    return function (message, messageContext, error) {
        var handlingStack;
        if (isAuthorized(status, HandlerType.http, this)) {
            handlingStack = createHandlingStack();
        }
        this.logImplementation(message, messageContext, status, error, handlingStack);
    };
}
