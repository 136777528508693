import { toStackTraceString } from '../../tools/stackTrace/handlingStack';
import { monitor } from '../../tools/monitor';
import { mergeObservables, Observable } from '../../tools/observable';
import { addEventListener } from '../../browser/addEventListener';
import { assign, includes } from '../../tools/utils/polyfills';
import { safeTruncate } from '../../tools/utils/stringUtils';
import { ErrorSource } from '../error/error.types';
import { clocksNow } from '../../tools/utils/timeUtils';
export var RawReportType = {
    intervention: 'intervention',
    deprecation: 'deprecation',
    cspViolation: 'csp_violation',
};
export function initReportObservable(configuration, apis) {
    var observables = [];
    if (includes(apis, RawReportType.cspViolation)) {
        observables.push(createCspViolationReportObservable(configuration));
    }
    var reportTypes = apis.filter(function (api) { return api !== RawReportType.cspViolation; });
    if (reportTypes.length) {
        observables.push(createReportObservable(reportTypes));
    }
    return mergeObservables.apply(void 0, observables);
}
function createReportObservable(reportTypes) {
    return new Observable(function (observable) {
        if (!window.ReportingObserver) {
            return;
        }
        var handleReports = monitor(function (reports, _) {
            return reports.forEach(function (report) { return observable.notify(buildRawReportErrorFromReport(report)); });
        });
        var observer = new window.ReportingObserver(handleReports, {
            types: reportTypes,
            buffered: true,
        });
        observer.observe();
        return function () {
            observer.disconnect();
        };
    });
}
function createCspViolationReportObservable(configuration) {
    return new Observable(function (observable) {
        var stop = addEventListener(configuration, document, "securitypolicyviolation" /* DOM_EVENT.SECURITY_POLICY_VIOLATION */, function (event) {
            observable.notify(buildRawReportErrorFromCspViolation(event));
        }).stop;
        return stop;
    });
}
function buildRawReportErrorFromReport(report) {
    var type = report.type, body = report.body;
    return buildRawReportError({
        type: body.id,
        message: "".concat(type, ": ").concat(body.message),
        originalError: report,
        stack: buildStack(body.id, body.message, body.sourceFile, body.lineNumber, body.columnNumber),
    });
}
function buildRawReportErrorFromCspViolation(event) {
    var message = "'".concat(event.blockedURI, "' blocked by '").concat(event.effectiveDirective, "' directive");
    return buildRawReportError({
        type: event.effectiveDirective,
        message: "".concat(RawReportType.cspViolation, ": ").concat(message),
        originalError: event,
        csp: {
            disposition: event.disposition,
        },
        stack: buildStack(event.effectiveDirective, event.originalPolicy
            ? "".concat(message, " of the policy \"").concat(safeTruncate(event.originalPolicy, 100, '...'), "\"")
            : 'no policy', event.sourceFile, event.lineNumber, event.columnNumber),
    });
}
function buildRawReportError(partial) {
    return assign({
        startClocks: clocksNow(),
        source: ErrorSource.REPORT,
        handling: "unhandled" /* ErrorHandling.UNHANDLED */,
    }, partial);
}
function buildStack(name, message, sourceFile, lineNumber, columnNumber) {
    return sourceFile
        ? toStackTraceString({
            name: name,
            message: message,
            stack: [
                {
                    func: '?',
                    url: sourceFile,
                    line: lineNumber !== null && lineNumber !== void 0 ? lineNumber : undefined,
                    column: columnNumber !== null && columnNumber !== void 0 ? columnNumber : undefined,
                },
            ],
        })
        : undefined;
}
