import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { NgForm } from '@angular/forms';
import { User } from '../../../../../auth/models/user.model';
import { LoginService } from '../../../../../auth/services/login.service';
import { Company } from '../../../../../models/company.model';
import { DataDogLoggerService } from '../../../../../services/data-dog-logger.service';
import { addDays } from '../../../../../utilities/date';
import { CsvImporterService, Entities } from '../../../../services/csv-importer.service';

@Component({
  selector: 'ag-import-contracts',
  templateUrl: './import-contracts.component.html',
  styleUrls: ['./import-contracts.component.scss']
})
export class ImportContractsComponent implements OnInit, OnDestroy {

  @ViewChild('importModal', { static: true }) private readonly importModal: TemplateRef<any>;

  @Input() public company: Company;
  @Input() public entity: Entities = 'contracts';

  @Output('submitted') readonly submitted = new EventEmitter();

  public accept: string = ".xls, .xlsx, .csv";
  public dueDate: Date;
  public maxSize: number = 1024 * 1024 * 20;
  public errorMessage: string;
  /**
   * Flag used to enable/disable UI buttons and links when an API request is in
   * progress.
   */
  public processing: boolean;

  private modalRef: BsModalRef;
  private modalSub: Subscription;
  private user: User;

  constructor(
    private modalService: BsModalService,
    private csvImporter: CsvImporterService,
    private loginService: LoginService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  ngOnInit(): void {
    this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;
    })
  }

  public submit(e: NgForm): void {
    const sources = e.value.source;

    this.processing = true;
    this.errorMessage = undefined;

    this.csvImporter.fromFileManager(this.company.id, this.user.id, this.entity, sources).subscribe({
      next: response => {
        this.processing = false;
        this.closeModal();
        this.submitted.emit();
      },
      error: error => {
        this.processing = false;
        this.errorMessage = error.error.message;
        this.dataDogLoggerService.warn(error.message, error.error);
      }
    });
  }

  public open(): void {
    this.errorMessage = undefined;

    this.dueDate = addDays(new Date(), 7);

    this.openModal(this.importModal, '');
  }

  /** Generic Modal trigger. */
  private openModal(template: TemplateRef<any>, c: string = ''): void {
    this.modalRef = this.modalService.show(template, {
      ignoreBackdropClick: true,
      class: c
    });

    this.modalSub = this.modalRef.onHide.subscribe((reason: string) => {
      this.modalSub.unsubscribe();
      this.modalRef = undefined;
      // Reset all values
      this.processing = false;
    });
  }

  /** Closes the most recent opened modal. */
  public closeModal(onHide: Function = null): void {
    if (this.modalRef) {
      this.modalRef.hide();
      if (onHide) this.modalRef.onHide.subscribe(onHide);
    } else {
      if (onHide) onHide();
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    this.closeModal();
  }
}
