import { ErrorSource, combine, createEventRateLimiter, getRelativeTime, isEmptyObject } from '@datadog/browser-core';
import { STATUSES } from './logger';
import { getRUMInternalContext } from './contexts/rumInternalContext';
export function startLogsAssembly(sessionManager, configuration, lifeCycle, getCommonContext, reportError) {
    var statusWithCustom = STATUSES.concat(['custom']);
    var logRateLimiters = {};
    statusWithCustom.forEach(function (status) {
        logRateLimiters[status] = createEventRateLimiter(status, configuration.eventRateLimiterThreshold, reportError);
    });
    lifeCycle.subscribe(0 /* LifeCycleEventType.RAW_LOG_COLLECTED */, function (_a) {
        var _b, _c;
        var rawLogsEvent = _a.rawLogsEvent, _d = _a.messageContext, messageContext = _d === void 0 ? undefined : _d, _e = _a.savedCommonContext, savedCommonContext = _e === void 0 ? undefined : _e, domainContext = _a.domainContext;
        var startTime = getRelativeTime(rawLogsEvent.date);
        var session = sessionManager.findTrackedSession(startTime);
        if (!session &&
            (!configuration.sendLogsAfterSessionExpiration ||
                !sessionManager.findTrackedSession(startTime, { returnInactive: true }))) {
            return;
        }
        var commonContext = savedCommonContext || getCommonContext();
        var log = combine({
            service: configuration.service,
            session_id: session ? session.id : undefined,
            session: session ? { id: session.id } : undefined,
            // Insert user first to allow overrides from global context
            usr: !isEmptyObject(commonContext.user) ? commonContext.user : undefined,
            view: commonContext.view,
        }, commonContext.context, getRUMInternalContext(startTime), rawLogsEvent, messageContext);
        if (((_b = configuration.beforeSend) === null || _b === void 0 ? void 0 : _b.call(configuration, log, domainContext)) === false ||
            (log.origin !== ErrorSource.AGENT &&
                ((_c = logRateLimiters[log.status]) !== null && _c !== void 0 ? _c : logRateLimiters['custom']).isLimitReached())) {
            return;
        }
        lifeCycle.notify(1 /* LifeCycleEventType.LOG_COLLECTED */, log);
    });
}
