import Theme from "./models/theme.model";

/**
 * Languages imports
 * Name convention: messages_[key]_[lang]
 */

/**
 * Macro
 */
// import messages_macro_es from '../i18n/theme/macro/es'
// import messages_macro_pt from '../i18n/theme/macro/pt'
// import messages_macro_en from '../i18n/theme/macro/en'
// import messages_macro_esmx from '../i18n/theme/macro/es-MX'

/**
 * Santander
 */
import messages_santandermx_en from '../i18n/theme/santandermx/en';
import messages_santandermx_es from '../i18n/theme/santandermx/es';
import messages_santandermx_esmx from '../i18n/theme/santandermx/es-MX';
import messages_santandermx_pt from '../i18n/theme/santandermx/pt';

const themes: { [themeName: string]: Theme } = {
  agree: {
    name: 'Agree',
    title: 'Agree',
    cssClass: 'agree-theme',
    hostnames: {
      dev: 'agree.dev.agree.ag',
    },
    favicons: [
      { sizes: '16x16', href: '/assets/img/icons/favicon-16x16.png', type: 'image/png' },
      { sizes: '32x32', href: '/assets/img/icons/favicon-32x32.png', type: 'image/png' },
      { sizes: '96x96', href: '/assets/img/icons/favicon-96x96.png', type: 'image/png' },
      { sizes: '192x192', href: '/assets/img/icons/favicon-192x192.png', type: 'image/png' },
      { rel: 'shortcut icon', href: '/assets/img/icons/favicon.ico' }
    ]
  },
  woccu: {
    name: 'Woccu',
    title: 'Woccu',
    cssClass: 'woccu-theme',
    hostnames: {
      dev: 'woccu.dev.agree.ag',
    },
    favicons: [
      { sizes: '16x16', href: '/assets/img/icons/favicon-16x16.png', type: 'image/png' },
      { sizes: '32x32', href: '/assets/img/icons/favicon-32x32.png', type: 'image/png' },
      { sizes: '96x96', href: '/assets/img/icons/favicon-96x96.png', type: 'image/png' },
      { sizes: '192x192', href: '/assets/img/icons/favicon-192x192.png', type: 'image/png' },
      { rel: 'shortcut icon', href: '/assets/img/icons/favicon.ico' }
    ]
  },
  cloudfront: {
    name: 'cloudfront',
    title: 'Tenant de prueba',
    cssClass: 'woccu-theme',
    hostnames: {
      demo: 'd1zkuq92po4fbc.cloudfront.net',
    },
    favicons: [
      { sizes: '16x16', href: '/assets/img/icons/favicon-16x16.png', type: 'image/png' },
      { sizes: '32x32', href: '/assets/img/icons/favicon-32x32.png', type: 'image/png' },
      { sizes: '96x96', href: '/assets/img/icons/favicon-96x96.png', type: 'image/png' },
      { sizes: '192x192', href: '/assets/img/icons/favicon-192x192.png', type: 'image/png' },
      { rel: 'shortcut icon', href: '/assets/img/icons/favicon.ico' }
    ]
  },
  // sancor: {
  //     name: 'Sancor',
  //     title: 'Sancor',
  //     cssClass: 'sancor-theme',
  //     hostnames: {
  //         dev: 'sancor.dev.agree.ag',
  //     },
  //     favicons: [
  //         { sizes: '16x16', href: '/assets/img/icons/favicon-16x16.png', type: 'image/png' },
  //         { sizes: '32x32', href: '/assets/img/icons/favicon-32x32.png', type: 'image/png' },
  //         { sizes: '96x96', href: '/assets/img/icons/favicon-96x96.png', type: 'image/png' },
  //         { sizes: '192x192', href: '/assets/img/icons/favicon-192x192.png', type: 'image/png' },
  //         { rel: 'shortcut icon', href: '/assets/img/icons/favicon.ico' }
  //     ]
  // },
  // colombian: {
  //     name: 'Colombian',
  //     title: 'Colombian',
  //     cssClass: 'colombian-theme',
  //     hostnames: {
  //         dev: 'colombian.dev.agree.ag',
  //     },
  //     favicons: [
  //         { sizes: '16x16', href: '/assets/img/icons/favicon-16x16.png', type: 'image/png' },
  //         { sizes: '32x32', href: '/assets/img/icons/favicon-32x32.png', type: 'image/png' },
  //         { sizes: '96x96', href: '/assets/img/icons/favicon-96x96.png', type: 'image/png' },
  //         { sizes: '192x192', href: '/assets/img/icons/favicon-192x192.png', type: 'image/png' },
  //         { rel: 'shortcut icon', href: '/assets/img/icons/favicon.ico' }
  //     ]
  // },
  santander: {
    name: 'Santander MX',
    title: 'Santander',
    cssClass: 'santander-mx-theme',
    hostnames: {
      dev: 'santander.dev.agree.ag',
      demo: 'santander.demo.agree.ag',
      prod: 'santander.agree.ag'
    },
    languages: {
      key: 'santandermx',
      messages: {
        es: messages_santandermx_es,
        en: messages_santandermx_en,
        pt: messages_santandermx_pt,
        'es-MX': messages_santandermx_esmx
      }
    },
    favicons: [
      { sizes: '16x16', href: '/assets/img/theme/santander-mx/icons/favicon-16x16.png', type: 'image/png' },
      { sizes: '32x32', href: '/assets/img/theme/santander-mx/icons/favicon-32x32.png', type: 'image/png' },
      { sizes: '120x120', href: '/assets/img/theme/santander-mx/icons/apple-touch-icon-120x120.png', type: 'image/png' },
      { sizes: '152x152', href: '/assets/img/theme/santander-mx/icons/apple-touch-icon-152x152.png', type: 'image/png' },
      { rel: 'shortcut', href: '/assets/img/theme/santander-mx/icons/favicon-32x32.png', type: 'image/png' }
    ]
  },
  // macro: {
  //     name: 'Banco Macro AR',
  //     title: 'Banco Macro',
  //     cssClass: 'macro-ar-theme',
  //     hostnames: {
  //         dev: 'macro.dev.agree.ag',
  //         demo: 'macro.demo.agree.ag',
  //         prod: 'macro.agree.ag'
  //     },
  //     languages: {
  //         key: 'macro',
  //         messages: {
  //             es: messages_macro_es,
  //             en: messages_macro_en,
  //             pt: messages_macro_pt,
  //             'es-MX': messages_macro_esmx
  //         }
  //     },
  //     favicons: [
  //         { sizes: '16x16', href: '/assets/img/theme/macro-ar/icons/favicon-16x16.png', type: 'image/png' },
  //         { sizes: '32x32', href: '/assets/img/theme/macro-ar/icons/favicon-32x32.png', type: 'image/png' },
  //         { sizes: '120x120', href: '/assets/img/theme/macro-ar/icons/apple-touch-icon-120x120.png', type: 'image/png' },
  //         { sizes: '152x152', href: '/assets/img/theme/macro-ar/icons/apple-touch-icon-152x152.png', type: 'image/png' },
  //         { rel: 'shortcut', href: '/assets/img/theme/macro-ar/icons/favicon-32x32.png', type: 'image/png' }
  //     ]
  // }
  Agrology: {
    name: 'Agrology',
    title: 'Agrology',
    cssClass: 'agrology-theme',
    hostnames: {
      dev: 'dev-market.agrology.com',
      demo: 'demo-market.agrology.com',
      prod: 'market.agrology.com'
    },
    // languages: {
    //   key: 'santandermx',
    //   messages: {
    //     es: messages_santandermx_es,
    //     en: messages_santandermx_en,
    //     pt: messages_santandermx_pt,
    //     'es-MX': messages_santandermx_esmx
    //   }
    // },
    favicons: [
      // { sizes: '16x16', href: '/assets/img/theme/santander-mx/icons/favicon-16x16.png', type: 'image/png' },
      // { sizes: '32x32', href: '/assets/img/theme/santander-mx/icons/favicon-32x32.png', type: 'image/png' },
      // { sizes: '120x120', href: '/assets/img/theme/santander-mx/icons/apple-touch-icon-120x120.png', type: 'image/png' },
      // { sizes: '152x152', href: '/assets/img/theme/santander-mx/icons/apple-touch-icon-152x152.png', type: 'image/png' },
      { rel: 'icon', href: '/assets/img/theme/agrology/icons/favicon.ico', type: 'image/x-icon' }
    ]
  },
};

export default themes;