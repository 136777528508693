<div class="horizontal-scroller"
*ngIf="orders?.length > 0">
  <div class="table-sticky-headers">
    <table class="table table-condensed" sortable defaultSortBy="companyName" #tableSortable="tableSortable"
      AgMultiselection [(ngModel)]="selectedOrders">
      <thead>
        <tr class="tr-align-center">
          <th class="sap"
            *ngIf="enableSelection">
            <input type="checkbox" AgSelectall
            id="select-all-toggle"
            class="show-onhover no-margin"
            tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}"
            container="body" [adaptivePosition]="false">
          </th>
          <th></th>
          <th class="sap hidden-xs hidden-sm"></th>
          <th sortBy="companyName">{{ 'GLOBAL.COMPANY'|translate }}</th>
          <th class="hidden-xs" sortBy="quality_string">{{ 'GLOBAL.QUALITY'|translate }}</th>
          <th class="hidden-xs" sortBy="locationString"
            *ngIf="market.configuration.location.enabled">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
          <th class="hidden-xs" sortBy="locationString"
            *ngIf="market.configuration.geolocation.enabled">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
          <th class="hidden-xs" sortBy="portString"
            *ngIf="market.configuration.order.business_detail.port.enabled">{{ 'GLOBAL.COUNTRY_PORT'|translate }}</th>
          <th class="sap hidden-xs" sortBy="deliveryType"
            *ngIf="market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">{{ 'GLOBAL.DELIVERY_TYPE_ABBR'|translate }}</th>
          <th class="sap hidden-xs hidden-sm" sortBy="hasObservations">
            <abbr title tooltip="{{ 'GLOBAL.OBSERVATIONS'|translate }}"
            container="body"
            [adaptivePosition]="false">{{ 'GLOBAL.OBSERVATIONS_ABBR'|translate }}</abbr>
          </th>
          <th class="dlb drb" sortBy="business_detail.quantity.valueString"
            *ngIf="!market.configuration.order.has_minimum_order">
            <abbr title tooltip="{{ 'GLOBAL.QUANTITY'|translate }}"
            container="body"
            [adaptivePosition]="false">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
          </th>
          <th class="sap text-center" sortBy="business_detail.delivery.intDateFrom">{{ 'GLOBAL.PERIOD'|translate }}</th>
          <th class="sap" sortBy="business_detail.price.value">{{ 'GLOBAL.PRICE'|translate }}</th>
          <th class="sap hidden-xs"
            *ngIf="!hideColumns.includes('cad')"></th>
        </tr>
      </thead>
      <tbody *ngFor="let group of orders; last as isLastGroup">
        <!-- Product sub-header -->
        <tr class="tr-align-center product-header text-uppercase">
          <!-- Always visible columns -->
          <th colspan="4">{{ group.key.name }}</th>
          <!-- Optional columns -->
          <th
            *ngIf="enableSelection"></th>
          <th class="hidden-xs hidden-sm"></th>
          <th class="hidden-xs"
            *ngIf="market.configuration.location.enabled"></th>
          <th class="hidden-xs"
            *ngIf="market.configuration.geolocation.enabled"></th>
          <th class="hidden-xs"
            *ngIf="market.configuration.order.business_detail.port.enabled"></th>
          <th class="hidden-xs"></th>
          <th class="hidden-xs"
            *ngIf="market.configuration.order.business_detail.delivery.delivery_type.show_in_lists"></th>
          <th
            *ngIf="!market.configuration.order.has_minimum_order"></th>
          <th class="hidden-xs hidden-sm"></th>
          <th class="hidden-xs"
            *ngIf="!hideColumns.includes('cad')"></th>
        </tr>
        <!-- Order row -->
        <tr class="tr-align-center"
          *ngFor="let order of group.values | orderBy: tableSortable.sortBy; last as isLastOrder"
          [popover]="order.props?.length > 0 ? popoverTemplate : null"
          [popoverContext]="{order: order}"
          containerClass="popFadeDelay"
          placement="bottom"
          triggers="mouseenter:mouseleave"
          container=".app-content"
          containerClass="popFadeDelay width-800">
          <td class="sap"
            *ngIf="enableSelection">
            <input
            *ngIf="order.operation_type === 'venta' &&
            order.company?.id &&
            company?.id !== order.company?.id"
            [AgCheckbox]="order" type="checkbox"
            class="show-onhover no-margin">
          </td>
          <td headers="ico">
            <scope-icon [entity]="order"></scope-icon>
            <i class="hidden-md hidden-lg material-symbols-rounded" *ngIf="order.general_observations">comment</i>
          </td>
          <td class="sap hidden-xs hidden-sm">
            <company-logo [company]="order.company"></company-logo>
          </td>
          <td>
            <i class="material-symbols-rounded small pull-right" *ngIf="order.auction">gavel</i>
            <!-- If no disclosure replace a[href] with this span -->
            <span *ngIf="!order.id" class="not-available truncate" title="{{ 'WORKING_ORDERS.UNAUTHORIZED'|translate }}">***</span>
            <ng-container *ngIf="order.id">
              <ng-container *ngTemplateOutlet="company ? orderLink : orderText; context: {order: order}"></ng-container>
            </ng-container>
  
            <div class="visible-xs small"><b>{{ order.quality_string }}</b></div>
            <div class="visible-xs location-small">
              <span *ngIf="market.configuration.location.enabled" class="clamp-2">{{ order.business_detail.delivery.locations |locationString }}</span>
              <span *ngIf="market.configuration.geolocation.enabled" class="clamp-2">{{ order.business_detail.delivery.geolocations |locationString:true:'name' }}</span>
              <span *ngIf="market.configuration.order.business_detail.port.enabled" class="clamp-2">{{ countryAndPort(order) }}</span>
              <span *ngIf="market.configuration.order.business_detail.delivery.delivery_type.show_in_lists" class="clamp-2">
                ({{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }})
              </span>
            </div>
          </td>
          <td class="hidden-xs small">{{ order.quality_string }}</td>
          <td class="hidden-xs small"
            *ngIf="market.configuration.location.enabled">
            <span class="clamp-2">{{ order.business_detail.delivery.locations |locationString }}</span>
          </td>
          <td class="hidden-xs small"
            *ngIf="market.configuration.geolocation.enabled">
            <span class="clamp-2">{{ order.business_detail.delivery.geolocations |locationString:true:'name' }}</span>
          </td>
          <td class="hidden-xs small"
            *ngIf="market.configuration.order.business_detail.port.enabled">
            {{ order.product.attributes.country?.name }} / {{ order.business_detail.port?.name }}
          </td>
          <td class="sap hidden-xs"
            *ngIf="market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
            {{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }}
          </td>
          <td class="sap hidden-xs hidden-sm">
            <i class="material-symbols-rounded" *ngIf="order.hasObservations">comment</i>
          </td>
          <td class="sap text-right"
            *ngIf="!market.configuration.order.has_minimum_order">
            <quantity-control [quantity]="order.business_detail.quantity"></quantity-control>
          </td>
          <td class="sap">
            <date-range
            [showSpot]="true"
            [from]="order.business_detail.delivery.date_from"
            [to]="order.business_detail.delivery.date_to"></date-range>
          </td>
          <td class="sap">
            <div class="price-box"
            [class.sell-bg]="order.operation_type === 'venta'"
            [class.buy-bg]="order.operation_type === 'compra'">
              <div class="type">{{ (order.operation_type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }}</div>
              <div class="value">
                <price-display [price]="order.business_detail.price" [withoutYear]="true"></price-display>
              </div>
            </div>
          </td>
          <td class="sap hidden-xs"
            *ngIf="!hideColumns.includes('cad')"><i tooltip="{{ order.payment_detail.payment_condition.name }}" *ngIf="order.payment_detail.payment_condition.id === 1" class="image-icons cash-payment"></i></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #orderText let-order="order">
  <span class="truncate" title="{{ order.companyName }}">
    <samp class="small hidden-xs hidden-sm">#{{ order.id }}:</samp>
    <b [class.highlighted-text]="company?.id === order.company.id">{{ order.companyName }}</b>
  </span>
</ng-template>

<ng-template #orderLink let-order="order">
  <a class="order-link" routerLink="../order/{{ order.id }}">
    <ng-container *ngTemplateOutlet="orderText; context: {order: order}"></ng-container>
  </a>
</ng-template>

<ng-template #popoverTemplate let-order="order">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4" *ngFor="let prop of order.props">
        <table-detail [header]="prop.name">{{ prop.value }}</table-detail>
      </div>
    </div>
  </div>
</ng-template>