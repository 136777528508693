<ng-container *ngIf="from && to">
  <div *ngIf="!simple; else simpler"
  class="period {{ rangeClass }} {{ spotClass }}">
    <div class="from" title="{{ from|date: 'EEEE':undefined:currentLang }}">
      <div class="day">
        {{ from|date: ('GLOBAL.DATE_FORMAT.XS'|translate):undefined:currentLang }}
      </div>
      <div class="year">
        <span class="hidden-xs">
          {{ from|date: 'yyyy' }}
        </span>
        <span class="hidden-sm hidden-md hidden-lg">
          {{ from|date: 'yy' }}
        </span>
      </div>
    </div>
    <i class="material-symbols-rounded">play_arrow</i>
    <div class="to" title="{{ to|date: 'EEEE':undefined:currentLang }}">
      <div class="day">
        {{ to|date: ('GLOBAL.DATE_FORMAT.XS'|translate):undefined:currentLang }}
      </div>
      <div class="year">
        <span class="hidden-xs">
          {{ to|date: 'yyyy' }}
        </span>
        <span class="hidden-sm hidden-md hidden-lg">
          {{ to|date: 'yy' }}
        </span>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #simpler>
  <div class="simpler {{ rangeClass }} {{ spotClass }}">
    <span class="from" title="{{ from|date: 'EEEE':undefined:currentLang }}">
      {{ from|date: ('GLOBAL.DATE_FORMAT.SM'|translate):undefined:currentLang }}</span>
    &nbsp;<span class="text-muted">&#9659;</span>&nbsp;
    <span class="to" title="{{ to|date: 'EEEE':undefined:currentLang }}">
      {{ to|date: ('GLOBAL.DATE_FORMAT.SM'|translate):undefined:currentLang }}</span>
  </div>
</ng-template>