import { removeItem } from './utils/arrayUtils';
var BUFFER_LIMIT = 500;
export function createBoundedBuffer() {
    var buffer = [];
    var add = function (callback) {
        var length = buffer.push(callback);
        if (length > BUFFER_LIMIT) {
            buffer.splice(0, 1);
        }
    };
    var remove = function (callback) {
        removeItem(buffer, callback);
    };
    var drain = function (arg) {
        buffer.forEach(function (callback) { return callback(arg); });
        buffer.length = 0;
    };
    return {
        add: add,
        remove: remove,
        drain: drain,
    };
}
