import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription, combineLatest } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Account } from '../../auth/models/account.model';
import { User } from '../../auth/models/user.model';
import { LoginService } from '../../auth/services/login.service';
import { Company, companyCan } from '../../models/company.model';
import { Notification } from '../../models/notification.model';
import { CompanyService } from '../../services/company.service';
import { DataDogLoggerService } from '../../services/data-dog-logger.service';
import { NotificationService } from '../../services/notification.service';
import { ThemeService } from '../../theme/services/theme.service';
import { CellphoneVerificationComponent } from '../../ui/components/cellphone-verification/cellphone-verification.component';
import { NetworkInviteModalComponent } from '../../ui/components/network-invite-modal/network-invite-modal.component';
import { FlashService } from '../../ui/services/flash.service';
import { MessageModalComponent } from '../message-modal/message-modal.component';

// declare const walkthrough: any;

/**
 * Application header. Includes [[User]], [[Company]] and
 * [[Notification|Notifications]] menu.
 *
 * It is also a container for global alerts.
 */
@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild('messageModal', { static: true }) private readonly messageModal: MessageModalComponent;
  @ViewChild('cellVerification', { static: true }) private readonly cellVerification: CellphoneVerificationComponent;
  @ViewChild('inviteModal', { static: true }) private readonly inviteModal: NetworkInviteModalComponent;

  public companyMenu: ddMenuSection[];
  public company: Company;
  /** Main button actions. */
  public createButtonStack: {
    type: string;
    link: string;
    title: string;
    iconHtml: string;
    route: string;
  }[] = [];
  public environment = environment;
  /** Pending Add to My Network Requests. */
  public pendingRequests: number;
  public userMenu: ddMenuSection[];
  /** Current [[User]]. */
  public user: User;
  /** Whether the header should be displayed or not. */
  public showHeader: boolean;

  private account: Account;
  private isFullyActivated: boolean;
  private subscriptions: Subscription[] = [];
  private notificationSubscription: Subscription;
  /** Hides the header in these urls */
  private hideHeaderIn: string[] = [
    '/sign-up',
    '/login'
  ];

  /** @ignore */
  constructor(
    public themeService: ThemeService,
    private companyService: CompanyService,
    private flashService: FlashService,
    private loginService: LoginService,
    private messageService: NotificationService,
    private router: Router,
    private translateService: TranslateService,
    private toastrService: ToastrService,
    private dataDogLoggerService: DataDogLoggerService
  ) { }

  /** @ignore */
  ngOnInit(): void {
    this.subscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationStart)
        this.showHeader = !this.hideHeaderIn.includes(event.url);
    }));

    this.subscriptions.push(this.loginService.getCurrentUser().subscribe(user => {
      this.user = user;

      // User
      if (this.notificationSubscription) this.notificationSubscription.unsubscribe();

      if (this.user) this.notificationSubscription = this.messageService.watchNew(this.user.id).subscribe(message => {
        this.showToastMessage(message);
      });

      this.buildUserMenu();
    }));

    this.subscriptions.push(combineLatest([
      this.companyService.watchAccount(),
      this.companyService.watch()
    ]).pipe(
      debounceTime(100)
    ).subscribe(response => {
      // Destructures the values ​​received from the observables, for readability
      const [
        account,
        company,
      ] = response;

      this.company = company;
      this.account = account;

      // Company
      this.isFullyActivated = this.company?.activation_level.id === 2;

      // walkthrough('general', false, this.translateService);

      if (this.company && !this.isFullyActivated) {
        this.flashService.report('activation_basic');
      } else {
        this.flashService.dismiss('activation_basic');
      }

      this.buildButtonStack();
      this.buildCompanyMenu();
    }));
  }

  /** Generates an empty array of the specified length. */
  public arrayOne(length: number): any[] {
    return Array(length);
  }

  private showToastMessage(notification: Notification): void {
    const toast = this.toastrService.show(notification.subject, undefined, {
      closeButton: true
    });

    this.subscriptions.push(toast.onTap.pipe(take(1)).subscribe(() => {
      this.messageService.setRead(notification).subscribe({
        error: error => {
          // Non fatal error
          this.dataDogLoggerService.warn(error.message, error.error);
        }
      });

      if (notification.link) {
        const link = notification.link.split("?");
        if (link[1]) {
          const queryParams = {};
          const params = link[1].split("&");
          params.forEach(param => {
            const keyValue = param.split("=");
            queryParams[keyValue[0]] = keyValue[1];
          });

          setTimeout(() => {
            this.router.navigate([link[0]], { queryParams: queryParams });
          });
        } else {
          this.router.navigateByUrl(notification.link);
        }
      } else {
        this.messageModal.show(notification);
      }
    }));
  }

  /** Starts the general welcome walkthrough. */
  // private walkthrough(): void {
  //   walkthrough('general', true, this.translateService);
  // }

  private logout(): void {
    this.companyService.unset();
    this.loginService.logout();
  }

  /** Changes the [[User|User's]] language. */
  private updateLanguage(slug: string): void {
    this.user.language = slug;
    this.translateService.use(this.user.language);

    this.subscriptions.push(this.loginService.updateUser(this.user).subscribe(user => {
      this.loginService.changeLocalStorageUserLanguage(slug);
    }));

    this.buildUserMenu();
  }

  private buildCompanyMenu(): void {
    this.companyMenu = [];
    this.pendingRequests = 0;

    if (!this.company || !this.account) return;

    const aIs = this.account.is;
    const { modules } = environment;

    this.pendingRequests = (
      this.company.pending_requests &&
      (aIs.commercial_with_users || aIs.counterpart_admin)
    ) ? this.company.pending_requests : 0;

    if (this.isFullyActivated) {
      // General section
      const gSection = new ddMenuSection();

      if (aIs.commercial) {
        if (this.company.hasModule('commercial')) {
          if (modules.operations_alerts) {
            gSection.items.push(new ddMenuItem({
              routerLink: ['/company', this.company.id, 'operation-alerts'],
              materialIcon: 'notifications_none',
              title: 'GLOBAL.OPERATION_ALERTS'
            }));
          }

          if (this.company.activity.broker || this.company.activity.buyer || this.company.activity.seller) {
            gSection.items.push(new ddMenuItem({
              routerLink: ['/company', this.company.id, 'templates'],
              materialIcon: 'description',
              title: 'GLOBAL.TEMPLATES'
            }));
          }
        }

        if (modules.chat &&
          (aIs.commercial || aIs.operations)) {
          gSection.items.push(new ddMenuItem({
            routerLink: ['/company', this.company.id, 'chat'],
            materialIcon: 'chat',
            title: 'GLOBAL.MESSENGER_CENTER'
          }));
        }
      }

      gSection.items.push(new ddMenuItem({
        routerLink: ['/company', this.company.id, 'approvals'],
        queryParams: { status: 'PENDING', order_by: '-created_at' },
        materialIcon: 'thumb_up',
        title: 'DOA.TITLE'
      }));

      if (gSection.items.length) this.companyMenu.push(gSection);

      // Setup section
      const sSection = new ddMenuSection();

      if (aIs.commercial_with_users) {
        sSection.items.push(new ddMenuItem({
          routerLink: ['/company', this.company.id, 'setup'],
          title: 'GLOBAL.CONFIG_ASSISTANT'
        }));
      }
      if (aIs.commercial_with_users) {
        sSection.items.push(new ddMenuItem({
          routerLink: ['/company', this.company.id, 'team'],
          materialIcon: 'people_outline',
          title: 'GLOBAL.TEAM'
        }));
      }
      if (aIs.commercial_with_users &&
        this.company.hasModule('commercial-manager')) {
        sSection.items.push(new ddMenuItem({
          routerLink: ['/company', this.company.id, 'commercial-manager'],
          materialIcon: 'business_center',
          title: 'GLOBAL.COMMERCIAL_MANAGER'
        }));
      }
      if (this.company.activity.buyer &&
        this.company.market.configuration.location.enabled &&
        aIs.commercial) {
        sSection.items.push(new ddMenuItem({
          routerLink: ['/company', this.company.id, 'locations'],
          materialIcon: 'location_on',
          title: 'GLOBAL.DELIVERY_PLACES'
        }));
      }
      if (aIs.commercial_with_users || aIs.counterpart_admin) {
        sSection.items.push(new ddMenuItem({
          routerLink: ['/company', this.company.id, 'notes'],
          queryParams: { order_by: '-fiscalId' },
          materialIcon: 'apartment',
          title: 'GLOBAL.NOTES'
        }));
      }
      if (aIs.commercial_with_users || aIs.counterpart_admin) {
        sSection.items.push(new ddMenuItem({
          routerLink: ['/company', this.company.id, 'network'],
          queryParams: { order_by: '-verification_date' },
          materialIcon: 'work_outline',
          title: 'GLOBAL.SELLER_ADMIN'
        }));
      }
      if (aIs.admin &&
        this.company.market.configuration.certificates.enabled) {
        sSection.items.push(new ddMenuItem({
          routerLink: ['/company', this.company.id, 'certificates'],
          glyphicon: 'glyphicon-certificate',
          title: 'GLOBAL.CERTIFICATES'
        }));
      }
      if (aIs.admin &&
        this.company.hasModule('importer')) {
        sSection.items.push(new ddMenuItem({
          routerLink: ['/company', this.company.id, 'integrations'],
          materialIcon: 'api',
          title: 'GLOBAL.INTEGRATIONS'
        }));
      }
      if (sSection.items.length) this.companyMenu.push(sSection);
    }

    // Admin section
    const aSection = new ddMenuSection();

    if (aIs.admin) {
      aSection.items.push(new ddMenuItem({
        routerLink: ['/company', this.company.id, 'edit'],
        materialIcon: 'settings',
        title: 'GLOBAL.EDIT_COMPANY'
      }));
    }

    if (aSection.items.length) this.companyMenu.push(aSection);
  }

  /**
   * Builds the user menu with various sections based on user properties.
   * 
   * @private
   */
  private buildUserMenu(): void {
    this.userMenu = [];

    if (!this.user) return;

    // General section
    const gSection = new ddMenuSection();
    gSection.items.push(new ddMenuItem({
      routerLink: ['/user'],
      materialIcon: 'person',
      title: 'GLOBAL.USER_PROFILE'
    }));
    gSection.items.push(new ddMenuItem({
      routerLink: ['/approvals'],
      queryParams: { status: 'PENDING', order_by: '-created_at' },
      materialIcon: 'thumb_up',
      title: 'DOA.TITLE'
    }));
    gSection.items.push(new ddMenuItem({
      routerLink: ['/companies'],
      materialIcon: 'business',
      title: 'GLOBAL.MY_COMPANIES'
    }));

    if (!this.user.phone || !this.user.phone.phone_number) {
      gSection.items.push(new ddMenuItem({
        click: () => {
          this.cellVerification.addPhone();
        },
        iconClass: 'animated infinite tada3rd',
        materialIcon: 'smartphone',
        title: 'CELLPHONE_VERIFICATION.MODAL.TITLE'
      }));
    }

    if (gSection.items.length) this.userMenu.push(gSection);

    // Language section
    const lSection = new ddMenuSection();
    lSection.title = 'HEADER.SELECT_LANGUAGE';

    environment.languages.forEach(lang => {
      if (this.user.language !== lang.slug) {
        lSection.items.push(new ddMenuItem({
          click: () => {
            this.updateLanguage(lang.slug);
          },
          title: lang.name
        }));
      }
    });

    if (lSection.items.length) this.userMenu.push(lSection);

    // Docs section
    const dSection = new ddMenuSection(),
      marketSlug = (this.company && this.company.market.slug) ? this.company.market.slug : undefined;

    dSection.items.push(new ddMenuItem({
      routerLink: marketSlug ? ['/documents', 'terms', marketSlug] : ['/terms'],
      materialIcon: 'history_edu',
      title: 'GLOBAL.TERMS_AND_CONDITIONS'
    }));
    dSection.items.push(new ddMenuItem({
      routerLink: marketSlug ? ['/documents', 'privacy', marketSlug] : ['/privacy'],
      materialIcon: 'policy',
      title: 'GLOBAL.PRIVACY_POLICY'
    }));
    // dSection.items.push(new ddMenuItem({
    //   routerLink: marketSlug ? ['/documents', 'user-manual', marketSlug] : ['/user-manual'],
    //   title: 'GLOBAL.USER_MANUAL'
    // }));
    // if (this.company) {
    //   dSection.items.push(new ddMenuItem({
    //     click: () => {
    //       this.walkthrough();
    //     },
    //     materialIcon: 'help_outline',
    //     title: 'GLOBAL.WALKTHROUGH'
    //   }));
    // }

    if (dSection.items.length) this.userMenu.push(dSection);

    if (this.user.admin) {
      // Admin section
      const aSection = new ddMenuSection();
      aSection.title = 'HEADER.SUPER_ADMIN_HEADER';

      aSection.items.push(new ddMenuItem({
        routerLink: ['/admin', 'tenants'],
        queryParams: {},
        materialIcon: 'developer_board',
        title: 'ADMIN_TENANTS.HEADER_LINK'
      }));
      aSection.items.push(new ddMenuItem({
        routerLink: ['/admin', 'companies'],
        queryParams: { order_by: '-id' },
        materialIcon: 'business',
        title: 'GLOBAL.COMPANY_ADMIN'
      }));
      aSection.items.push(new ddMenuItem({
        routerLink: ['/admin', 'fintech'],
        materialIcon: 'attach_money',
        title: 'FINTECH.MANAGMENT'
      }));
      aSection.items.push(new ddMenuItem({
        routerLink: ['/admin', 'identity-validation'],
        queryParams: { order_by: '-created_at' },
        materialIcon: 'portrait',
        title: 'GLOBAL.IDENTITY_VALIDATION'
      }));
      aSection.items.push(new ddMenuItem({
        routerLink: ['/admin', 'ciec'],
        queryParams: {},
        title: 'ADMIN_CIEC_REQUESTS.HEADER_LINK'
      }));
      aSection.items.push(new ddMenuItem({
        routerLink: ['/admin', 'nip'],
        queryParams: {},
        title: 'NIP.ADMIN_TITLE'
      }));
      aSection.items.push(new ddMenuItem({
        routerLink: ['/admin', 'templates'],
        materialIcon: 'notifications',
        title: 'GLOBAL.TEMPLATES_ADMIN'
      }));
      aSection.items.push(new ddMenuItem({
        routerLink: ['/admin', 'destinations'],
        materialIcon: 'local_shipping',
        title: 'TRUCKS.DESTINATION'
      }));

      aSection.items.push(new ddMenuItem({
        routerLink: ['/admin', 'permissions'],
        title: 'GLOBAL.PERMISSION_ADMIN'
      }));
      aSection.items.push(new ddMenuItem({
        routerLink: ['/admin', 'markets', 'orders'],
        title: 'GLOBAL.ORDERS_ADMIN'
      }));
      // aSection.items.push(new ddMenuItem({
      //   routerLink: ['/admin', 'integrations-monitor'],
      //   queryParams: {},
      //   title: 'INTEGRATIONS.MONITOR'
      // }));

      if (aSection.items.length) this.userMenu.push(aSection);
    }

    const loSection = new ddMenuSection();
    loSection.items.push(new ddMenuItem({
      click: () => {
        this.logout();
      },
      materialIcon: 'exit_to_app',
      title: 'HEADER.LOGOUT'
    }));
    if (loSection.items.length) this.userMenu.push(loSection);
  }

  private buildButtonStack(): void {
    this.createButtonStack = [];

    if (!this.company || !this.account) return;

    if (this.isFullyActivated) {
      let orderTypes = [];
      if (this.company.market.configuration.allowed_order_types) {
        orderTypes = Object.keys(this.company.market.configuration.allowed_order_types);
      } else {
        orderTypes = ['order'];
      }

      orderTypes.push('barter'); // Add to allowed_order_types
      orderTypes.push('fintech'); // Add to allowed_order_types
      orderTypes.push('cd'); // Add to allowed_order_types
      orderTypes.push('network'); // Add to allowed_order_types

      const buttonsData = [];
      const _companyCan = companyCan(this.company);

      const canCreateBarter: boolean = this.company.hasModule('barters') &&
        (this.company.hasModule('market') || this.company.hasModule('my-network')) &&
        this.account.is.commercial &&
        _companyCan.createBarter; // Grain elevatorss, Brokers, Distributors or Suppliers only

      if (
        this.company.hasModule('fintech') &&
        (this.account.is.commercial || this.account.is.financial) &&
        _companyCan.qualify // Exclude some activities
      ) {
        buttonsData.push({
          type: 'fintech',
          link: '/company/' + this.company.id + '/fintech/apply',
          title: 'FINTECH.APPLY.NEW',
          iconHtml: 'attach_money',
          route: '/company/:companyId/fintech/apply'
        });
      }

      if (this.company.hasModule('commercial')) {
        if ((this.company.hasModule('market') || this.company.hasModule('my-network')) &&
          (this.company.activity.broker || this.company.activity.buyer || this.company.activity.seller) &&
          this.account.is.commercial) {
          buttonsData.push({
            type: 'order',
            link: '/company/' + this.company.id + '/create-order',
            title: 'HEADER.CREATE_ORDER',
            iconHtml: 'add_circle_outline',
            route: '/company/:companyId/create-order'
          });
          if (environment.modules.auctions &&
            this.company.market.configuration.allowed_order_types.auction) {
            buttonsData.push({
              type: 'auction',
              link: '/company/' + this.company.id + '/create-auction',
              title: 'HEADER.CREATE_AUCTION',
              iconHtml: 'gavel',
              route: '/company/:companyId/create-auction'
            });
          }
        }

        if (canCreateBarter) {
          buttonsData.push({
            type: 'barter',
            link: '/company/' + this.company.id + '/create-barter',
            title: 'BARTERS.BARTER_PROPOSAL',
            iconHtml: 'swap_horiz',
            route: '/company/:companyId/create-barter'
          });
        }

        if (environment.modules.certificate_deposit &&
          this.account.is.commercial &&
          (this.company.activity.seller && !this.company.activity.broker && this.company.market.id === 2) // Commodities AR only
        ) {
          buttonsData.push({
            type: 'cd',
            link: '/company/' + this.company.id + '/create-cd',
            title: 'GLOBAL.CERTIFICATE_DEPOSIT',
            iconHtml: 'archive',
            route: '/company/:companyId/create-cd'
          });
        }
      }

      if (this.company.hasModule('my-network') &&
        this.account.is.commercial) {
        buttonsData.push({
          type: 'network',
          click: () => {
            this.inviteModal.open();
          },
          title: 'COUNTERPARTIES.NETWORK.MODAL.TITLE',
          iconHtml: 'add'
        });
      }

      buttonsData.forEach(value => {
        if (orderTypes.indexOf(value.type) !== -1) {
          this.createButtonStack.push(value);
        }
      });
    }
  }

  /** @ignore */
  ngOnDestroy(): void {
    // Unsubscribe from everything
    if (this.notificationSubscription) this.notificationSubscription.unsubscribe();
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

class ddMenuItem {
  routerLink?: any[];
  queryParams?: any;
  materialIcon?: string;
  glyphicon?: string;
  iconClass?: string = '';
  title: string;
  click?: Function;

  constructor(data: Partial<ddMenuItem> = {}) {
    Object.assign(this, data);
  }
}

class ddMenuSection {
  title?: string;
  items: ddMenuItem[] = [];
}
