import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { plainToInstance } from "class-transformer";
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { map, mergeMap, startWith, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { ActivationLevel } from '../admin/models/activation-level.model';
import { Account } from '../auth/models/account.model';
import { LoginService } from '../auth/services/login.service';
import { Negotiation } from '../company/modules/commercial/models/negotiation.model';
import { CompanyActivity } from '../models/company-activity.model';
import { Company } from '../models/company.model';
import { FileManagerFile } from '../models/file-manager-file.model';
import { FiscalId } from '../models/fiscal-id.model';
import { GeoSelection } from '../models/geo-selection.model';
import { JSend } from '../models/jsend.model';
import { PlatformModule } from '../models/platform-module.model';
import { PusherMessage } from '../models/pusher-message.model';
import { PrimitiveUnit } from '../models/unit.model';
import { RouteSpyService } from '../ui/services/route-spy.service';
import { buildFilters } from '../utilities/filters';
import { CacheService } from './cache.service';
import { ErrorService } from './error.service';
import { PusherService } from './pusher.service';

@Injectable()
export class CompanyService {

  public company: Company;

  private currentCompanyId: number;
  private currentCompany = new BehaviorSubject<Company>(null);
  private currentAccount = new BehaviorSubject<Account>(null);

  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private router: Router,
    private routeSpyService: RouteSpyService,
    private pusherService: PusherService,
    private errorService: ErrorService,
    private cacheService: CacheService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.checkCurrent();
      }
    });
  }

  private adminCompaniesUrl = '/:apiBase/admin/companies';
  private adminCompanyActivationUrl = this.adminCompaniesUrl + '/:companyId/activation';
  private companiesUrl = '/:apiBase/companies';
  // private adminValidateCuitUrl = '/:apiBase/admin/validate-cuit/:cuit';
  private companyAllActivitiesUrl = '/:apiBase/company-activities';
  private platformModulesUrl = '/:apiBase/admin/platform-modules';
  private companyUrl = this.companiesUrl + '/:companyId';
  private viewCompanyPrivateFileUrl = this.companyUrl + '/file-url';
  private viewPrivateFileUrl = this.companyUrl + '/negotiations/:negotiationId';
  private companyActivityUrl = this.companyUrl + '/activity';
  private counterpartsUrl = this.companyUrl + '/authorized-buyers';
  private brokersUrl = this.companyUrl + '/broker-companies';
  private positionsUrl = '/:apiBase/company-positions';
  private companiesPrivateUrl = this.companyUrl + '/privates';
  private documentEnmendUrl = this.companyUrl + '/negotiations/:negotiationId';
  private crudAccountProductsUrl = this.companyUrl + '/products/accounts';
  private companyActivationLevelsUrl = this.adminCompaniesUrl + '/activation-levels';
  private deleteLogoUrl = this.companyUrl + '/logo';
  private platformModulesActivationUrl = this.adminCompaniesUrl + '/:companyId/module';
  private searchByFiscalValueUrl = this.companyUrl + '/countries/:countryId/fiscal-value/:fiscalValue';
  private companyFileUrl = this.viewCompanyPrivateFileUrl + '/:fileKey/:fileId';
  private companySTOPkeyUrl = this.companyUrl + '/stop-keys';
  private importFromFileUrl = this.companiesUrl + '/import-by-tax-file';
  private adminCompanyAddress = this.adminCompaniesUrl + '/:companyId/address';
  private importCompanyUrl = this.companyUrl + '/import-file';
  private importUrl = this.companiesUrl + '/import-file';

  /**
   * Maps only those parameters that don't match in the API call.
   * Format - 'Webapp query': 'API query'
   */
  private readonly queryMap: Record<string, string> = {
    'zone_id': 'filters[zones][][id]',
    'location_id': 'filters[locations][][id]',
    'buyer': 'filters[activity][buyer]',
    'seller': 'filters[activity][seller]',
    'include_me': 'filters[include_me]',
    'market_id': 'filters[market][id]',
    'filters[company.name]': 'filters[name]'
  };

  private fillFormData(form: FormData, entity: any, skip: any = {}, base: string = ""): void {
    for (const key in entity) {
      const fullKey = base === "" ? key : base + '[' + key + ']';
      const typeStr = Object.prototype.toString.call(entity[key]);

      if (fullKey in skip) {
        continue;
      }

      if (typeStr === "[object Object]") {
        this.fillFormData(form, entity[key], skip, fullKey);
      } else if (typeStr === "[object Array]") {
        for (let i = 0; i < entity[key].length; i++) {
          form.append(fullKey + '[]', entity[key][i]);
        }
      } else if (typeStr === "[object FileList]") {
        for (let i = 0; i < entity[key].length; i++) {
          form.append(fullKey + '[]', entity[key][i]);
        }
      } else {
        form.append(fullKey, entity[key]);
      }
    }
  }

  /**
   * @deprecated
   * Use watch.
   */
  public set(company: Company): void {
    localStorage.setItem("company", JSON.stringify(company));
    this.company = company;
  }

  /**
   * @deprecated
   * Use watch.
   */
  public unset(): void {
    localStorage.removeItem("company");
    delete this.company;
  }

  private _company_refresh: Subscription;

  public checkCurrent(force_refresh = false): void {
    const snapshot = this.routeSpyService.getSnapshot('/company/:companyId');

    if (snapshot && snapshot.params?.companyId) {
      const companyId = snapshot.params.companyId;

      if (companyId !== this.currentCompanyId || force_refresh) {
        this.currentCompanyId = companyId;
        this.companyListener();
      }
    } else {
      this.currentCompanyId = undefined;
      this.currentCompany.next(null);
    }
  }

  private companyListener(): void {
    if (this._requestingCompanyID == this.currentCompanyId) return;

    if (this._company_refresh) this._company_refresh.unsubscribe();

    this._company_refresh = this.pusherService.listen(`company_${this.currentCompanyId}`, 'refresh_company').pipe(
      startWith({}),
      mergeMap(event => {
        if (this.currentCompanyId) {
          return this.getRequestCompany(this.currentCompanyId);
        } else {
          return of(null);
        }
      })
    ).subscribe({
      next: company => {
        if (company) {
          this.currentCompany.next(company);
          this.getAccountsForCompany(company.id);
        } else {
          this._company_refresh.unsubscribe();
          this.currentCompany.next(null);
        }
      },
      error: error => {
        this.currentCompany.next(null);
        this.errorService.handleError(error);
      }
    });
  }

  private _requestingCompanyID: number;

  public getRequestCompany(companyId: number): Observable<Company> {
    this._requestingCompanyID = companyId;

    const url = this.companyUrl.replace(':companyId', companyId.toString());
    const stream = this.http.get<Company>(url);

    return stream.pipe(
      map(company => {
        this._requestingCompanyID = null;
        return plainToInstance(Company, company);
      })
    );
  }

  public watch(): BehaviorSubject<Company> {
    return this.currentCompany;
  }

  public watchAccount(): BehaviorSubject<Account> {
    return this.currentAccount;
  }

  private getAccountsForCompany(companyId: Number): Subscription {
    return this.loginService.getAccountsForCompany(companyId).subscribe(accounts => {
      this.currentAccount.next(accounts[0]);
    });
  }

  public create(company: Company): Observable<Company> {
    const form = new FormData();

    this.fillFormData(form, company, {
      "address[localidad][levels]": true
    });

    return this.http.post<Company>(this.companiesUrl, form);
  }

  public update(company: Company): Observable<Company> {

    const form = new FormData();

    this.fillFormData(form, company, {
      "address[localidad][levels]": true
    });

    const url = this.companyUrl.replace(':companyId', company.id.toString());

    // TODO: No debería ser un POST, debería ser un PUT.
    return this.http.post<Company>(url, form);
  }

  public createAsAdmin(company: Company): Observable<Company> {
    return this.http.post<Company>(this.adminCompaniesUrl, company).pipe(map(response => plainToInstance(Company, response)));
  }

  public updateAsAdmin(company: Company): Observable<Company> {
    const url = `${this.adminCompaniesUrl}/${company.id}`;
    return this.http.put<Company>(url, company);
  }

  public updateActivity(company: Company): Observable<Company> {
    const url = this.companyActivityUrl.replace(':companyId', company.id.toString());

    return this.http.put<Company>(url, {
      'company_activity_id': company.activity.id
    });
  }

  public getAllActivities(): Observable<CompanyActivity[]> {
    const url = this.companyAllActivitiesUrl;

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<CompanyActivity[]>(url).pipe(
      tap(data => this.cacheService.set(url, data, 60 * 24))
    );
  }

  public getPositions(): Observable<PrimitiveUnit[]> {
    const url = this.positionsUrl;

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<PrimitiveUnit[]>(url).pipe(
      tap(data => this.cacheService.set(url, data, 60 * 24 * 7))
    );
  }

  public getBrokers(companyId: number, requireBartersModule: boolean = false): Observable<Company[]> {
    let url = this.brokersUrl.replace(':companyId', companyId.toString());

    if (requireBartersModule)
      url += '?requireBartersModule=' + requireBartersModule;

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<Company[]>(url).pipe(
      tap(data => this.cacheService.set(url, data, 60))
    );
  }

  /**
   * Retrieves a query string for fetching companies visible to the current company.
   * 
   * The response is an array of Companies visible to the current Company. It takes into account
   * the visibility of the market, the Company's network, and the approved Companies.
   * 
   * @param {number} companyId - The ID of the current company.
   * @param {Object} [filters] - Optional filters to apply to the query.
   * @param {boolean} [filters.all] - Whether to include all companies registered in the market.
   * @param {boolean} [filters.buyers] - Whether to include or exclude buyers.
   * @param {boolean} [filters.sellers] - Whether to include or exclude sellers.
   * @param {boolean} [filters.brokers] - Whether to include or exclude brokers.
   * @param {number[]} [filters.included_activities] - Array of CompanyActivity IDs to include.
   * @param {number[]} [filters.excluded_activities] - Array of CompanyActivity IDs to exclude.
   * @param {number[]} [filters.excluded_ids] - Array of Company IDs to exclude.
   * @param {string} [filters.scenario] - Scenario for full object.
   * @returns {Function} - A function that generates a query URL string for fetching companies.
   */
  public getCompanies(
    companyId: number,
    filters?: {
      all?: boolean;
      buyers?: boolean;
      sellers?: boolean;
      brokers?: boolean;
      included_activities?: number[];
      excluded_activities?: number[];
      excluded_ids?: number[];
      scenario?: string;
    }) {
    const query: string[] = [];
    const stream = this.companyUrl.replace(':companyId', companyId.toString());

    if (filters) {
      /**
       * Filters and joins an array of numbers into a comma-separated string, removing duplicates.
       * 
       * @param {number[]} a - The array of numbers to filter and join.
       * @returns {string} - A comma-separated string of unique numbers.
       */
      const filterAndJoin = function (a: number[]): string {
        return a.filter((value, index, self) => value && self.indexOf(value) === index).join(',');
      };

      /**
       * Adds an activity filter to the query string based on the provided key and boolean value.
       * 
       * @param {string} key - The activity key (e.g., 'buyer', 'seller', 'broker').
       * @param {boolean} value - Whether to include or exclude the activity.
       * @returns {string} - The generated filter string for the query.
       */
      const addActivityFilter = function (key: string, value: boolean): string {
        return typeof value === 'boolean' ? `filters[activity.${key}]=equal:${value}` : '';
      };

      /**
       * Builds and adds a filter query to the main query array.
       * 
       * @param {string} key - The key for the filter (e.g., 'activity.id').
       * @param {string} condition - The condition to apply (e.g., 'in', '!in').
       * @param {number[]} arr - The array of IDs to filter.
       */
      const buildFilterQuery = function (key: string, condition: string, arr: number[]) {
        const str = filterAndJoin(arr);
        if (str.length) query.push(`filters[${key}]=${condition}:${str}`);
      };

      if (filters.all != undefined) query.push('filters[all]=equal:' + filters.all);

      if (filters.buyers != undefined) query.push(addActivityFilter('buyer', filters.buyers));
      if (filters.sellers != undefined) query.push(addActivityFilter('seller', filters.sellers));
      if (filters.brokers != undefined) query.push(addActivityFilter('broker', filters.brokers));
      if (filters.scenario != undefined) query.push('filters[scenario]=' + filters.scenario);

      if (filters.included_activities) buildFilterQuery('activity.id', 'in', filters.included_activities);
      if (filters.excluded_activities) buildFilterQuery('activity.id', '!in', filters.excluded_activities);
      if (filters.excluded_ids) buildFilterQuery('id', '!in', filters.excluded_ids);
    }

    /**
     * Generates the final query URL string for fetching companies.
     * 
     * @param {string} [name] - Optional name filter to apply.
     * @returns {string} - The generated query URL string.
     */
    return (name?: string) => {
      query.push('page=1');
      if (name) query.push(`filters[name]=contains:${name}`);
      return `${stream}/companies?${query.join('&')}`;
    };
  }

  private getPrivateCompaniesUrl(query, companyId: number, operation_type?: 'compra' | 'venta', locations?: GeoSelection[], include_me?: boolean): string {
    let url = this.companiesPrivateUrl.replace(':companyId', companyId.toString()) + '?text-search=' + query;

    const filters = [];

    if (operation_type === 'compra')
      filters['seller'] = "1";
    else if (operation_type === 'venta') {

      filters['buyer'] = "1";

      const locationsArray = [], zonesArray = [];

      locations.forEach(location => {
        if (location.location) {
          locationsArray.push(location.location.id.toString());
          filters['location_id'] = locationsArray;
        } else if (location.zone) {
          zonesArray.push(location.zone.id.toString());
          filters['zone_id'] = zonesArray;

        }
      });
    }

    if (include_me) {
      filters['include_me'] = true;
    }

    url = buildFilters(url, filters, this.queryMap);

    return url;
  }

  public getAdminCompaniesUrl(filters?: any): Function {
    let url = this.adminCompaniesUrl;
    url = buildFilters(url, filters, this.queryMap);

    return (name?: string) => {
      if (name) {
        const separator = (url.indexOf('?') === -1) ? '?' : '&';
        url = url + separator + 'page=1&filters[name]=contains:' + name;
      }
      return url;
    };
  }

  public getAdminCompanies(filters?: any): Observable<{ body: Company[], headers: HttpHeaders }> {
    const url = this.getAdminCompaniesUrl(filters)();
    const stream = this.http.get<Company[]>(url, { observe: 'response' });

    return stream.pipe(map(response => ({ body: plainToInstance(Company, response.body), headers: response.headers })));
  }

  public watchAdminCompanies(filters?: any): Observable<any> {
    return this.pusherService.listen('admins', 'companies').pipe(
      startWith({}),
      mergeMap(event => this.getAdminCompanies(filters))
    );
  }

  public getCompanyActivationLevels(): Observable<ActivationLevel[]> {
    const url = this.companyActivationLevelsUrl;

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<ActivationLevel[]>(url).pipe(
      tap(data => this.cacheService.set(url, data, 60 * 24 * 30))
    );
  }

  public getCounterparts(companyId: number, locations: number[], zones: number[], only_my_network: number = 0): Observable<Company[]> {
    const qLocations = (locations || []).map(loc => 'location[]=' + loc).join('&');
    const qZones = (zones || []).map(zone => 'zone[]=' + zone).join('&');

    const url = this.counterpartsUrl.replace(':companyId', String(companyId)) +
      '?' + qLocations + (qZones && qLocations ? '&' : '') + qZones + '&only_my_network=' + only_my_network;

    const stream = this.http.get<Company[]>(url);

    return stream.pipe(map(companies => plainToInstance(Company, companies)));
  }

  public activateCompany(company: Company, activation_level_id: number): Observable<any> {
    const url = this.adminCompanyActivationUrl.replace(':companyId', company.id.toString());

    return this.http.post<any>(url, {
      activation_level_id: activation_level_id
    });
  }

  public viewFile(company: Company, negotiation: Negotiation, file: string): Observable<any> {
    const url = this.viewPrivateFileUrl.replace(':companyId', company.id.toString()).replace(':negotiationId', negotiation.id.toString()) + '/' + file;

    return this.http.get<any>(url);
  }

  public companySolver(locations: GeoSelection[], operation_type: 'compra' | 'venta', companyId: number, include_me?: boolean): Function {
    if (companyId) {
      return (query: string) => this.getPrivateCompaniesUrl(query, companyId, operation_type, locations, include_me);
    }
  }

  public amendDocument(companyId: number, negotiation_id: number, document_type: string, data: any = {}): Observable<Company> {
    const url = this.documentEnmendUrl.replace(':companyId', companyId.toString()).replace(':negotiationId', negotiation_id.toString()) + '/' + document_type;

    return this.http.put<Company>(url, data);
  }

  public crudAccountProduct(companyId: number, data: any = {}): Observable<Company> {
    const url = this.crudAccountProductsUrl.replace(':companyId', companyId.toString());

    return this.http.post<Company>(url, data);
  }

  public deleteLogo(companyId: number): Observable<any> {
    const url = this.deleteLogoUrl.replace(":companyId", companyId.toString());

    return this.http.delete<any>(url);
  }

  public getPlatformModules(): Observable<PlatformModule[]> {
    const url = this.platformModulesUrl;

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<PlatformModule[]>(url).pipe(
      tap(data => this.cacheService.set(url, data, 60 * 24 * 30))
    );
  }

  public activateModule(companyId: number, module_id: number): Observable<any> {
    const url = this.platformModulesActivationUrl.replace(":companyId", companyId.toString());

    return this.http.post(url, {
      module_id: module_id
    });
  }

  public deactivateModule(companyId: number, module_id: number): Observable<any> {
    const url = this.platformModulesActivationUrl.replace(":companyId", companyId.toString()) + '/' + module_id;

    return this.http.delete(url);
  }

  public getCompaniesByFiscalValue(companyId: number, countryId: number, fiscalValue: string): Observable<Company[]> {
    const url = this.searchByFiscalValueUrl
      .replace(":companyId", companyId.toString())
      .replace(":countryId", countryId.toString())
      .replace(':fiscalValue', fiscalValue.toString());

    const cached: Company[] = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(plainToInstance(Company, cached));

    return this.http.get<Company[]>(url).pipe(
      map(companies => {
        this.cacheService.set(url, companies, 60 * 24);
        return plainToInstance(Company, companies);
      })
    );
  }

  /**
   * @param data Must include the reference property with a unique string
   * identifier. It can be used as a folder or to link to an entity. The rest
   * of the properties must be in {slug: binary} format.
   */
  public uploadCompanyFile(companyId: number, data: FormData): Observable<any> {
    const url = this.companyUrl.replace(':companyId', companyId.toString());

    return this.http.post<any>(url, data);
  }

  public getCompanyFile(companyId: number, file_key: string, file_id: string): Observable<FileManagerFile> {
    const url = this.companyFileUrl.replace(':companyId', companyId.toString())
      .replace(':fileKey', file_key.toString())
      .replace(':fileId', file_id.toString());

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(plainToInstance(FileManagerFile, cached));

    return this.http.get<FileManagerFile>(url).pipe(
      map(response => {
        this.cacheService.set(url, response, 60 * 24);
        return plainToInstance(FileManagerFile, response)
      })
    );
  }

  /** Sets the STOP API key of the [[Company]]. */
  public setSTOPkey(companyId: number, key: string): Observable<any> {
    const url = this.companySTOPkeyUrl.replace(':companyId', companyId.toString());

    return this.http.post<any>(url, {
      key: key
    });
  }

  public patchCompanyAsAdmin(company: Company): Observable<any> {
    const url = this.adminCompanyAddress.replace(':companyId', company.id.toString());
    return this.http.patch<Company>(url, company);
  }

  private _pusherSubs: { [eventKey: string]: Subscription } = {};
  private _immportSubjects: { [eventKey: string]: BehaviorSubject<Company> } = {};

  /** @deprecated */
  public importFromFile(file: File, fiscal_id?: FiscalId): Observable<Company> {
    const eventKey = uuidv4();
    const url = this.importFromFileUrl;

    const formData = new FormData();
    formData.append("file", file);
    if (fiscal_id) formData.append("fiscalId", JSON.stringify(fiscal_id));

    const stream = this.http.post<JSend<{
      status: 'PROCESSING' | 'PROCESSED' | 'FAILED';
      message: string;
      channel: string;
      company?: Company;
    }>>(url, formData);

    this._immportSubjects[eventKey] = new BehaviorSubject<Company>(null);

    stream.subscribe({
      next: response => {
        if (response.data.company) {
          this._immportSubjects[eventKey].next(plainToInstance(Company, response.data.company));
        } else {
          if (response.data.status !== 'FAILED') {
            // Me subscribo al Pusher porque el proceso fue a Textract
            this._pusherSubs[eventKey] = this.pusherService.listen(response.data.channel, 'company').subscribe((event: PusherMessage) => {
              if (event.data.company) {
                this._pusherSubs[eventKey].unsubscribe();
                return this._immportSubjects[eventKey].next(plainToInstance(Company, event.data.company));
              }
            });
          } else {
            this._immportSubjects[eventKey].error(response.data);
          }
        }
      },
      error: error => {
        this._immportSubjects[eventKey].error(error);
      }
    });

    return this._immportSubjects[eventKey];
  }

  public import(file: File, companyId?: number, fiscal_id?: FiscalId): Observable<Company> {
    const eventKey = uuidv4();

    let url = this.importUrl;
    if (companyId) {
      url = this.importCompanyUrl.replace(':companyId', companyId.toString());
    }

    const formData = new FormData();
    formData.append("file", file);
    if (fiscal_id) formData.append("fiscalId", JSON.stringify(fiscal_id));

    const stream = this.http.post<JSend<{
      status: 'PROCESSING' | 'PROCESSED' | 'FAILED';
      message: string;
      channel: string;
      company?: Company;
    }>>(url, formData);

    this._immportSubjects[eventKey] = new BehaviorSubject<Company>(null);

    stream.subscribe({
      next: response => {
        if (response.data.company) {
          this._immportSubjects[eventKey].next(plainToInstance(Company, response.data.company));
        } else {
          if (response.data.status !== 'FAILED') {
            // Me subscribo al Pusher porque el proceso fue a Textract
            this._pusherSubs[eventKey] = this.pusherService.listen(response.data.channel, 'company').subscribe((event: PusherMessage) => {
              if (event.data.company) {
                this._pusherSubs[eventKey].unsubscribe();
                return this._immportSubjects[eventKey].next(plainToInstance(Company, event.data.company));
              }
            });
          } else {
            this._immportSubjects[eventKey].error(response.data);
          }
        }
      },
      error: error => {
        this._immportSubjects[eventKey].error(error);
      }
    });

    return this._immportSubjects[eventKey];
  }
}
