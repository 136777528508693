// eslint-disable-next-line no-restricted-syntax
var AbstractLifeCycle = /** @class */ (function () {
    function AbstractLifeCycle() {
        this.callbacks = {};
    }
    AbstractLifeCycle.prototype.notify = function (eventType, data) {
        var eventCallbacks = this.callbacks[eventType];
        if (eventCallbacks) {
            eventCallbacks.forEach(function (callback) { return callback(data); });
        }
    };
    AbstractLifeCycle.prototype.subscribe = function (eventType, callback) {
        var _this = this;
        if (!this.callbacks[eventType]) {
            this.callbacks[eventType] = [];
        }
        this.callbacks[eventType].push(callback);
        return {
            unsubscribe: function () {
                _this.callbacks[eventType] = _this.callbacks[eventType].filter(function (other) { return callback !== other; });
            },
        };
    };
    return AbstractLifeCycle;
}());
export { AbstractLifeCycle };
