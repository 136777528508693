<div class="ag-container">
  <div class="ag-col-secondary">
    <filter-set #filterSet="filterSet" [marketId]="company?.market.id">
        <filter-list key="status_group_orders"></filter-list>
        <filter-list key="operation_type"></filter-list>
        <filter-list key="product_id" sortKey="name"></filter-list>
    </filter-set>
  </div>
  <div class="ag-col-primary">
    <div class="container-fluid">
      <div class="content-box">
        <div class="ag-header sticky" *ngIf="!loading && preOrdersByProduct?.length > 0">
          <div class="ag-header-title">
            <div class="summary" *ngIf="selection.length === 0">
              <span [innerHtml]="'PREORDERS_VIEW.SUMMARIZE'|translate: {quantity: (preOrdersByProduct|pluck:'values'|flatten).length}"></span>
              <span *ngIf="company?.market.quantity_units.length === 1"
              [innerHtml]="'TRADED_VIEW.SUMARIZE_TONS'|translate: {
                quantity: preOrdersByProduct|pluck:'values'|flatten|pluck:'business_detail.quantity.value'|sum|number:undefined:currentLang,
                unit: company?.market.quantity_units[0].abbrev
              }"></span>
            </div>

            <div class="summary" *ngIf="selection.length > 0">
              <span [innerHtml]="'PREORDERS_VIEW.SELECTED'|translate: {quantity: selection.length}"></span>
              <span *ngIf="company?.market.quantity_units.length === 1"
              [innerHtml]="'TRADED_VIEW.SUMARIZE_TONS'|translate: {
                quantity: selection|pluck:'business_detail.quantity.value'|sum|number:undefined:currentLang,
                unit: company?.market.quantity_units[0].abbrev
              }"></span>
              &mdash;
              <a class="small" (click)="clearSelection()">{{ 'GLOBAL.CLEAR_SELECTION'|translate }}</a>
            </div>
          </div>
          <div class="ag-header-actions">
            <div class="btn-toolbar">
              <div class="btn-group" *ngIf="!company?.activity.broker">
                <button type="button"
                class="btn btn-link" [disabled]="selection.length === 0"
                (onConfirm)="massiveCancel()"
                [confirm-options]="{
                  title: (selection.length > 1 ? 'PREORDERS_VIEW.CANCEL_CONFIRM.TITLE_0' : 'PREORDERS_VIEW.CANCEL_CONFIRM.TITLE_1')|translate,
                  body: (selection.length > 1 ? 'PREORDERS_VIEW.CANCEL_CONFIRM.BODY_0' : 'PREORDERS_VIEW.CANCEL_CONFIRM.BODY_1')|translate: {id: (selection|listFormatter:{ key: 'id', prefix: '#' })},
                  action: 'GLOBAL.YES_WITHDRAW'|translate
                }"
                [confirm-close]="processedMassive">{{ 'GLOBAL.WITHDRAW'|translate }}</button>
              </div>
              <div class="btn-group" *ngIf="preOrdersEnabled && company?.activity.broker">
                <button type="button"
                title="Rechazar" class="btn btn-default" [disabled]="selection.length === 0 || reviewing"
                (onConfirm)="massiveReject()"
                [confirm-options]="{
                  title: (selection.length > 1 ? 'PREORDERS_VIEW.REJECT_CONFIRM.TITLE_0' : 'PREORDERS_VIEW.REJECT_CONFIRM.TITLE_1')|translate,
                  body: (selection.length > 1 ? 'PREORDERS_VIEW.REJECT_CONFIRM.BODY_0' : 'PREORDERS_VIEW.REJECT_CONFIRM.BODY_1')|translate: {id: (selection|listFormatter:{ key: 'id', prefix: '#' })},
                  action: 'GLOBAL.YES_REJECT'|translate
                }"
                [confirm-close]="processedMassive">
                  <i class="material-symbols-rounded hidden-xs hidden-sm">undo</i> {{ 'GLOBAL.REJECT'|translate }}
                </button>
                <button type="button"
                class="btn btn-agree" [disabled]="selection.length === 0 || reviewing" (click)="massiveReview()">
                  <i class="material-symbols-rounded hidden-xs hidden-sm">edit</i> {{ 'PREORDERS_VIEW.REVIEW'|translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="loading" *ngIf="loading">
          <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
        </div>

        <div class="row" *ngIf="!loading">
          <div class="col-xs-12">
            <p *ngIf="company?.activity.broker">{{ 'PREORDERS_VIEW.DESCRIPTION_0'|translate }}</p>
            <p *ngIf="!company?.activity.broker">{{ 'PREORDERS_VIEW.DESCRIPTION_1'|translate }}</p>
            <!-- No data -->
            <div class="no-data" *ngIf="preOrdersByProduct?.length === 0">
              <i class="material-symbols-rounded">not_interested</i>
              {{ 'PREORDERS_VIEW.NO_RECORDS'|translate }}
            </div>
            <!-- Data -->
            <!-- https://www.w3.org/WAI/tutorials/tables/examples/threeheaders/ -->
            <div class="horizontal-scroller">
              <table class="table table-condensed" sortable defaultSortBy="companyName" #tableSortable="tableSortable" *ngIf="preOrdersByProduct?.length > 0"
              AgMultiselection
              [(ngModel)]="selection">
                <!-- <caption></caption> -->
                <thead>
                  <tr class="tr-align-center">
                    <th class="sap" *ngIf="preOrdersEnabled">
                      <input type="checkbox" AgSelectall
                      id="select-all-toggle"
                      class="show-onhover no-margin"
                      tooltip="{{ 'WORKING_ORDERS.SELECT_ALL'|translate }}"
                      container="body" [adaptivePosition]="false">
                    </th>
                    <th class="sap hidden-xs hidden-sm"></th>
                    <th *ngIf="company?.activity.broker" sortBy="companyName">{{ 'GLOBAL.COMPANY'|translate }}</th>
                    <th *ngIf="!company?.activity.broker" sortBy="broker_company.name">{{ 'PREORDERS_VIEW.TABLE_HEADER.BROKER'|translate }}</th>
                    <th class="hidden-xs hidden-sm" sortBy="locationString" *ngIf="company?.market.configuration.location.enabled">{{ 'GLOBAL.DELIVERY_PLACES'|translate }}</th>
                    <th class="hidden-xs hidden-sm" sortBy="portString" *ngIf="company?.market.configuration.order.business_detail.port.enabled">{{ 'GLOBAL.COUNTRY_PORT'|translate }}</th>
                    <th class="sap hidden-xs hidden-sm" sortBy="deliveryType" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
                      <abbr title tooltip="{{ 'GLOBAL.DELIVERY_TYPE'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.DELIVERY_TYPE_ABBR'|translate }}</abbr>
                    </th>
                    <th sortBy="order_status.name">{{ 'GLOBAL.STATUS'|translate }}</th>
                    <th class="sap dlb drb" sortBy="business_detail.quantity.valueString">
                      <abbr title tooltip="{{ 'GLOBAL.QUANTITY'|translate }}" [adaptivePosition]="false">{{ 'GLOBAL.QUANTITY_ABBR'|translate }}</abbr>
                    </th>
                    <th class="sap text-center hidden-xs" sortBy="business_detail.delivery.intDateFrom">{{ 'GLOBAL.PERIOD'|translate }}</th>
                    <th class="sap" sortBy="business_detail.price.value">
                      {{ 'GLOBAL.PRICE'|translate }}
                      <ng-container *ngIf="company?.market.quantity_units.length === 1">/{{ company?.market.quantity_units[0].abbrev }}</ng-container>
                    </th>
                  </tr>
                </thead>
                <tbody *ngFor="let group of preOrdersByProduct">
                  <!-- Product sub-header -->
                  <tr class="product-header">
                    <th class="text-uppercase" colspan="3">{{ group.key.name }}</th>
                    <th *ngIf="preOrdersEnabled"></th>
                    <th class="hidden-xs hidden-sm"></th>
                    <th *ngIf="company?.activity.broker"></th>
                    <th *ngIf="!company?.activity.broker"></th>
                    <th class="hidden-xs hidden-sm" *ngIf="company?.market.configuration.location.enabled"></th>
                    <th class="hidden-xs hidden-sm" *ngIf="company?.market.configuration.order.business_detail.port.enabled"></th>
                    <th class="hidden-xs hidden-sm" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists"></th>
                    <th class="hidden-xs"></th>
                  </tr>
                  <!-- Order row -->
                  <tr class="tr-align-center" *ngFor="let order of group.values | orderBy: tableSortable.sortBy">
                    <td class="sap" *ngIf="preOrdersEnabled">
                      <input
                      *ngIf="order.order_status.id === 2"
                      [AgCheckbox]="order" type="checkbox" id="order_id_{{ order.id }}_check"
                      class="show-onhover no-margin">
                    </td>
                    <td class="sap hidden-xs hidden-sm">
                      <company-logo [company]="order.company"></company-logo>
                    </td>
                    <td>
                      <a class="order-link" (click)="gotoOrder($event, order.id)" title="{{ order.companyName }}">
                        <span class="truncate">
                          <samp class="small hidden-xs hidden-sm">#{{ order.id }}:</samp>
                          <b *ngIf="company?.activity.broker">{{ order.companyName }}</b>
                          <b *ngIf="!company?.activity.broker"><i class="material-symbols-rounded hidden-xs hidden-sm">arrow_right_alt</i>{{ order.broker_company.name }}</b>
                        </span>
                      </a>
                      <div class="visible-xs visible-sm small text-muted">
                        <span *ngIf="company?.market.configuration.location.enabled" class="clamp-2">{{ order.business_detail.delivery.locations |locationString }}</span>
                        <span *ngIf="company?.market.configuration.order.business_detail.port.enabled" class="clamp-2">{{ countryAndPort(order) }}</span>
                        <span *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists" class="clamp-2">({{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }})</span>
                      </div>
                    </td>
                    <td class="hidden-xs hidden-sm small" *ngIf="company?.market.configuration.location.enabled">
                      <span class="clamp-2">{{ order.business_detail.delivery.locations |locationString }}</span>
                    </td>
                    <td class="hidden-xs hidden-sm small" *ngIf="company?.market.configuration.order.business_detail.port.enabled">
                      {{ countryAndPort(order) }}
                    </td>
                    <td class="sap hidden-xs hidden-sm small" *ngIf="company?.market.configuration.order.business_detail.delivery.delivery_type.show_in_lists">
                      {{ order.business_detail.delivery.delivery_type?.short_name || order.business_detail.delivery.delivery_type?.name }}
                    </td>
                    <td>
                      <div class="co-status text-uppercase" (click)="gotoOrder($event, order.id)">
                        <div class="truncate"
                        [class.status-info]="order.company.id === companyId && order.order_status.id === 2"
                        [class.status-warning]="order.broker_company?.id === companyId && order.order_status.id === 2"
                        [class.status-danger]="[3, 4, 6].includes(order.order_status.id)"
                        [class.status-success]="order.order_status.id === 5"><span>
                          {{ order.order_status.name }}
                          <span *ngIf="order.order_status.id === 5 && company?.activity.broker"><i class="material-symbols-rounded">arrow_right_alt</i> <a (click)="gotoOrder($event, order.parent_order?.id)">#{{ order.parent_order?.id }}</a></span>
                        </span></div>
                      </div>
                    </td>
                    <td class="sap text-right dlb drb">
                      <quantity-control [quantity]="order.business_detail.quantity" [compact]="true"></quantity-control>
                    </td>
                    <td class="sap hidden-xs">
                      <date-range
                      [showSpot]="true"
                      [from]="order.business_detail.delivery.date_from"
                      [to]="order.business_detail.delivery.date_to"></date-range>
                    </td>
                    <td class="sap">
                      <div class="price-box" [class.sell-bg]="order.operation_type === 'venta'" [class.buy-bg]="order.operation_type === 'compra'">
                        <div class="type">{{ (order.operation_type === 'compra' ? 'GLOBAL.BID' : 'GLOBAL.SELL')|translate }}</div>
                        <div class="value">
                          <price-display [price]="order.business_detail.price" [market]="company?.market" [withoutYear]="true"></price-display>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TODO: Analyze how to replace with the confirm directive -->
<agree-confirm
  title="{{ confirmTitle }}"
  body="{{ confirmBody }}"
  confirm-button="{{ confirmButton }}"
  cancel-button="{{ cancelButton }}"
  #agreeConfirm="agreeConfirm"
>
</agree-confirm>
