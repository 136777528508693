import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CurrentDateService } from '../../../services/current-date.service';
import { addDays, startOfDay } from '../../../utilities/date';

/**
 * DateRangeComponent displays and styles a range of dates.
 * 
 * ## Usage
 * ```html
 * <date-range [from]="startDate" [to]="endDate" [showSpot]="true" [styles]="true"></date-range>
 * ```
 */
@Component({
  selector: 'date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeComponent implements OnInit, OnChanges {
  /**
   * Start date of the range.
   */
  @Input() public from: Date;

  /**
   * End date of the range.
   */
  @Input() public to: Date;

  /**
   * If true, highlights the range as "spot" if the current date falls within the range.
   */
  @Input() public showSpot: boolean;

  /**
   * If true, applies styling based on range status (e.g., expired, to expire).
   */
  @Input() public styles: boolean = true;

  /**
   * Displays a simpler range style if true.
   */
  @Input() public simple: boolean = false;

  private today: Date;
  /** Current date at the start of the day. */
  private todayStart: Date;
  /** Date seven days from the current date. */
  private nextWeek: Date;

  /**
   * Current language, used for localization.
   */
  public currentLang: string;
  /** CSS class for the date range. */
  public rangeClass: string = '';
  /** CSS class for the spot highlight. */
  public spotClass: string = '';

  constructor(
    private currentDate: CurrentDateService,
    private translateService: TranslateService
  ) {
    this.today = this.currentDate.get();
    this.todayStart = startOfDay(this.today);
    this.nextWeek = addDays(this.today, 7);
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang === 'es' ? undefined : this.translateService.currentLang;
  }

  /** Updates the component state when input values change. */
  ngOnChanges(changes: SimpleChanges): void {
    this.from = this.ensureDate(this.from);
    this.to = this.ensureDate(this.to);
    this.updateStyles();
  }

  /**
   * Converts a string to a Date object if necessary.
   * @param value The date value (string or Date).
   * @returns The parsed Date object.
   */
  private ensureDate(value: Date | string): Date {
    return typeof value === 'string' ? new Date(value) : value;
  }

  /**
   * Updates CSS classes for range and spot highlights.
   */
  private updateStyles(): void {
    this.rangeClass = this.styles
      ? this.getRangeClass()
      : '';

    this.spotClass = this.styles && this.showSpot
      ? this.getSpotClass()
      : '';
  }

  /**
   * Determines the CSS class for the date range.
   * @returns The appropriate CSS class.
   */
  private getRangeClass(): string {
    if (this.to.getTime() < this.todayStart.getTime()) {
      return 'expired';
    }
    if (this.to.getTime() < this.nextWeek.getTime()) {
      return 'to_expire';
    }
    return '';
  }

  /**
   * Determines the CSS class for the spot highlight.
   * @returns The appropriate CSS class.
   */
  private getSpotClass(): string {
    const isActive = this.today.getTime() >= this.from.getTime() &&
      this.to.getTime() >= this.todayStart.getTime();
    return isActive ? 'spot' : '';
  }

}
