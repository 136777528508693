<div class="row">
  <div class="col-xs-12">
    <h4>{{ 'API_KEY.TITLE'|translate }}</h4>
  </div>
</div>
<div class="row">
  <div class="col-sm-7">
    <p [innerHtml]="'API_KEY.DESCRIPTION'|translate"></p>

    <!-- Keys -->
    <ng-container *ngIf="apiKeys; else loading">
      <table class="table"
      *ngIf="apiKeys?.length > 0; else noData"
      [sortable]="apiKeys" defaultSortBy="-created_at" #tableSortable="tableSortable">
        <!-- <caption></caption> -->
        <thead>
          <tr>
            <th class="sap"
            sortBy="created_at">{{ 'TEMPLATES_VIEW.TABLE_HEADER.DATE'|translate }}</th>
            <th class="sap text-center"
            sortBy="status">{{ 'GLOBAL.STATUS'|translate }}</th>
            <th>Key</th>
            <th class="sap"></th>
            <th class="sap"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="tr-align-center"
          *ngFor="let key of tableSortable.collection"
          [class.bg-danger]="key.status === 'DELETED'"
          [class.bg-warning]="key.status === 'INACTIVE'">
            <td class="small sap"><date-md [date]="key.created_at"></date-md></td>
            <td class="sap text-center">
              <span class="label"
              [class.label-success]="key.status === 'ACTIVE'"
              [class.label-danger]="key.status === 'DELETED'"
              [class.label-warning]="key.status === 'INACTIVE'">{{ 'API_KEY.STATUS.'+key.status|translate }}</span>
            </td>
            <td><input class="form-control key monospace" [readonly]="true" [type]="getType(key.api_key)" [value]="key.api_key" /></td>
            <td class="sap">
              <a (click)="toggleType(key.api_key)">
                <span *ngIf="getType(key.api_key) === 'password'" class="material-symbols-rounded">visibility</span>
                <span *ngIf="getType(key.api_key) === 'text'" class="material-symbols-rounded">visibility_off</span>
              </a>
            </td>
            <td class="sap">
              <a (click)="clipboardService.copy(key.api_key)"
              title="{{ 'GLOBAL.COPY_CLIPBOARD'|translate }}">
                <span class="material-symbols-rounded">content_copy</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
  <div class="col-sm-5">
    <pre class="small">curl --location 'https://public-api.agree.ag/{{ '{{' }}PATH{{ '}}' }}' \
  --header 'authorization: Bearer {{ '{{' }}API_KEY{{ '}}' }}‘ \
  --header 'accept: application/json' \
  --header 'accept-language: es'</pre>
      <div class="text-center text-muted small">{{ 'API_KEY.SAMPLE'|translate }}</div>
  </div>
</div>

<ng-template #loading>
  <div class="loading">
    <spinner message="{{ 'GLOBAL.LOADING'|translate }}"></spinner>
  </div>
</ng-template>

<ng-template #noData>
  <!-- No data -->
  <div class="no-data no-data-sm">
    <i class="material-symbols-rounded">not_interested</i>
    {{ 'API_KEY.NO_KEYS'|translate }}
  </div>
</ng-template>