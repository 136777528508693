import { flattenErrorCauses, isError, tryToGetFingerprint } from '../error/error';
import { mergeObservables, Observable } from '../../tools/observable';
import { ConsoleApiName, globalConsole } from '../../tools/display';
import { callMonitored } from '../../tools/monitor';
import { sanitize } from '../../tools/serialisation/sanitize';
import { find } from '../../tools/utils/polyfills';
import { jsonStringify } from '../../tools/serialisation/jsonStringify';
import { ErrorSource } from '../error/error.types';
import { computeStackTrace } from '../../tools/stackTrace/computeStackTrace';
import { createHandlingStack, toStackTraceString, formatErrorMessage } from '../../tools/stackTrace/handlingStack';
import { clocksNow } from '../../tools/utils/timeUtils';
var consoleObservablesByApi = {};
export function initConsoleObservable(apis) {
    var consoleObservables = apis.map(function (api) {
        if (!consoleObservablesByApi[api]) {
            consoleObservablesByApi[api] = createConsoleObservable(api); // we are sure that the observable created for this api will yield the expected ConsoleLog type
        }
        return consoleObservablesByApi[api];
    });
    return mergeObservables.apply(void 0, consoleObservables);
}
export function resetConsoleObservable() {
    consoleObservablesByApi = {};
}
function createConsoleObservable(api) {
    return new Observable(function (observable) {
        var originalConsoleApi = globalConsole[api];
        globalConsole[api] = function () {
            var params = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                params[_i] = arguments[_i];
            }
            originalConsoleApi.apply(console, params);
            var handlingStack = createHandlingStack();
            callMonitored(function () {
                observable.notify(buildConsoleLog(params, api, handlingStack));
            });
        };
        return function () {
            globalConsole[api] = originalConsoleApi;
        };
    });
}
function buildConsoleLog(params, api, handlingStack) {
    var message = params.map(function (param) { return formatConsoleParameters(param); }).join(' ');
    var error;
    if (api === ConsoleApiName.error) {
        var firstErrorParam = find(params, isError);
        error = {
            stack: firstErrorParam ? toStackTraceString(computeStackTrace(firstErrorParam)) : undefined,
            fingerprint: tryToGetFingerprint(firstErrorParam),
            causes: firstErrorParam ? flattenErrorCauses(firstErrorParam, 'console') : undefined,
            startClocks: clocksNow(),
            message: message,
            source: ErrorSource.CONSOLE,
            handling: "handled" /* ErrorHandling.HANDLED */,
            handlingStack: handlingStack,
        };
    }
    return {
        api: api,
        message: message,
        error: error,
        handlingStack: handlingStack,
    };
}
function formatConsoleParameters(param) {
    if (typeof param === 'string') {
        return sanitize(param);
    }
    if (isError(param)) {
        return formatErrorMessage(computeStackTrace(param));
    }
    return jsonStringify(sanitize(param), undefined, 2);
}
