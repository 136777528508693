import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { plainToInstance } from "class-transformer";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';

import { GroupedMarkets } from '../company/modules/commercial/models/market-type.model';
import { Market } from '../company/modules/commercial/models/market.model';
import { CompanyActivity } from '../models/company-activity.model';
import { Company } from '../models/company.model';
import { Currency } from '../models/currency.model';
import { Product } from '../models/product.model';
import { buildFilters } from '../utilities/filters';
import { CacheService } from './cache.service';
import { CompanyService } from './company.service';
import { PusherService } from './pusher.service';

@Injectable()
export class MarketService {

  private marketsUrl = '/:apiBase/markets';
  private marketUrl = this.marketsUrl + '/:marketId';
  private marketsByTypeUrl = '/:apiBase/markets-by-type';
  private marketSummaryUrl = '/:apiBase/companies/:companyId/market-summary';
  private marketsByUserUrl = '/:apiBase/users/:userId/markets';
  private companyExistsUrl = this.marketUrl + '/company-exists/:fiscalValue';
  private productsUrl = this.marketUrl + '/products';
  private currenciesUrl = this.marketUrl + '/currencies';
  private companyActivitiesUrl = this.marketUrl + '/company-activities';

  /**
   * Maps only those parameters that don't match in the API call.
   * Format - 'Webapp query': 'API query'
   */
  private readonly queryMap: Record<string, string> = {
    'product_id': 'filters[product][id]',
    'zone_id': 'filters[zone][id]',
    'location_id': 'filters[location][id]',
    'operation_type': 'filters[operation_type]'
  };

  private current: Market;
  private currentMarket = new BehaviorSubject<Market>(null);
  private currentCurrencies = new BehaviorSubject<Currency[]>(null);
  private currentProducts = new BehaviorSubject<Product[]>(null);
  private currentActivities = new BehaviorSubject<CompanyActivity[]>(null);
  private _markets: { [id: number]: Market } = {};
  private readonly CACHE_MINUTES = 60 * 24 * 7;

  constructor(
    private http: HttpClient,
    private pusherService: PusherService,
    private companyService: CompanyService,
    private cacheService: CacheService,
    private translateService: TranslateService
  ) {
    /** [[Market]] data is based on the current [[Company]]. */
    this.companyService.watch().subscribe(company => {
      if (company && company.market &&
        (!this.current || this.current.id !== company.market.id)) {
        // Market change
        this.current = company.market;
        this.currentMarket.next(this.current);

        this.getCurrencies(this.current.id).subscribe(currencies => {
          this.currentCurrencies.next(currencies);
        });
        this.getProducts(this.current.id).subscribe(products => {
          this.currentProducts.next(products);
        });
        this.getActivities(this.current.id).subscribe(activities => {
          this.currentActivities.next(activities);
        });
      }
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.current) {
        // Language change
        this.getProducts(this.current.id).subscribe(products => {
          this.currentProducts.next(products);
        });
      }
    });
  }

  /**
   * Load the supported [[Currency|Currencies]] in the specified [[Market]].
   *
   * To minimize API calls, consider subscribing to the
   * [[MarketService.watchCurrencies]] observable instead.
   */
  public getCurrencies(marketId: number): Observable<Currency[]> {
    const url = this.currenciesUrl
      .replace(":marketId", marketId.toString());

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<Currency[]>(url).pipe(map(currencies => {
      this.cacheService.set(url, currencies, this.CACHE_MINUTES);
      return currencies;
    }));
  }

  /**
   * Returns an observable of the collection of [[Currency|Currencies]]
   * supported in the current [[Market]].
   */
  public watchCurrencies(): BehaviorSubject<Currency[]> {
    return this.currentCurrencies;
  }

  private getProducts(marketId: number): Observable<Product[]> {
    const url = this.productsUrl
      .replace(":marketId", marketId.toString());

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<Product[]>(url).pipe(map(products => {
      this.cacheService.set(url, products, this.CACHE_MINUTES);
      return products;
    }));
  }

  /**
   * Returns an observable of the collection of [[Product|Products]]
   * supported in the current [[Market]].
   */
  public watchProducts(): BehaviorSubject<Product[]> {
    return this.currentProducts;
  }

  public watch(): BehaviorSubject<Market> {
    return this.currentMarket;
  }

  public getActivities(marketId: number): Observable<CompanyActivity[]> {
    const url = this.companyActivitiesUrl
      .replace(':marketId', marketId.toString());

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<CompanyActivity[]>(url).pipe(
      map(activities => {
        activities = activities.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        this.cacheService.set(url, activities, this.CACHE_MINUTES);
        return activities;
      })
    );
  }

  /**
   * Returns an observable of the collection of
   * [[CompanyActivity|Company activities]] in the current [[Market]].
   */
  public watchActivities(): BehaviorSubject<CompanyActivity[]> {
    return this.currentActivities;
  }

  public getMarkets(): Observable<Market[]> {
    const url = this.marketsUrl;
    return this.http.get<any[]>(url).pipe(
      map(markets => plainToInstance(Market, markets))
    );
  }

  public getMarketsByType(): Observable<GroupedMarkets[]> {
    const url = this.marketsByTypeUrl;
    return this.http.get<any[]>(url).pipe(
      map(groups => plainToInstance(GroupedMarkets, groups))
    );
  }

  public getMarketsByUser(userId: number): Observable<Market[]> {
    const url = this.marketsByUserUrl.replace(':userId', userId.toString());
    return this.http.get<any[]>(url).pipe(
      map(markets => plainToInstance(Market, markets))
    );
  }

  /**
   * Retrieves and caches the market data for a given market ID.
   *
   * @param {number} marketId - The ID of the market.
   * @returns {Observable<Market>} - An observable of the market data.
   */
  public get(marketId: number): Observable<Market> {
    if (this._markets[marketId]) {
      return of(this._markets[marketId]);
    }

    const url = this.marketUrl.replace(':marketId', marketId.toString());

    const cached = this.cacheService.get(url);
    // Return cached value if exists
    if (cached) return of(cached);

    return this.http.get<Market>(url).pipe(map(market => {
      this.cacheService.set(url, market, this.CACHE_MINUTES);
      this._markets[marketId] = market;
      return market;
    }));
  }

  // watchConfiguration(): void {
  //   return this.companyService.watch().pipe(
  //     map(company => company && company.market.configuration)
  //   );
  // }

  public getSummary(type: string, companyId: number, filters?: any): Observable<any[]> {
    let url = this.marketSummaryUrl.replace(':companyId', companyId.toString()) + '/' + type;

    url = buildFilters(url, filters, this.queryMap);

    // TODO: implementar summarizer como parte del generic
    return this.http.get<any[]>(url);
  }

  public watchMarketSummary(type: string, companyId: number, filters?: any): Observable<any[]> {
    return this.pusherService.listenToMultiple([
      { channel: 'public', event: 'negotiation' },
      { channel: 'public', event: 'order' }
    ]).pipe(
      startWith({}),
      mergeMap(event => this.getSummary(type, companyId, filters))
    );
  }

  public companyExists(marketId: number, fiscal_value: string): Observable<any> {
    const url = this.companyExistsUrl.replace(':marketId', marketId.toString()).replace(':fiscalValue', fiscal_value.toString());

    return this.http.get<{ exists: boolean, company: Company }>(url);
  }
}
